import { React, Link } from "../../../common";
import Container from "../../container";
import LeftBreadCrumb from "../leftTree"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="preapprove_pgBlk">
                                <div className="preApprove_breadcrumb">Pre-Approval: Get Pre-Approved</div>
                                <ul className="preApprove_IIcol_285by100pc">
                                    <LeftBreadCrumb />
                                    <li className="preApprove_IIcol_285by100pc">
                                        <ul className="step_count">
                                            <li className="stepHead_100pc">To see how much you can afford, we will need to do a soft credit check</li>
                                        </ul>

                                        <div className="purchase">
                                            <div className="spacer_25margin_bottom">
                                                <div className="pur_Q">Current Address</div>
                                                <input type="text" />
                                            </div>

                                            <div className="spacer_25margin_bottom">
                                                <ul className="III_col">
                                                    <li className="III_col">
                                                        <div className="pur_Q">City</div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="III_col">
                                                        <div className="pur_Q">State</div>
                                                        <select name="filterType" defaultValue={'DEFAULT'} required>
                                                            <option value="DEFAULT" disabled>Select</option>
                                                            <option value="#">State 1</option>
                                                            <option value="#">State 2</option>
                                                        </select>
                                                        {/* <div className="pur_Q">State</div>
                                                        <input type="text" /> */}
                                                    </li>
                                                    <li className="III_col">
                                                        <div className="pur_Q">Zip</div>
                                                        <input type="text" />
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="spacer_25margin_bottom">
                                                <ul className="II_col3by2">
                                                    <span className="spacer_5margin_bottom">
                                                        <div className="pur_Q">Mobile Number</div>
                                                    </span>
                                                    <li className="II_col3by2">
                                                        <input type="text" placeholder="XXX-XXXX-XXX" />
                                                    </li>
                                                    <li className="II_col3by2">
                                                        <div className="noteText">By entering your phone number you are authorizing i3 Mortgage to call and text. We won't charge you for any contact.</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="spacer_25margin_bottom">
                                                <ul className="II_col3by2">
                                                    <div className="spacer_5margin_bottom">
                                                        <div className="pur_Q">Credit Score</div>
                                                    </div>
                                                    <li className="II_col3by2">
                                                        <select name="filterType" defaultValue={'DEFAULT'} required>
                                                            <option value="DEFAULT" disabled>Select</option>
                                                            <option value="#">720 + (Excellent)</option>
                                                            <option value="#">660 - 719 (Good)</option>
                                                            <option value="#">620 - 659 (Average)</option>
                                                            <option value="#">580 - 619 (Below Average)</option>
                                                            <option value="#">&#10877; 579 (Poor)</option>
                                                        </select>
                                                    </li>
                                                    <li className="II_col3by2">
                                                        <div className="noteText">Please select the range you fall under. <br />Highest credit score qualifies for the best interest rate.</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="spacer_25margin_bottom">
                                                <ul className="III_col">
                                                    <div className="spacer_5margin_bottom">
                                                        <div className="pur_Q">Social Security Number</div>
                                                    </div>
                                                    <li className="III_col">
                                                        <input type="text" placeholder="XXXX" />
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="spacer_25margin_bottom">
                                                <div className="checkboxLabel">
                                                    <input type="checkbox" id="cb_ssn" /><label htmlFor="cb_ssn">I, Bhaskar NR, authorize i3 Mortgage to verify my credit. I have also read and agreed to i3 Mortgage <Link to="/terms">Terms of Use</Link>, <Link to="/privacy">Privacy Policy</Link> and <Link to="/consent">Consent to Receive Electronic Loan Documents</Link>, and consent to a credit enquiry and income verification.</label>
                                                </div>
                                            </div>

                                            <div className="button_preApprove_blk">
                                                <input type="button" value="Previous" className="button_steps" onClick={() => window.open('/preapprovalstepVI', "_self")} />
                                                <input type="button" value="Next" className="button_steps" onClick={() => window.open('/preapprovalstepVIII', "_self")} />
                                            </div>
                                            <div className="errormessage" style={{ display: "none" }}>All fields are mandatory</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

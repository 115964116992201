import { React, Link } from "../../../../common";
import Container from "../../../container";
import { LoanTabsListNav } from "../../../components";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_lo_30">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Loan Options</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt_topTxt_noRight">30 Year Fixed Rate</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="loPgStructure">
                                <li className="loPgStructure">
                                    <LoanTabsListNav type="thirty" />
                                </li>
                                <li className="loPgStructure">
                                    <ul className="loPointsBlk">
                                        <li className="loPointsBlk">
                                            <ul className="loDescPoints">
                                                <li className="loDescPoints">Most popular mortgage plan</li>
                                                <li className="loDescPoints">No rising rates</li>
                                                <li className="loDescPoints">Steady, affordable monthly payments</li>
                                                <li className="loDescPoints">Qualify for higher loan amounts</li>
                                                <Link to=""><input type="button" value="Apply Now" className="loButton" /></Link>
                                            </ul>
                                        </li>
                                        <li className="loPointsBlk">
                                            <div className="pgTxtNormal">The financial security of a 30-year fixed rate mortgage makes this the preferred loan product for many borrowers. The fixed-rate of this mortgage product is helpful for those who find budget planning important, and with rates at historical lows, this is can be especially appealing to those who plan on staying in the same house for a long time. Monthly payments will only adjust if a change is made to your homeowne's insurance or property taxes, or both if those items are paid with your mortgage each month.</div>
                                            <div className="pgTxtNormal">While the 30-year term will keep the payments lower than a shorter-term loan, the drawback is that you pay more interest over the length of the loan compared with shorter-term loans. In the early years of the mortgage - when the largest portion of your payment is going toward interest - you are not building significant equity in the property.</div>
                                            <div className="pgTxtNormal">This is why it is important to consult with a mortgage professional. An experienced loan officer will review your short-term and long-term goals with you. This will help determine if a fixed rate or adjustable rate mortgage is right for you, as well as the term of the loan. Our loan officers are ready to help you make this decision; contact us today!</div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
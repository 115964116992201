import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div className="app_box_shadow_noPad">
            <div className="app_headBlk_pad20">
                <div className="app_headBlk_blkName">
                    1003 Application
                </div>
                <div className="blockRefresh_dash"></div>
                <div className="selectRight">
                    <select name="filterType" required>
                        <option value="#">Status</option>
                        <option value="#">Saved</option>
                        <option value="#">Completed</option>
                    </select>
                </div>
            </div>
            <div className="appContentarea">
                <div className="dash_form1003">
                    <div className="dash_form1003_head">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Application No</td>
                                    <td>Borrower Name</td>
                                    <td>Property Address</td>
                                    <td>Status</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="dash_form1003">
                        <table>
                            <tbody>
                                <tr>
                                    <td>MAL10324567</td>
                                    <td>Andrew P Harris</td>
                                    <td>1900 Boothe Cir #100, Longwood, FL 32750, United States</td>
                                    <td>Saved</td>
                                    <td><Link to="#" title="Resume Application"><div className="dash_resumeForm"></div></Link></td>
                                </tr>
                                <tr>
                                    <td>MAL14567890</td>
                                    <td>Thomas Mathew</td>
                                    <td>2200 Mission College Blvd Santa Clara, CA 95054 United States</td>
                                    <td>Completed</td>
                                    <td><Link to="#" title="Download Fannie Mae File"><div className="dash_downloadForm"></div></Link></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Component;
import { React, Link } from "../../../common";
import Container from "../../container";
import LeftBreadCrumb from "../leftTree"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="preapprove_pgBlk">
                                <div className="preApprove_breadcrumb">Pre-Approval: Get Pre-Approved</div>
                                <ul className="preApprove_IIcol_285by100pc">
                                    <LeftBreadCrumb />
                                    <li className="preApprove_IIcol_285by100pc">
                                        <ul className="step_count">
                                            <li className="stepHead">Great! Let us know about your income</li>
                                            <li className="countBlk"><div className="countNo">4/5</div></li>
                                        </ul>

                                        <div className="purchase">
                                            <div className="spacer_10margin_bottom">
                                                <ul className="III_col_income">
                                                    <li className="III_col_income_head">
                                                        <div className="pur_Q">Source of Income?</div>
                                                    </li>
                                                    <li className="III_col_income_head">
                                                        <div className="pur_Q">Gross Income?</div>
                                                    </li>
                                                    <li className="III_col_income_head">&nbsp;</li>
                                                </ul>
                                                <ul className="III_col_income">
                                                    <li className="III_col_income">
                                                        <select name="filterType" defaultValue={'DEFAULT'} required>
                                                            <option value="DEFAULT" disabled>Select</option>
                                                            <option value="#">Employed</option>
                                                            <option value="#">Retired</option>
                                                            <option value="#">Self Employed</option>
                                                            <option value="#">Child Support / Alimony</option>
                                                            <option value="#">Rental Income</option>
                                                            <option value="#">Other Income</option>
                                                        </select>
                                                    </li>
                                                    <li className="III_col_income">
                                                        <input type="text" placeholder="$" />
                                                    </li>
                                                    <li className="III_col_income">
                                                        <input type="button" value="&#65291;" className="button_adddelete" />
                                                    </li>
                                                </ul>
                                                <ul className="III_col_income">
                                                    <li className="III_col_income">
                                                        <select name="filterType" defaultValue={'DEFAULT'} required>
                                                            <option value="DEFAULT" disabled>Select</option>
                                                            <option value="#">Employed</option>
                                                            <option value="#">Retired</option>
                                                            <option value="#">Self Employed</option>
                                                            <option value="#">Child Support / Alimony</option>
                                                            <option value="#">Rental Income</option>
                                                            <option value="#">Other Income</option>
                                                        </select>
                                                    </li>
                                                    <li className="III_col_income">
                                                        <input type="text" placeholder="$" />
                                                    </li>
                                                    <li className="III_col_income">
                                                        <input type="button" value="&#65291;" className="button_adddelete" />
                                                    </li>
                                                </ul>
                                                <ul className="III_col_income">
                                                    <li className="III_col_income">
                                                        <select name="filterType" defaultValue={'DEFAULT'} required>
                                                            <option value="DEFAULT" disabled>Select</option>
                                                            <option value="#">Employed</option>
                                                            <option value="#">Retired</option>
                                                            <option value="#">Self Employed</option>
                                                            <option value="#">Child Support / Alimony</option>
                                                            <option value="#">Rental Income</option>
                                                            <option value="#">Other Income</option>
                                                        </select>
                                                    </li>
                                                    <li className="III_col_income">
                                                        <input type="text" placeholder="$" />
                                                    </li>
                                                    <li className="III_col_income">
                                                        <input type="button" value="&#65293;" className="button_adddelete" />
                                                    </li>
                                                </ul>

                                                <ul className="II_col_80by20">
                                                    <li className="II_col_80by20">
                                                        <ul className="II_col">
                                                            <li className="II_col">
                                                                <div className="pur_Q">Annual Gross Income</div>
                                                                <input type="text" placeholder="$" readOnly />
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="spacer_15margin_bottom">
                                                <div className="pur_Q">Monthly Debt Payments</div>
                                                <ul className="pur_A">
                                                    <li><Link to="#">$0 - $500</Link></li>
                                                    <li><Link to="#" className="current">$501 - $1000</Link></li>
                                                    <li><Link to="#">$1001 - $2000</Link></li>
                                                    <li><Link to="#">$2001 - $5000</Link></li>
                                                    <li><Link to="#">$5001 +</Link></li>
                                                </ul>
                                            </div>

                                            <div className="spacer_15margin_bottom">
                                                <div className="pur_Q">Do you want to add a Co-borrower?</div>
                                                <ul className="pur_A">
                                                    <li><Link to="#" className="current">Yes</Link></li>
                                                    <li><Link to="#">No</Link></li>
                                                </ul>
                                            </div>

                                            <div className="spacer_25margin_bottom">
                                                <div className="pur_Q">Co-borrower Primary Address</div>
                                                <input type="text" name="#" placeholder="City or Zip" />
                                            </div>
                                            <div className="spacer_20margin_bottom">
                                                <ul className="II_col">
                                                    <li className="II_col">
                                                        <div className="pur_Q">Relationship to Primary Borrower</div>
                                                        <select name="filterType" defaultValue={'DEFAULT'} required>
                                                            <option value="DEFAULT" disabled>Select</option>
                                                            <option value="#">Spouse</option>
                                                            <option value="#">Co-owner</option>
                                                            <option value="#">Other</option>
                                                        </select>
                                                    </li>
                                                    <li className="II_col">
                                                        <div className="pur_Q">Email of Co-borrower</div>
                                                        <input type="text" />
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="spacer_25margin_bottom">
                                                <div className="checkboxLabel">
                                                    <input type="checkbox" id="cb_investor" /><label htmlFor="cb_investor">We understand that by adding a co-borrower we are agreeing to apply for credit jointly. By continuing with the application, we will have access to application information and documents that are submitted by either borrower. This includes but is not limited to: income, credit score and factors impacting your credit score. If you do not wish to apply jointly, please remove the co-borrower.</label>
                                                </div>

                                            </div>

                                            <div className="button_preApprove_blk">
                                                <input type="button" value="Previous" className="button_steps" onClick={() => window.open('/preapprovalstepIII', "_self")} />
                                                <input type="button" value="Next" className="button_steps" onClick={() => window.open('/preapprovalstepV', "_self")} />
                                            </div>
                                            <div className="errormessage" style={{ display: "none" }}>All fields are mandatory</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

const Component = () => {
    return (
        <div className="app_box_shadow">
            <div className="app_headBlk">
                <div className="app_headBlk_blkName_status">
                    Application Status -
                </div>
                <div className="selectAppNo">
                    <select name="filterType" required>
                        <option value="#">MAL14567890</option>
                    </select>
                </div>
            </div>
            <ul className="statusSummary">
                <li className="statusSummary">Borrower Name:</li>
                <li className="statusSummary" title="Thomas Mathew">Thomas Mathew</li>
                <li className="statusSummary">Address:</li>
                <li className="statusSummary" title="9205, Topeka Street, Bethesda, TN 20817 20817">9205, Topeka Street, Bethesda, TN 20817 20817</li>
                <li className="statusSummary">Submitted On:</li>
                <li className="statusSummary">Mar/15/2022 &nbsp;15:33</li>
            </ul>
            <ul className="loanStatus">
                <li className="loanStatus success">
                    <div className="statusnumber successNo">&#x2713;</div><div className="statusTxt">Started</div>
                </li>
                <li className="loanStatus success">
                    <div className="statusnumber successNo">&#x2713;</div><div className="statusTxt">Registration</div>
                </li>
                <li className="loanStatus current">
                    <div className="statusnumber active">3</div><div className="statusTxt">Rate Lock</div>
                </li>
                <li className="loanStatus">
                    <div className="statusnumber">4</div><div className="statusTxt">Disclosure</div>
                </li>
                <li className="loanStatus">
                    <div className="statusnumber">5</div><div className="statusTxt">Submission</div>
                </li>
                <li className="loanStatus">
                    <div className="statusnumber">6</div><div className="statusTxt">Approval</div>
                </li>
                <li className="loanStatus">
                    <div className="statusnumber">7</div><div className="statusTxt">Processing</div>
                </li>
                <li className="loanStatus">
                    <div className="statusnumber">8</div><div className="statusTxt">Closing</div>
                </li>
            </ul>
        </div>
    )
}

export default Component;
import { React } from "../../../common";

import Mp4Video from "../video/083041670.mp4";
import WebmVideo from "../video/083041670.webm";

const Component = () => {
  const OnGettingStarted = async (e) => {
    e.preventDefault();
    document.getElementById("confirm_pop").style.display = "block";
  };

  return (
    <>
      <div id="homeVideo">
        <div className="videoContainer">
          <video className="video1-bg" autoPlay={true} loop muted style={{ width: '100%' }}>
            <source src={Mp4Video} />
            <source src={WebmVideo} />
            Video not supported.
          </video>
        </div>
        <div className="fallback_smallscreen"></div>
        <div className="videoContent_blk">
          <div className="widthfix_10px">
            <ul className="homeText_Quote_blk">
              <li className="homeText">
                <div className="homeMainTxtBlock">
                  <h1>
                    <div className="mainTxt_line">Realize your Dream of</div>
                    <div className="mainTxt_line">
                      Owning a Home with i3 Lending
                    </div>
                    <div className="mainTxt_line">powered by Blockchain</div>
                  </h1>
                </div>
                <div className="secondaryTxt">
                  We will help you understand your mortgage options so that you
                  can feel certain about what you can afford
                </div>
                <div className="homeButLabel">
                  <input
                    type="submit"
                    className="but_preQuaify"
                    value="Get Pre-Qualified"
                    onClick={(e) => OnGettingStarted(e)}
                  />
                </div>
              </li>
              <li className="requestQuote">
                <div className="requestHead">Request A Quote</div>
                <div className="requestSub">decide your mortgage</div>
                <input type="text" placeholder="Your Name" />
                <input type="text" placeholder="Email" />
                <input type="text" placeholder="Phone Number" />
                <select name="filterType" required defaultValue={""}>
                  <option value="">Loan Purpose</option>
                  <option value="#">Buy a Home</option>
                  <option value="#">Refinancing</option>
                </select>

                <input
                  type="button"
                  value="Apply Now"
                  className="requestButton"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;

import { React, Link } from "../../../common";
import Container from "../../container";
import BreadCrumb from "../../breadcrumb"
import LoanSearch from "../loansearch"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">

                            <BreadCrumb />

                            <div className="document_pgBlk">
                                <div className="appPgStructure_Icol">
                                    <LoanSearch />
                                </div>

                                <div className="appPgStructure_Icol">
                                    <div className="app_box_shadow_noPad">
                                        <div className="app_headBlk_pad20">
                                            <div className="app_headBlk_blkName">
                                                View Documents for Loan No: XXX
                                            </div>
                                        </div>
                                        <div className="appContentarea">
                                            <div className="noContent" style={{ display: "none" }}>No documents available</div>
                                            <div className="viewISPTable_head">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Sl.</td>
                                                            <td><Link to="#">Document Type &#9207;</Link></td>
                                                            <td><Link to="#">Document Name</Link></td>
                                                            <td>Document Info</td>
                                                            <td>Ver</td>
                                                            <td>Uploaded By</td>
                                                            <td>Date &amp; Time</td>
                                                            <td>ISP</td>
                                                            <td>Aura</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="viewISPTable">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Disclosure</td>
                                                            <td title="Sams Documents">Sams Documents</td>
                                                            <td>Uploading for review</td>
                                                            <td>V1</td>
                                                            <td>Sam</td>
                                                            <td>01/05/2022 &nbsp; 12:20 AM</td>
                                                            <td>
                                                                <Link to="#">
                                                                    <div className="pdficon"></div>
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to="#">
                                                                    <div className="pdficon"></div>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Disclosure</td>
                                                            <td title="Sams Documents">Sams Documents</td>
                                                            <td>Bundle of all documents</td>
                                                            <td>V2</td>
                                                            <td>i3 Lending</td>
                                                            <td>01/05/2022 &nbsp; 12:20 AM</td>
                                                            <td>
                                                                <Link to="#">
                                                                    <div className="pdficon"></div>
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to="#">
                                                                    <div className="pdficon"></div>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>Appraisals</td>
                                                            <td title="1456278">1456278</td>
                                                            <td>Apprasial documents from abc corp</td>
                                                            <td>V3</td>
                                                            <td>i3 Lending</td>
                                                            <td>01/05/2022 &nbsp; 12:20 AM</td>
                                                            <td>
                                                                <Link to="#">
                                                                    <div className="pdficon"></div>
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to="#">
                                                                    <div className="pdficon"></div>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

import { React } from "../../common";
import Container from "../container";

const Component = () => {

    const changePassword = async (e) => {
        e.preventDefault();
        document.getElementById("changepswd_pop").style.display = "block";
    };

    const profileComplete = async (e) => {
        e.preventDefault();
        document.getElementById("profileComplete_pop").style.display = "block";
    };

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="consentLO_pgBlk">
                                <div className="consentLO_PgHead">Consents</div>
                                <ul className="IIIcol">
                                    <li className="IIIcol">
                                        <div className="appcontentarea_table_border">
                                            <div className="app_headBlk_pad20">
                                                <div className="app_headBlk_blkName">
                                                    Consent LO to fill 1003
                                                </div>
                                            </div>
                                            <div className="appContentarea_pad15_bgTexure">
                                                <div className="consentLO_TxtHead"> Consent to loan officer to fill in all the Form 1003 fields on your behalf</div>
                                                <ul className="appformColumn">
                                                    <li className="appformColumn_inline">
                                                        <div className="consent_radioLabel">
                                                            <input type="radio" id="radioconsentY" name="loconsent" /><label htmlFor="radioconsentY">Yes</label>
                                                        </div>
                                                    </li>
                                                    <li className="appformColumn_inline">
                                                        <div className="consent_radioLabel">
                                                            <input type="radio" id="radioconsentN" name="loconsent" /><label htmlFor="radioconsentN">No</label>
                                                        </div>
                                                    </li>
                                                </ul>

                                                <div className="consentButtonLabel">
                                                    <input type="button" value="Submit" className="consentButton" />
                                                    <input type="button" value="Revoke" className="consentButton" />
                                                </div>
                                                <div className="consentLO_Txt">Email verified consent provided on 01/01/2023</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <div className="appcontentarea_table_border">
                                            <div className="app_headBlk_pad20">
                                                <div className="app_headBlk_blkName">
                                                    Consent to Upload Documents
                                                </div>
                                            </div>
                                            <div className="appContentarea_pad15_bgTexure">
                                                <div className="consentLO_TxtHead">Allow loan officer to upload all documents on your behalf</div>
                                                <ul className="appformColumn">
                                                    <li className="appformColumn_inline">
                                                        <div className="consent_radioLabel">
                                                            <input type="radio" id="radioconsentY" name="loconsent" /><label htmlFor="radioconsentY">Yes</label>
                                                        </div>
                                                    </li>
                                                    <li className="appformColumn_inline">
                                                        <div className="consent_radioLabel">
                                                            <input type="radio" id="radioconsentN" name="loconsent" /><label htmlFor="radioconsentN">No</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className="consentButtonLabel">
                                                    <input type="button" value="Submit" className="consentButton" />
                                                    <input type="button" value="Revoke" className="consentButton" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="IIIcol">
                                        <div className="appcontentarea_table_border">
                                            <div className="app_headBlk_pad20">
                                                <div className="app_headBlk_blkName">
                                                    Co-Borrower / Co-Applicant Consent
                                                </div>
                                            </div>
                                            <div className="appContentarea_pad15_bgTexure">
                                                <div className="consentLO_TxtHead">If you are a co-borrower or co-applicant allow the loan officer / primary borrower to fill in all the Form 1003 fields on your behalf and upload the required documents</div>
                                                <ul className="appformColumn">
                                                    <li className="appformColumn_inline">
                                                        <div className="consent_radioLabel">
                                                            <input type="radio" id="radioconsentY" name="loconsent" /><label htmlFor="radioconsentY">Yes</label>
                                                        </div>
                                                    </li>
                                                    <li className="appformColumn_inline">
                                                        <div className="consent_radioLabel">
                                                            <input type="radio" id="radioconsentN" name="loconsent" /><label htmlFor="radioconsentN">No</label>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className="consentButtonLabel">
                                                    <input type="button" value="Submit" className="consentButton" />
                                                    <input type="button" value="Revoke" className="consentButton" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="profile_pgBlk" style={{ display: "none" }}>
                                <ul className="appPgStructure_300by100pc">
                                    <li className="appPgStructure_300by100pc">
                                        <div className="app_box_shadow_noPad">
                                            <div className="app_headBlk_pad20">
                                                <div className="app_headBlk_blkName">
                                                    Account Details
                                                </div>
                                            </div>
                                            <div className="appContentarea_pad20">
                                                <div className="accDetails">
                                                    <div className="acc_name">i3 Lending</div>
                                                    <div className="memberBlock">
                                                        <div className="memberHead">Member Since:</div>
                                                        <div className="memberTxt">April 15, 2022</div>
                                                    </div>
                                                    <div className="memberBlock">
                                                        <div className="memberHead">NMLS ID:</div>
                                                        <div className="memberTxt">1234567890</div>
                                                    </div>
                                                    <div className="mailPhBlock">
                                                        <div className="horBlk">
                                                            <div className="mailIcon"></div>
                                                            <div className="mailPh_txt">info@i3lending.com</div>
                                                        </div>
                                                        <div className="horBlk">
                                                            <div className="phIcon"></div>
                                                            <div className="mailPh_txt">+(1) 1234 112 234</div>
                                                        </div>
                                                    </div>
                                                    <div className="acc_btn_label">
                                                        <input type="button" className="acc_btn" value="Change Password" onClick={(e) => changePassword(e)} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="appPgStructure_300by100pc">
                                        <div className="app_box_shadow_noPad">
                                            <div className="app_headBlk_pad20">
                                                <div className="app_headBlk_blkName">
                                                    Profile Details
                                                </div>
                                            </div>
                                            <div className="appContentarea_pad20">
                                                <ul className="appformColumn">
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">First Name</div><div className="appformMandatory">*</div>
                                                        <div className="userFormicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">Middle Name</div>
                                                        <div className="userFormicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">Last Name</div><div className="appformMandatory">*</div>
                                                        <div className="userFormicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">Address</div>
                                                        <div className="locationicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">City</div>
                                                        <div className="locationicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">State</div>
                                                        <div className="locationicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">Zip</div>
                                                        <div className="locationicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">Home Phone</div>
                                                        <div className="landphFormicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">Email</div><div className="appformMandatory">*</div>
                                                        <div className="mailFormicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">Work Phone (XXX) XXX-XXXX</div><div className="appformMandatory">*</div>
                                                        <div className="cellphFormicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">Cell Phone (XXX) XXX-XXXX</div><div className="appformMandatory">*</div>
                                                        <div className="cellphFormicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">Fax</div>
                                                        <div className="cellphFormicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">SSN ID</div>
                                                        <div className="hastagicon"></div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">Passport No</div>
                                                        <div className="hastagicon"></div>
                                                        <input type="text" />
                                                    </li>

                                                    <li className="appformColumn_III">
                                                        <div className="appformContentHead">US Citizen</div><div className="appformMandatory">*</div>
                                                        <div className="appGenericBox">
                                                            <ul className="appformColumn">
                                                                <li className="appformColumn_inline">
                                                                    <div className="checkRadio_Lable">
                                                                        <input type="radio" id="radiocitizenY" name="uscitizen" /><label htmlFor="radiocitizenY">Yes</label>
                                                                    </div>
                                                                </li>
                                                                <li className="appformColumn_inline">
                                                                    <div className="checkRadio_Lable">
                                                                        <input type="radio" id="radiocitizenN" name="uscitizen" /><label htmlFor="radiocitizenN">No</label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li className="appformColumn_I">
                                                        <div className="appformContentHead">Upload Documents for - SSN or Passport</div><div className="appformMandatory">*</div>
                                                        <div className="uploadSuccess">Upload Successful</div>

                                                        <div className="fileUpload">
                                                            <div className="uploadBox"><input id="#" type="file"
                                                                name="joining" /></div>
                                                            <div className="buttonUploadLayout"><input type="submit"
                                                                className="buttonUpload" value="Upload" /></div>
                                                            <div className="submitError" style={{ display: "none" }}>File size
                                                                allowed: 300 Kb</div>
                                                            <div className="postUploadBtnLabel">
                                                                <input type="button" className="postUploadBut" value="View ISP File"></input>
                                                                <input type="button" className="postUploadBut" value="View File"></input>
                                                                <input type="button" className="postUploadBut" value="Delete File"></input>
                                                            </div>
                                                        </div>
                                                    </li>

                                                </ul>
                                                <div className="appform_btn_label">
                                                    <input type="button" value="Submit" className="appform_btn" />
                                                    <input type="button" value="Reset" className="appform_btn" />
                                                    <input type="button" value="Profile Complete Popup" className="appform_btn" onClick={(e) => profileComplete(e)} />
                                                </div>
                                                <div className="errormessage" style={{ display: "none" }}>Please fill mandatory fields</div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
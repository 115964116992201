import { React } from "../../../../common";
import Container from "../../../container";
import { AmtCalc } from "../../../components";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_refin_reduce">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Buy a Home</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Buy a Foreclosed Home</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">How Much Do You Need?</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtNormal">Whether you're looking for your first home, next home, a vacation home or even an investment property, a foreclosed property may be an option you're considering. The availability and condition of these houses can vary greatly, and you'll want to do your homework to make sure your purchase is a sound investment. Take some of the mystery out of the process with help from us.</div>
                                    <div className="pgTxtNormal">Cover the essential questions on foreclosures:</div>
                                    <ul className="bulletPoints_un">
                                        <li className="bp_circleOutline">What's the difference between a foreclosure and a short sale?</li>
                                        <li className="bp_circleOutline">Do I need to pay cash or can I get a mortgage?</li>
                                        <li className="bp_circleOutline">What if I'm purchasing property out-of-state?</li>
                                        <li className="bp_circleOutline">How do I prepare for my mortgage application?</li>
                                        <li className="bp_circleOutline">What are the steps from mortgage prequalification to closing?</li>
                                    </ul>
                                </li>
                                <li className="IIcol_100by400">
                                    <AmtCalc />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
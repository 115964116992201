const Component = () => {
    return (
        <div>
            <ul className="formStage">
                <div className="stageHead">Step 2 of 5</div>
                <li className="step_5 current"></li>
                <li className="step_5 current"></li>
                <li className="step_5"></li>
                <li className="step_5"></li>
                <li className="step_5"></li>
            </ul>
        </div>
    )
}

export default Component;
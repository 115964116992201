import { React } from "../../../../common";
import Container from "../../../container";
import { AmtCalc } from "../../../components";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_refin_lower">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Refinance</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Pay Off Your Mortgage Sooner</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">How Much Do You Need?</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtBoldNoTopSpace">Convert to a shorter-term mortgage</div>
                                    <div className="pgTxtNormal">If you convert from a 30-year to a 15-year fixed-rate mortgage, you will pay off your mortgage sooner, and pay less loan interest overall. The trade-off will likely be larger monthly mortgage payments.</div>
                                    <div className="pgTxtNormal"><b>Example:</b><br /> The Smiths have 25 years and $150,000 remaining on a 30-year mortgage at
                                        5.25%. They are considering refinancing the $150,000 with a 15-year mortgage at 3.5%. By doing so, they will save $66,645 in interest payments over the life of the loan.</div>
                                    <div className="refin_5col_payoff">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Term</td>
                                                    <td>Loan Amount</td>
                                                    <td>Interest Rate</td>
                                                    <td>Interest Paid</td>
                                                    <td>Savings</td>
                                                </tr>
                                                <tr>
                                                    <td>25 years remaining on a 30-year</td>
                                                    <td>$150,000</td>
                                                    <td>5.25%</td>
                                                    <td>$109,663</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>15 Years</td>
                                                    <td>$150,000</td>
                                                    <td>3.5%</td>
                                                    <td>$43,018</td>
                                                    <td>$66,645</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pgTxtBold">Make additional payments on your current mortgage</div>
                                    <div className="pgTxtNormal">You can pay off your loan sooner without refinancing by simply making additional principal payments. Additional regular payments can add up quickly, take years off your mortgage and reduce your overall interest payments.</div>
                                    <div className="pgTxtNormal"><b>Example:</b><br />The Smiths have 25 years remaining on a $100,000 30-year mortgage at 5%. For the remaining term of the mortgage, they pay an extra $100/month. The mortgage will be paid approximately 6 years and 7 months earlier $22,046 total interest saved by paying sooner</div>
                                </li>
                                <li className="IIcol_100by400">
                                    <AmtCalc />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
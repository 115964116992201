import { React } from "../../../../common";
import Container from "../../../container";
import { ContactInfo } from "../../../components";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_about">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Become a Branch</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtBoldNoTopSpace">No more searching online and filling out applications for hours. Just fill this online form and join our ever growing list of Loan Officers.</div>
                                    <div className="contactFormNoShadow">
                                        <ul className="contactForm_IIcol">
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead_Error">Name</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormUserIcon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Email</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormMailIcon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Phone Number</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormPhIcon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">NMLS Number</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormHastagIcon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">State Licensing</div>
                                                <div className="contactFormTypeIcon"></div>
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">Alabama</option>
                                                    <option value="#">California</option>
                                                    <option value="#">Florida</option>
                                                </select>
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">How you best describe yourself</div>
                                                <div className="contactFormTypeIcon"></div>
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">Sales Professional</option>
                                                    <option value="#">Large Group Manager</option>
                                                </select>
                                            </li>
                                        </ul>
                                        <div className="contactForm_Icol">
                                            <div className="contactFormHead">Message</div>
                                            <div className="contactFormNoteIcon"></div>
                                            <textarea></textarea>
                                        </div>
                                        <div className="contactForm_btn_label">
                                            <input type="button" value="Apply Now" className="contactFormBtn" />
                                        </div>
                                        <div className="errormessage" style={{ display: "none" }}>You have some errors. Please fix and proceed.</div>

                                        <div className="successmessage" style={{ display: "none" }}>Thank you for contacting us. We will get back to you ASAP.</div>
                                    </div>
                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul >
                        </div >
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

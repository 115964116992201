import { React, Link } from "../../../common";
import Container from "../../container";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="form1003_pgBlk">
                                <ul className="formHeadStageBlk">
                                    <li className="formHeadStageBlk">1003 Form - Saved &amp; Completed Applications</li>
                                    <li className="formHeadStageBlk">&nbsp;</li>
                                </ul>

                                <div className="noContent" style={{ display: 'none' }}>No application data available</div>

                                <div className="form1003_savedCompleted_blk">
                                    <div className="savedCompleted_head">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Ref No.</td>
                                                    <td>App No.</td>
                                                    <td>Borrower</td>
                                                    <td>Phone</td>
                                                    <td>Email</td>
                                                    <td>Address</td>
                                                    <td>Status</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="savedCompleted">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>202302012024</td>
                                                    <td>202302012024</td>
                                                    <td>Stuart M Little</td>
                                                    <td>(431) 242-6662</td>
                                                    <td>stuart@i3lending.com</td>
                                                    <td>1900 Boothe Circle, Suite #100, Longwood, FL 32750</td>
                                                    <td>Saved</td>
                                                    <td><Link to="#" title="Edit"><div className="form1003__resumeForm"></div></Link></td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
const Component = () => {
    return (
        <ul className="topBreadcrumb">
            <li className="topBreadcrumb_home"></li>
            <li className="topBreadcrumb">Documents</li>
            <li className="topBreadcrumb">View Documents</li>
        </ul>
    )
}

export default Component;
const Component = () => {
    return (
        <div className="reachYouBlk">
            <div className="widthfix_10px">
                <div className="reachYou_blkHeadTxt">Just let us know how to reach you and one of our loan officers will be in touch shortly</div>
                <ul className="reachYouForm">
                    <li className="reachYouForm">
                        <input type="text" placeholder="Name" />
                    </li>
                    <li className="reachYouForm">
                        <input type="text" placeholder="Phone" />
                    </li>
                    <li className="reachYouForm">
                        <input type="email" placeholder="Email" />
                    </li>
                    <li className="reachYouForm">
                        <input type="button" className="reachYouButton" value="Submit" />
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default Component;
import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div className="ourServiceBlk">
            <div className="widthfix_10px">
                <div className="ourService_blockDesc">Our Services<span className="curveShape"></span></div>
                <div className="ourService_blkHeadTxt">Loan Products We Offer</div>
                <div className="ourService_IIIcol hover_ourService buy">
                    <div className="ourService_icon1"></div>
                    <div className="ourService_colHeadTxt">01. Buying a Home</div>
                    <div className="ourService_colTxt">We have a mortgage solution for you. Get your custom rate quote today.</div>
                    <Link to="#"><input type="button" value="Get Pre-Approved" className="ourServiceButton" /></Link>
                </div>
                <div className="ourService_IIIcol hover_ourService refinance">
                    <div className="ourService_icon2"></div>
                    <div className="ourService_colHeadTxt">02. Refinancing</div>
                    <div className="ourService_colTxt">We are committed to helping you refinance with lowest rates and fees.</div>
                    <Link to="#"><input type="button" value="Get a Quote" className="ourServiceButton" /></Link>
                </div>
                <div className="ourService_IIIcol hover_ourService credit lastCol">
                    <div className="ourService_icon3"></div>
                    <div className="ourService_colHeadTxt">03. Credit Service</div>
                    <div className="ourService_colTxt">Our management consulting services focus on our clients most critical issues.</div>
                    <Link to="#"><input type="button" value="Connect with an Agent" className="ourServiceButton" /></Link>
                </div>
            </div>
        </div>
    )
}

export default Component;
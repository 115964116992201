import { React, Link, useNavigate } from "../../common";

const SignUpScreen = ({ onClicked }) => {
    const NavigateTo = useNavigate();
    const [received, setReceived] = React.useState(false);

    const OnVerifyClicked = async (e) => {
        setReceived(true);
    };

    const OnSubmitClicked = async (e) => {
        sessionStorage.setItem("user", "Hello, Sign In");
        onClicked("hide");
        NavigateTo("/dashboard");
    };

    return (
        <>
            <div id="signup" style={{ display: "block" }}>
                <div className="si_su_Head">Create your i3 Mortgage Account</div>
                <div className="si_su_sub">
                    Already have an account?{" "}
                    <Link to="#" onClick={(e) => onClicked("signin")}>
                        Sign In
                    </Link>
                </div>
                <div className="si_su_method" style={{ display: "none" }}>
                    <div className="method_head">
                        Choose on the following sign up methods.
                    </div>
                    <div className="spacer_10margin_bottom">
                        <Link to="#" title="Twitter">
                            <div className="twitterLogo"></div>
                        </Link>
                        <Link to="#" title="Facebook">
                            <div className="facebookLogo"></div>
                        </Link>
                        <Link to="#" title="Google">
                            <div className="googleLogo"></div>
                        </Link>
                    </div>
                </div>
                <div className="si_su_emailmethod">
                    Sign Up using your email address
                </div>
                <div className="spacer_8margin_bottom">
                    <ul className="si_su_II_col">
                        <li className="si_su_II_col">
                            <div className="si_su_Q">First Name</div>
                            <input
                                type="text"
                                placeholder="First Name"
                            />
                        </li>

                        <li className="si_su_II_col">
                            <div className="si_su_Q">Last Name</div>
                            <input
                                type="text"
                                placeholder="Last Name"
                            />
                        </li>

                        <li className="si_su_II_col">
                            <div className="si_su_Q">Mobile</div>
                            <input
                                type="text"
                                placeholder="Mobile"
                            />
                        </li>

                        <li className="si_su_II_col">
                            <div className="si_su_Q">Land Phone</div>
                            <input
                                type="text"
                                placeholder="Phone No"
                            />
                        </li>
                    </ul>
                </div>
                <div className="spacer_20margin_bottom">
                    <ul className="si_su_II_col_by150">
                        <li className="si_su_II_col_by150">
                            <div className="si_su_Q">
                                Email Address
                            </div>
                            <input
                                type="text"
                                placeholder="Email Address"
                            />
                        </li>
                        <li className="si_su_II_col_by150">
                            <div className="si_su_Q">
                                &nbsp;
                            </div>
                            <input
                                type="button"
                                value="Verify Email"
                                className="button_verify"
                                onClick={(e) => OnVerifyClicked(e)}
                            />
                        </li>
                    </ul>
                </div>
                {received && (
                    <div className="spacer_15margin_bottom">
                        <ul className="si_su_II_col_40by60">
                            <li className="si_su_II_col_40by60">
                                <div className="si_su_Q">OTP</div>
                                <input
                                    type="text"
                                />
                            </li>
                            <li className="si_su_II_col_40by60">
                                <div className="si_su_OTP_note">
                                    Please authenticate using the One Time Password that is sent
                                    to the email address
                                </div>
                            </li>
                        </ul>
                    </div>
                )}
                {received && (
                    <div className="button_si_su_label">
                        <input
                            type="button"
                            value="Create My Account"
                            className="button_verify"
                            onClick={(e) => OnSubmitClicked(e)}
                        />
                    </div>
                )}
                <div className="si_su_terms">
                    By creating an account, you're agreeing to our <Link to="/terms">Terms Of Use</Link> and our <Link to="/privacy">Security And Privacy Policy</Link>, which includes using arbitration to resolve claims related to the Telephone Consumer Protection Act.
                </div>
            </div>
        </>
    );
};

const SignInScreen = ({ onClicked }) => {
    const [received, setReceived] = React.useState(false);
    const NavigateTo = useNavigate();

    const OnVerifyAccount = async (e) => {
        setReceived(true);
    };

    const OnSignInClicked = async (e) => {
        sessionStorage.setItem("user", "Hello, Sign In");
        onClicked("hide");
        NavigateTo("/dashboard");
    };

    return (
        <>
            <div id="signin" style={{ display: "block" }}>
                <div className="si_su_Head">Login to your i3 Mortgage Account</div>
                <div className="si_su_sub">
                    Don't have an account?{" "}
                    <Link to="#" onClick={(e) => onClicked("signup")}>
                        Register Now!
                    </Link>
                </div>
                <div className="si_su_method" style={{ display: "none" }}>
                    <div className="method_head">
                        Connect with your social media account
                    </div>
                    <div className="spacer_10margin_bottom">
                        <Link to="#" title="Twitter">
                            <div className="twitterLogo"></div>
                        </Link>
                        <Link to="#" title="Facebook">
                            <div className="facebookLogo"></div>
                        </Link>
                        <Link to="#" title="Google">
                            <div className="googleLogo"></div>
                        </Link>
                    </div>
                </div>
                <div className="spacer_20margin_bottom">
                    <div className="si_su_emailmethod">
                        Sign In using your email address
                    </div>
                </div>

                <div className="spacer_15margin_bottom">
                    <div className="si_su_I_col">
                        <div className="si_su_Q">
                            Email Address
                            <div className="si_su_note">
                                <input
                                    type="checkbox"
                                    id="cb_remember"
                                />
                                <label htmlFor="cb_remember">Remember Me</label>
                            </div>
                        </div>
                        <input
                            type="text"
                            placeholder="Email Address"
                        />
                    </div>
                </div>
                <div className="spacer_5margin_bottom">
                    <ul className="si_su_II_col">
                        <li className="si_su_II_col">
                            <input
                                type="button"
                                value="Login with OTP"
                                className="button_verify"
                                onClick={(e) => OnVerifyAccount(e)}
                            />
                        </li>
                        <li className="si_su_II_col">
                            <input
                                type="button"
                                value="Login with Password"
                                className="button_verify"
                            />
                        </li>
                    </ul>

                    <ul className="si_su_II_col_by150" style={{ display: "none" }}>
                        <li className="si_su_II_col_by150">
                            <div className="si_su_Q">
                                Email Address
                                <div className="si_su_note">
                                    <input
                                        type="checkbox"
                                        id="cb_remember"
                                    />
                                    <label htmlFor="cb_remember">Remember Me</label>
                                </div>
                            </div>
                            <input
                                type="text"
                                placeholder="Email Address"
                            />
                        </li>
                        <li className="si_su_II_col_by150">
                            <input
                                type="button"
                                value="Get OTP"
                                className="button_verify"
                                onClick={(e) => OnVerifyAccount(e)}
                            />
                        </li>
                    </ul>
                </div>
                {received && (
                    <div className="spacer_20margin_bottom">
                        <ul className="si_su_II_col_40by60">
                            <li className="si_su_II_col_40by60">
                                <div className="si_su_Q">OTP</div>
                                <input
                                    maxLength={6}
                                    placeholder="OTP"
                                    type="text"
                                />
                            </li>
                            <li className="si_su_II_col_40by60">
                                <div className="si_su_OTP_note">
                                    Please authenticate using the One Time Password that is sent
                                    to the email address
                                </div>
                            </li>
                        </ul>
                    </div>
                )}
                {received && (
                    <div className="button_si_su_label">
                        <input
                            type="button"
                            value="LOGIN"
                            onClick={(e) => OnSignInClicked(e)}
                            className="button_verify"
                        />
                    </div>
                )}
            </div>
        </>
    );
};

const Component = () => {
    const [logintype, setLoginType] = React.useState("signin");
    let closeElm = React.useRef(null);

    React.useEffect(() => {
        setLoginType("signin");
    }, []);

    const OnClicked = (e) => {
        if (e === "hide") {
            closeElm.click();
        } else {
            setLoginType(e);
        }
    };

    return (
        <div className="si_su_Layout">
            <Link
                to="#"
                ref={(input) => (closeElm = input)}
                className="si_su_Close"
                onClick={(e) => window.fnSign(0)}
            ></Link>
            <div className="si_su_ContentArea">
                {logintype === "signup" ? (
                    <SignUpScreen onClicked={(e) => OnClicked(e)} />
                ) : (
                    <SignInScreen onClicked={(e) => OnClicked(e)} />
                )}
            </div>
        </div>
    );
};

export default Component;

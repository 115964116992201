import { React } from "../../../common";
import Container from "../../container";
import logoImage from '../../../content/img/i3LendingLogo.jpg';

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="preapprove_pgBlk">
                                <div className="preApprove_breadcrumb">Pre-Approval Letter</div>

                                <div className="nonApproveLetter_blk">
                                    <div className="nonapprovalBoldText">Dear. Sunil Srinivasa Murthy,</div>
                                    <div className="nonapprovalText">We would need some more information before we can provide you with the pre-approval letter.</div>
                                    <div className="nonapprovalText">Please call us at (888) 975-1374 or email us at info@i3lending.com</div>
                                </div>

                                <ul className="approveLetter_blk">
                                    <li className="approveLetter_blk">
                                        <div className="approveMainText">Congrats, Sunil Srinivasa Murthy! <br />You've been pre-approved for</div>
                                        <div className="approveMainTextAmount">$171,000</div>
                                        <div className="approveSecondText">Based on your information, we have created your custom pre-approval letter. It shows what you are qualified for.</div>
                                        <div className="approveButtonBlk">
                                            <input type="button" value="Download pre-approval letter" className="button_approve" />
                                            <input type="button" value="Fill 1003 Form" className="button_approve" onClick={() => window.open('/preapprovalstepI', "_self")} />
                                        </div>
                                    </li>
                                    <li className="approveLetter_blk">
                                        <div className="approvalLetter">
                                            <div className="approvalcoLogo">
                                                <img src={logoImage} alt="i3 Lending" style={{ height: "35px" }} />
                                            </div>
                                            <div className="approvalcoAdd">
                                                1900 Boothe Circle, Suite 100,<br /> Longwood, FL 32750S
                                            </div>
                                            <ul className="letterHeadNMLS_blk">
                                                <li className="letterHeadNMLS_blk">
                                                    <div className="approvalLetterHead">Mortgage Loan</div>
                                                    <div className="approvalLetterHeadPre">Pre-Approval</div>
                                                </li>
                                                <li className="letterHeadNMLS_blk">
                                                    <div className="NMLSTxt">Company NMLS: 1606866</div>
                                                </li>
                                            </ul>
                                            <div className="letterDate">February 08, 2022</div>
                                            <div className="letterTextB">Dear. Sunil Srinivasa Murthy,</div>
                                            <div className="letterText">We are pleased to notify you that your application for a mortgage pre-approval has been approved for a new home purchase. The pre-approved purchase price has been set at $171,000 with Conventional 30 year fixed financing.</div>
                                            <div className="spacer_15margin_bottom"></div>
                                            <div className="letterText">This pre-approval is valid until 02/03/2022 assuming that there are no changes in your financial situation. This pre-approval should not be considered a commitment to lend until the following conditions are met:</div>
                                            <ul className="letterBullet">
                                                <li className="letterBullet">
                                                    <div className="letterText">A valid sales contract is ratified;</div>
                                                </li>
                                                <li>
                                                    <div className="letterText">A satisfactory appraisal is accomplished on said property;</div>
                                                </li>
                                                <li>
                                                    <div className="letterText">A rate commitment is issued by our company under the above referenced mortgage program.  </div>
                                                </li>
                                            </ul>
                                            <div className="letterText">In addition to the above standard conditions, your pre-approval is subject to the following conditions:</div>
                                            <ul className="letterBullet">
                                                <li>
                                                    <div className="letterText">Satisfactory Title Report</div>
                                                </li>
                                                <li>
                                                    <div className="letterText">Satisfactory documentation to fully support continued financial qualifications as seen during initial loan application.</div>
                                                </li>
                                            </ul>
                                            <div className="letterText">This loan approval is being issued with verbal income provided and credit verification. If any figures or information change from the initial application then please let us know at your first convenience and we can update this approval for you with updated figures and requirements.</div>
                                            <div className="letterText">We wish you luck in the home buying process and we stand ready to continue our service.</div>
                                            <div className="spacer_20margin_bottom"></div>
                                            <div className="letterTextB">i3 Lending, Inc</div>
                                            <div className="letterTextB">Naresh Elluri</div>
                                            <div className="letterText">Loan Officer</div>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

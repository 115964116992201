// import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div className="app_box_shadow_noPad">
            <div className="app_headBlk_pad20">
                <div className="app_headBlk_blkName">
                    Pre-Approval
                </div>
                <div className="blockRefresh_dash"></div>

            </div>

            <ul className="dash_preapprove_IIIcol">
                <li className="dash_preapprove_IIIcol">
                    <div className="dash_preapprove_head">Pre-Approval</div>
                    <div className="dash_preapprove_head_separator"></div>
                    <div className="dash_preapproveText">Click here to get a pre-approval letter</div>
                    <div className="dash_preapprove_btnLabel"><input type="button" value="Get Pre-Approved" className="dash_preapprove_btn" onClick={() => window.fnShowZipLookUp(1)} /></div>
                </li>
                <li className="dash_preapprove_IIIcol">
                    <div className="dash_preapprove_head">Resume Saved Form</div>
                    <div className="dash_preapprove_head_separator"></div>
                    <div className="dash_preapproveText">Click here to resume and complete the saved form</div>
                    <div className="dash_preapprove_btnLabel"><input type="button" value="Resume Form" className="dash_preapprove_btn" /></div>
                </li>
                <li className="dash_preapprove_IIIcol">
                    <div className="dash_preapprove_head">Pre-Approval Letter</div>
                    <div className="dash_preapprove_head_separator"></div>
                    <div className="dash_preapproveText">Click here to download your pre-approval letter</div>
                    <div className="dash_formText" style={{ display: 'none' }}>You do not have any pre-qualification letter. <br />Please complete the pre-qualification form and download the letter from here.</div>
                    <div className="dash_preapprove_btnLabel"><input type="button" value="Download Letter" className="dash_preapprove_btn" /></div>
                </li>
            </ul>
        </div>
    )
}

export default Component;
import Container from "../calculatorcontainer";

const Component = () => {

    return (
        <>
            <Container type={"mortgagecalculator"} header={"Mortgage Calculator"}>
                <li className="calcPgStructure">
                    <div className="comingSoonText">Coming soon</div>
                </li>
            </Container>
        </>
    )
}

export default Component;
import { React, Link } from "../../../common";
import Container from "../../container";
import LeftBreadCrumb from "../leftTree"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="preapprove_pgBlk">
                                <div className="preApprove_breadcrumb">Pre-Approval: Get Pre-Approved</div>
                                <ul className="preApprove_IIcol_285by100pc">
                                    <LeftBreadCrumb />
                                    <li className="preApprove_IIcol_285by100pc">
                                        <ul className="step_count">
                                            <li className="stepHead">Now, tell us a bit more about your co-borrower</li>
                                            <li className="countBlk"><div className="countNo">5/6</div></li>
                                        </ul>

                                        <div className="purchase">
                                            <div className="spacer_25margin_bottom">
                                                <div className="pur_Q">Full Legal Name</div>
                                                <ul className="III_col">
                                                    <li className="III_col"><input type="text" placeholder="First Name" /></li>
                                                    <li className="III_col"><input type="text" placeholder="Last Name" /></li>
                                                </ul>
                                            </div>
                                            <div className="spacer_25margin_bottom">
                                                <ul className="III_col">
                                                    <li className="III_col">
                                                        <div className="pur_Q">Date of Birth</div>
                                                        <input type="text" placeholder="MM/DD/YYYY" />
                                                    </li>
                                                    <li className="III_col">
                                                        <div className="pur_Q">Phone Number</div>
                                                        <input type="text" placeholder="Last Name" />
                                                    </li>
                                                    <li className="III_col">
                                                        <div className="pur_Q">Type</div>
                                                        <select name="filterType" defaultValue={'DEFAULT'} required>
                                                            <option value="DEFAULT" disabled>Select</option>
                                                            <option value="#">Mobile</option>
                                                            <option value="#">Home</option>
                                                        </select>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="spacer_10margin_bottom">
                                                <ul className="III_col_income">
                                                    <li className="III_col_income_head">
                                                        <div className="pur_Q">Source of Income?</div>
                                                    </li>
                                                    <li className="III_col_income_head">
                                                        <div className="pur_Q">Gross Income?</div>
                                                    </li>
                                                    <li className="III_col_income_head">&nbsp;</li>
                                                </ul>
                                                <ul className="III_col_income">
                                                    <li className="III_col_income">
                                                        <select name="filterType" defaultValue={'DEFAULT'} required>
                                                            <option value="DEFAULT" disabled>Select</option>
                                                            <option value="#">Employed</option>
                                                            <option value="#">Retired</option>
                                                            <option value="#">Self Employed</option>
                                                            <option value="#">Child Support / Alimony</option>
                                                            <option value="#">Rental Income</option>
                                                            <option value="#">Other Income</option>
                                                        </select>
                                                    </li>
                                                    <li className="III_col_income">
                                                        <input type="text" placeholder="$" />
                                                    </li>
                                                    <li className="III_col_income">
                                                        <input type="button" value="&#65291;" className="button_adddelete" />
                                                    </li>
                                                </ul>
                                                <ul className="III_col_income">
                                                    <li className="III_col_income">
                                                        <select name="filterType" defaultValue={'DEFAULT'} required>
                                                            <option value="DEFAULT" disabled>Select</option>
                                                            <option value="#">Employed</option>
                                                            <option value="#">Retired</option>
                                                            <option value="#">Self Employed</option>
                                                            <option value="#">Child Support / Alimony</option>
                                                            <option value="#">Rental Income</option>
                                                            <option value="#">Other Income</option>
                                                        </select>
                                                    </li>
                                                    <li className="III_col_income">
                                                        <input type="text" placeholder="$" />
                                                    </li>
                                                    <li className="III_col_income">
                                                        <input type="button" value="&#65291;" className="button_adddelete" />
                                                    </li>
                                                </ul>
                                                <ul className="III_col_income">
                                                    <li className="III_col_income">
                                                        <select name="filterType" defaultValue={'DEFAULT'} required>
                                                            <option value="DEFAULT" disabled>Select</option>
                                                            <option value="#">Employed</option>
                                                            <option value="#">Retired</option>
                                                            <option value="#">Self Employed</option>
                                                            <option value="#">Child Support / Alimony</option>
                                                            <option value="#">Rental Income</option>
                                                            <option value="#">Other Income</option>
                                                        </select>
                                                    </li>
                                                    <li className="III_col_income">
                                                        <input type="text" placeholder="$" />
                                                    </li>
                                                    <li className="III_col_income">
                                                        <input type="button" value="&#65293;" className="button_adddelete" />
                                                    </li>
                                                </ul>

                                                <ul className="II_col_80by20">
                                                    <li className="II_col_80by20">
                                                        <ul className="II_col">
                                                            <li className="II_col">
                                                                <div className="pur_Q">Annual Gross Income</div>
                                                                <input type="text" placeholder="$" readOnly />
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="spacer_15margin_bottom">
                                                <div className="pur_Q">Military Service?</div>
                                                <ul className="pur_A">
                                                    <li><Link to="#">Yes</Link></li>
                                                    <li><Link to="#" className="current">No</Link></li>
                                                </ul>
                                            </div>
                                            <div className="button_preApprove_blk">
                                                <input type="button" value="Previous" className="button_steps" onClick={() => window.open('/preapprovalstepIV', "_self")} />
                                                <input type="button" value="Next" className="button_steps" onClick={() => window.open('/preapprovalstepVI', "_self")} />
                                            </div>
                                            <div className="errormessage" style={{ display: "none" }}>All fields are mandatory</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

import { React } from "../../../../common";
import Container from "../../../container";
import { AmtCalc } from "../../../components";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_refin_lower">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Refinance</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Steps To Refinance</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">How Much Do You Need?</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtBoldNoTopSpace">What are the steps for my refinance?</div>
                                    <div className="refin_3col_prepare">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Step</td>
                                                    <td>What To Expect</td>
                                                    <td>What To Do</td>
                                                </tr>
                                                <tr>
                                                    <td>We talk to you about which refinancing options are best for you.</td>
                                                    <td>$200,000</td>
                                                    <td>75%</td>
                                                </tr>
                                                <tr>
                                                    <td>Mortgage application</td>
                                                    <td>Once your application is submitted, we will:
                                                        Strive to give you a conditional decision
                                                        Call you to review your loan conditions, documentation and next steps
                                                        We will provide you with a good faith estimate of your closing costs within 3 business days of submitting your application.</td>
                                                    <td>Gather all documentation, including the equity you have in your home</td>
                                                </tr>
                                                <tr>
                                                    <td>Processing</td>
                                                    <td>Once we receive all your documentation, we will: <br />Process your application <br />Review your credit report and <br />Verify your information</td>
                                                    <td>Make sure you send us any additional requested information by the date on your conditional approval letter.</td>
                                                </tr>
                                                <tr>
                                                    <td>Appraisal</td>
                                                    <td>The bank arranges an appraisal of your home with a licensed appraiser. Your home’s title will be researched by a title company to be sure that the property is legally cleared.</td>
                                                    <td>The bank receives the appraiser’s report and verifies that your loan amount won’t exceed loan to value limits. A copy of the appraiser’s report is included in your mortgage closing document and the appraisal fee is included in your closing costs.</td>
                                                </tr>
                                                <tr>
                                                    <td>Underwriting</td>
                                                    <td>The underwriter receives and reviews all information provided to ensure loan guidelines have been met.</td>
                                                    <td>You must provide any additional information the bank requests in order to finalize the review process.</td>
                                                </tr>
                                                <tr>
                                                    <td>Closing</td>
                                                    <td>Generally, your mortgage will be ready to close in 30-45 days.</td>
                                                    <td>You will be informed of the time, date and location of your closing. You will receive the amount of your closing costs within 24 hours before your closing so you can get a certified or bank check for the amount.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </li>
                                <li className="IIcol_100by400">
                                    <AmtCalc />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
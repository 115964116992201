import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div>
            <div className="inPgRightHeadResponsive">Monthly EMI Calculator</div>
            <div className="setLoanAmtBlk">
                <ul className="loanAmtCalc">
                    <li className="loanAmtCalc">Loan Amount</li>
                    <li className="loanAmtCalc">
                        <div className="form_dollariconRed"></div>
                        <input type="text" />
                    </li>

                    <li className="loanAmtCalc">Interest Rate</li>
                    <li className="loanAmtCalc">
                        <div className="form_percenticonRed"></div>
                        <input type="text" />

                    </li>
                    <li className="loanAmtCalc">No. of &nbsp;Years</li>
                    <li className="loanAmtCalc">
                        <select name="filterType" defaultValue={'DEFAULT'} required>
                            <option value="DEFAULT" disabled>Select</option>
                            <option value="#">1</option>
                            <option value="#">2</option>
                            <option value="#">3</option>
                            <option value="#">4</option>
                            <option value="#">5</option>
                            <option value="#">6</option>
                            <option value="#">7</option>
                            <option value="#">8</option>
                            <option value="#">9</option>
                            <option value="#">10</option>
                            <option value="#">15</option>
                            <option value="#">20</option>
                            <option value="#">15</option>
                            <option value="#">30</option>
                        </select>
                    </li>
                </ul>
                <ul className="loanAmtEMI">
                    <li className="loanAmtEMI">Monthly EMI &nbsp;-</li>
                    <li className="loanAmtEMI">$ 0.00</li>
                </ul>
                <Link to="#"><input type="button" className="apply_button" value="Apply For Loan" /></Link>
            </div>
        </div>
    )
}

export default Component;
import { React, Link } from "../../../../common";
import Container from "../../../container";
import { LoanTabsListNav } from "../../../components";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_lo_30">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Loan Options</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt_topTxt_noRight">Renovation Loans</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="loPgStructure">
                                <li className="loPgStructure">
                                    <LoanTabsListNav type="renovation" />
                                </li>
                                <li className="loPgStructure">
                                    <ul className="loPointsBlk">
                                        <li className="loPointsBlk">
                                            <ul className="loDescPoints">
                                                <li className="loDescPoints">Receive money from the loan</li>
                                                <li className="loDescPoints">Include all costs in one loan</li>
                                                <li className="loDescPoints">Steady, affordable monthly payments</li>
                                                <li className="loDescPoints">Qualify for higher loan amounts</li>

                                                <Link to="#"><input type="button" value="Apply Now" className="loButton" /></Link>
                                            </ul>
                                        </li>
                                        <li className="loPointsBlk">
                                            <div className="pgTxtNormal">Have you been thinking about adding a deck to your home or installing new windows? Do you have big dreams for that fixer-upper you’re considering purchasing?</div>
                                            <div className="pgTxtNormal">If so, the Department of Housing and Urban Development (HUD) has a program that would allow you to get these home improvements done. The Section 203(k) program is HUD’s primary program for the rehabilitation and repair of single-family properties. This program is sometimes referred to as a “renovation loan.”</div>
                                            <div className="pgTxtNormal">Section 203 (k) loans are available through FHA-approved lenders, meaning that this is not available through conventional financing. The program was created to promote and facilitate the restoration and preservation of the nation’s existing housing stock, as well as to help revitalize lower income communities.</div>
                                            <div className="pgTxtNormal">This type of loan is different from other models of financing, which typically involve supplying mortgages for properties that are up to code and provide adequate loan security. When rehabilitation is involved, this means that a lender usually requires the improvements to be finished before a long-term mortgage is made.</div>
                                            <div className="pgTxtNormal">Conversely, a renovation loan offers homebuyers a way to purchase a house in need of repair or modernization, finance the reconstruction of the home, and obtain a permanent mortgage when the work is completed to pay off the interim loans with a permanent mortgage. This program allows the borrower to get one mortgage loan, at a long-term fixed (or adjustable) rate, to finance both the acquisition and the rehabilitation of the property.</div>
                                            <div className="pgTxtNormal">If so, the Department of Housing and Urban Development (HUD) has a program that would allow you to get these home improvements done. The Section 203(k) program is HUD’s primary program for the rehabilitation and repair of single-family properties. This program is sometimes referred to as a “renovation loan.”</div>
                                            <div className="pgTxtNormal">According to HUD’s website, the mortgage amount is based on the projected value of the property with the work completed, taking into account the cost of the work. To minimize the risk to the mortgage lender, the mortgage loan (the maximum allowable amount) is eligible for endorsement by HUD as soon as the mortgage proceeds are disbursed and a rehabilitation escrow account is established. At this point the lender has a fully-insured mortgage loan.</div>
                                            <div className="pgTxtNormal">Although luxury items and improvements are not eligible as a cost rehabilitation, funds can be used for items as painting, room additions, decks and other items even if the home does not need any other improvements. All health, safety and energy conservation items must be addressed prior to completing general home improvements.</div>
                                            <div className="pgTxtBold">What are the requirements?</div>
                                            <div className="pgTxtNormal">To be eligible, the property must meet the following criteria:</div>
                                            <ul className="bulletPoints_un">
                                                <li className="bp_circleOutline">Property must be a one- to four-family dwelling that has been completed for at least one year.</li>
                                                <li className="bp_circleOutline">The number of units on the site must be acceptable according to the provisions of local zoning requirements; cooperative units are not eligible.</li>
                                                <li className="bp_circleOutline">Homes that have been demolished, or will be razed as part of the rehabilitation work, are eligible provided some of the existing foundation system remains in place.</li>
                                            </ul>
                                            <div className="pgTxtBold">Fannie Mae’s HomePath Renovation Mortgage</div>
                                            <div className="pgTxtNormal">There is a similar type of loan program available through Fannie Mae, which is a government-sponsored enterprise. This program, called the HomePath Renovation Mortgage allows a borrower to purchase a property that requires light to moderate renovation. The one loan amount includes both the funds for the purchase and renovation – up to 35% of the as completed value, no more than $35,000.</div>
                                            <div className="pgTxtNormal">This mortgage is for purchases only and the home must be a Fannie Mae-owned property. To find out if the property you are interested in is a Fannie Mae property, check out their loan look-up tool.</div>
                                            <div className="pgTxtNormal">If you are interested in financing a property that meets the above requirements, please contact a i3 Lending representative today. We will be able to discuss different ways the program can be used, including financing for condominium units and converting one-family dwelling to a two-, three-, or four-family dwelling. One of our mortgage professionals will also be able to walk you through the application processes.</div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
import { React, Link } from "../../../../common";
import Container from "../../../container";
import { InfoFixedThirty } from "./popup";
import { InfoFixedThirtyFHA } from "./popup";
import { InfoFixedTwenty } from "./popup";
import { InfoFixedFifteen } from "./popup";

const Component = () => {

    const OnGettingStarted = async (e) => {
        e.preventDefault();
        document.getElementById("ziplook_pop").style.display = "block";
    };

    const OnPopupInfoClicked = (e, name) => {
        e.preventDefault();
        document.getElementById(name).style.display = "block";
    }

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_about">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcHeadTxt_generic">Mortgage Rates</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol">
                                <li className="IIcol">
                                    <div className="mortgageRateBlk">
                                        <div className="mr_heading">Purchase</div>
                                        <div className="mr_noteTxt">The below rates are estimated current rates as of: <b>12/Dec/2022</b></div>
                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedthirty')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">6.250<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">6.473<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed FHA
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedthirtyFHA')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">6.125<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">6.514<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">20-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedtwenty')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">6.500<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">6.801<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">15-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedfifteen')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">6.375<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">6.754<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="IIcol">
                                    <div className="mortgageRateBlk">
                                        <div className="mr_heading">Refinance</div>
                                        <div className="mr_noteTxt">The below rates are estimated current rates as of: <b>12/Dec/2022</b></div>
                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedthirty')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">6.000<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">6.219<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">30-Yr Fixed FHA
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedthirtyFHA')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">6.000<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">6.372<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">20-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedtwenty')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">6.250<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">6.539<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>

                                        <ul className="rateBlock">
                                            <li className="rateBlock">
                                                <div className="rateHead">Term</div>
                                                <div className="rateResult">15-Yr Fixed
                                                    <Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_fixedfifteen')}> <span className="rateTip"></span></Link>
                                                </div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">Interest Rate</div>
                                                <div className="rateResult">6.000<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <div className="rateHead">APR</div>
                                                <div className="rateResult">6.236<span className="symbol">%</span></div>
                                            </li>
                                            <li className="rateBlock">
                                                <input type="button" className="rate_button" value="Get Started" onClick={(e) => OnGettingStarted(e)} />
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>

            <InfoFixedThirty />
            <InfoFixedThirtyFHA />
            <InfoFixedTwenty />
            <InfoFixedFifteen />
        </>
    )
}

export default Component;

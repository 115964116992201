const Component = (props) => {
    const { title, message, display, onCancelClicked, onConfirmClicked } = props;

    const OnSubmit = async (e) => {
        if (onConfirmClicked) onConfirmClicked(e);
    };

    const OnCancelClicked = (e) => {
        if (onCancelClicked) onCancelClicked();
    };

    if (display) {
        return (
            <div id="confirm_pop" className="loader display-block">
                <section className="popup-modal">
                    <div className="confirm_pop" style={{ top: "20%" }}>
                        <div className="confirm_ContentArea">
                            <div className="confirm_Head">{title}</div>
                            <div className="confirm_content">{message}</div>
                            <div className="confirm_button_label">
                                <input
                                    type="button"
                                    className="confirmBut"
                                    value="Yes"
                                    onClick={(e) => OnSubmit(true)}
                                />
                                <input
                                    type="button"
                                    className="confirmBut"
                                    value="No"
                                    style={{ marginLeft: 5 }}
                                    onClick={(e) => OnSubmit(false)}
                                />
                                <input
                                    type="button"
                                    className="confirmBut"
                                    value="Cancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={(e) => OnCancelClicked(e)}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    } else {
        return null;
    }
};

export default Component;

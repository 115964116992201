import { React, Link } from "../../../common";
import Container from "../../container";
import LeftBreadCrumb from "../leftTree"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="preapprove_pgBlk">
                                <div className="preApprove_breadcrumb">Pre-Approval: Get Pre-Approved</div>
                                <ul className="preApprove_IIcol_285by100pc">
                                    <LeftBreadCrumb />
                                    <li className="preApprove_IIcol_285by100pc">
                                        <ul className="step_count">
                                            <li className="stepHead">Complete five easy steps to get pre-approved</li>
                                            <li className="countBlk"><div className="countNo">1/5</div></li>
                                        </ul>

                                        <div className="purchase">
                                            <div className="spacer_15margin_bottom">
                                                <div className="pur_Q">Where in the process are you?</div>
                                                <ul className="pur_A">
                                                    <li><Link to="#" className="current">I'm just researching</Link></li>
                                                    <li><Link to="#">I'm going to open houses</Link></li>
                                                    <li><Link to="#" >I'm making offers</Link></li>
                                                    <li><Link to="#" >I have signed a purchase contract</Link></li>
                                                </ul>
                                            </div>
                                            <div className="spacer_15margin_bottom">
                                                <div className="pur_Q">When do you plan on purchasing your property?</div>
                                                <ul className="pur_A">
                                                    <li><Link to="#">0 - 3 months</Link></li>
                                                    <li><Link to="#" className="current">3 - 6 months</Link></li>
                                                    <li><Link to="#" >6 + months</Link></li>
                                                    <li><Link to="#" >Not sure</Link></li>
                                                </ul>
                                            </div>
                                            <div className="spacer_15margin_bottom">
                                                <div className="pur_Q">How can we help?</div>
                                                <ul className="pur_A">
                                                    <li><Link to="#">Get a pre-approval letter</Link></li>
                                                    <li><Link to="#">Get an estimate</Link></li>
                                                    <li><Link to="#">Find a real estate agent</Link></li>
                                                </ul>
                                            </div>
                                            <div className="button_preApprove_blk">
                                                <input type="button" value="Previous" className="button_steps" disabled />
                                                <input type="button" value="Next" className="button_steps" onClick={() => window.open('/preapprovalstepII', "_self")} />
                                            </div>
                                            <div className="errormessage" style={{ display: "none" }}>All fields are mandatory</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container >
        </>
    )
}

export default Component;

import { React, Link } from "../../../common";
import Container from "../../container";
import LeftBreadCrumb from "../leftTree"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="preapprove_pgBlk">
                                <div className="preApprove_breadcrumb">Pre-Approval: Get Pre-Approved</div>
                                <ul className="preApprove_IIcol_285by100pc">
                                    <LeftBreadCrumb />
                                    <li className="preApprove_IIcol_285by100pc">
                                        <ul className="step_count">
                                            <li className="stepHead">Tell us a bit more about your property</li>
                                            <li className="countBlk"><div className="countNo">2/5</div></li>
                                        </ul>

                                        <div className="purchase">
                                            <div className="spacer_25margin_bottom">
                                                <div className="pur_Q">What is the property address you are purchasing?</div>
                                                <ul className="contentIIIcol">
                                                    <li className="contentIIIcol">
                                                        <input type="text" placeholder="Zip Code" />
                                                    </li>
                                                    <li className="contentIIIcol">
                                                        <input type="text" placeholder="City" disabled="disabled" />
                                                    </li>
                                                    <li className="contentIIIcol">
                                                        <input type="text" placeholder="State" disabled="disabled" />
                                                    </li>
                                                </ul>
                                                <input type="text" placeholder="Property Address" />
                                            </div>

                                            <div className="spacer_10margin_bottom">
                                                <ul className="contentIIcol">
                                                    <li className="contentIIcol">
                                                        <div className="pur_Q">Are you working with a Loan Officer?</div>
                                                        <select name="filterType" defaultValue={'DEFAULT'} required>
                                                            <option value="DEFAULT" disabled>Select</option>
                                                            <option value="#">Yes</option>
                                                            <option value="#">No</option>
                                                        </select>
                                                    </li>
                                                    <li className="contentIIcol">
                                                        <div className="pur_Q">Select the Loan Officer</div>
                                                        <select name="filterType" defaultValue={'DEFAULT'} required>
                                                            <option value="DEFAULT" disabled>Select</option>
                                                            <option value="#">Loan Officer 1</option>
                                                            <option value="#">Loan Officer 2</option>
                                                        </select>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="spacer_15margin_bottom">
                                                <div className="pur_Q">What property type would this be?</div>
                                                <ul className="pur_A">
                                                    <li><Link to="#">Single Family</Link></li>
                                                    <li><Link to="#" className="current">Townhouse</Link></li>
                                                    <li><Link to="#" >Condo</Link></li>
                                                    <li><Link to="#" >2 to 4 Units</Link></li>
                                                </ul>
                                            </div>
                                            <div className="spacer_15margin_bottom">
                                                <div className="pur_Q">What is the property use?</div>
                                                <ul className="pur_A">
                                                    <li><Link to="#">Primary Residence</Link></li>
                                                    <li><Link to="#" className="current">Vacation Home</Link></li>
                                                    <li><Link to="#">Investment</Link></li>
                                                </ul>
                                            </div>
                                            <div className="spacer_15margin_bottom">
                                                <div className="pur_Q">Estimated purchase price</div>
                                                <ul className="pur_A">
                                                    <li><Link to="#">Less than $100,000</Link></li>
                                                    <li><Link to="#">$100,001 to $300,000</Link></li>
                                                    <li><Link to="#">$300,001 to $500,000</Link></li>
                                                    <li><Link to="#">$500,001 +</Link></li>
                                                </ul>
                                            </div>
                                            <div className="spacer_15margin_bottom">
                                                <div className="pur_Q">Down payment</div>
                                                <ul className="pur_A">
                                                    <li><Link to="#">0% VA/USDA</Link></li>
                                                    <li><Link to="#">3% Conv.</Link></li>
                                                    <li><Link to="#">3.5% FHA</Link></li>
                                                    <li><Link to="#">5%</Link></li>
                                                    <li><Link to="#">10%</Link></li>
                                                    <li><Link to="#">20%</Link></li>
                                                </ul>
                                            </div>
                                            <div className="button_preApprove_blk">
                                                <input type="button" value="Previous" className="button_steps" onClick={() => window.open('/preapprovalstepI', "_self")} />
                                                <input type="button" value="Next" className="button_steps" onClick={() => window.open('/preapprovalstepIII', "_self")} />
                                            </div>
                                            <div className="errormessage" style={{ display: "none" }}>All fields are mandatory</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

import { React, Link } from "../../../common";
import Container from "../../container";
import LeftBreadCrumb from "../leftTree"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="preapprove_pgBlk">
                                <div className="preApprove_breadcrumb">Pre-Approval: Get Pre-Approved</div>
                                <ul className="preApprove_IIcol_285by100pc">
                                    <LeftBreadCrumb />
                                    <li className="preApprove_IIcol_285by100pc">
                                        <ul className="step_count">
                                            <li className="stepHead">Confirm your account. Then, you'll see your rate.</li>
                                            <li className="countBlk"><div className="countNo">6/6</div></li>
                                        </ul>

                                        <div className="purchase">
                                            <div className="spacer_25margin_bottom" style={{ display: "none" }}>
                                                <div className="su_method">
                                                    <div className="method_head">Connect with your social media account</div>
                                                    <div className="spacer_10margin_bottom">
                                                        <Link to="#" title="Twitter"><div className="twitterLogo"></div></Link>
                                                        <Link to="#" title="Facebook"><div className="facebookLogo"></div></Link>
                                                        <Link to="#" title="Google"><div className="googleLogo"></div></Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="su_emailmethod">Sign in using your email address</div>
                                            <div className="spacer_25margin_bottom">
                                                <div className="spacer_5margin_bottom">
                                                    <div className="pur_Q">Email Address</div>
                                                </div>
                                                <ul className="II_col">
                                                    <li className="II_col">
                                                        <input type="text" placeholder="Email Address" />
                                                    </li>
                                                    <li className="II_col">
                                                        <input type="button" value="Login with OTP" className="button_verify" />
                                                        <input type="button" value="Login with Password" className="button_verify" />
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="spacer_25margin_bottom">
                                                <div className="spacer_5margin_bottom">
                                                    <div className="pur_Q">OTP</div>
                                                </div>
                                                <ul className="II_col">
                                                    <li className="II_col">
                                                        <input type="text" />
                                                    </li>
                                                    <li className="II_col">
                                                        <div className="noteText">Please authenticate using the (OTP) One Time Password that is sent to the email address</div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="haveAcc">Don't have an account? <Link to="#">Register Now!</Link></div>

                                            <div className="button_preApprove_blk">
                                                <input type="button" value="Previous" className="button_steps" onClick={() => window.open('/preapprovalstepV', "_self")} />
                                                <input type="button" value="Next" className="button_steps" onClick={() => window.open('/preapprovalstepVII', "_self")} />
                                            </div>
                                            <div className="errormessage" style={{ display: "none" }}>All fields are mandatory</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

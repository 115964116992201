import { React } from "../../../../common";
import Container from "../../../container";
import { AmtCalc } from "../../../components";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_buy_next">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Buy a Home</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Buy your Next Home</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">How Much Do You Need?</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtNormal">No matter how many homes you've bought, purchasing your next home requires a lot of coordination. Your past home-buying experience will come in handy, and now is a good time to get up-to-speed on details that can help eliminate potential hassles.</div>
                                    <div className="pgTxtNormal">Securing the right mortgage for your next home is an important part of the process, and we're ready to help you find a mortgage that works best for you.</div>
                                    <div className="pgTxtNormal">In addition to securing a new mortgage, you'll have lots to do as you find your new home, sell your current house, and coordinate your move.</div>
                                    <div className="pgTxtNormal">Simplify your next home purchase by doing a little homework now:</div>
                                    <ul className="bulletPoints_un">
                                        <li className="bp_circleOutline">How much am I qualified to borrow?</li>
                                        <li className="bp_circleOutline">How do I use my sale proceeds for my new downpayment?</li>
                                        <li className="bp_circleOutline">How do I prepare for my mortgage application?</li>
                                        <li className="bp_circleOutline">What are the steps from mortgage prequalification to purchase?</li>
                                    </ul>
                                </li>
                                <li className="IIcol_100by400">
                                    <AmtCalc />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
import { React } from "../../common";
import Container from "../container";
import loPhoto from '../../content/img/about.jpg';

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">

                        <div id="heroImg_about" style={{ display: "none" }}>
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Senior Loan Advisor</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt_topTxt_noRight">Sarah Lender</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <div className="loLandingBlk">
                                <div className="loLandingRedBox"></div>
                                <ul className="loLandingNamePhotoBlk">
                                    <li className="loLandingPhotoBlk">
                                        <img src={loPhoto} alt="Sarah Lender" />
                                    </li>
                                    <li className="loLandingAboutTxt">
                                        <div className="loLandingName">Sarah Lender</div>
                                        <div className="loLandingPosition">Senior Loan Officer</div>
                                        <div className="loLandingAbout">A loan office is a financial institution that offers various types of loans to borrowers, such as personal loans, business loans, auto loans, and mortgages. Loan offices are often banks, credit unions, or other financial institutions that specialize in lending money to individuals and businesses.</div>
                                        <div className="loLandingAbout">Loan officers, who work in loan offices, are responsible for evaluating loan applications, determining the creditworthiness of borrowers, and ensuring that loans are repaid on time. They may also be responsible for collecting payments, managing delinquent accounts, and providing advice to borrowers on loan options and financial management.</div>

                                        <div className="loLandingBtnLabel">
                                            <input type="button" value="Apply Now" className="loLandingButton" />
                                        </div>
                                    </li>
                                    <li className="loLandingContactDetails">
                                        <div className="loLandingContact">
                                            <div className="loContactHead">Get In Touch</div>
                                            <ul className="contactIconTxtBlk">
                                                <li className="contactIconTxtBlk">
                                                    <div className="loPhoneIcon"></div>
                                                </li>
                                                <li className="contactIconTxtBlk">
                                                    <div className="loContactTextHead">Phone</div>
                                                    <div className="loContactText">(888) 975-1374</div>
                                                    <div className="loContactText">(888) 975-1375</div>
                                                </li>
                                                <li className="contactIconTxtBlk">
                                                    <div className="loEmailIcon"></div>
                                                </li>
                                                <li className="contactIconTxtBlk">
                                                    <div className="loContactTextHead">Web</div>
                                                    <div className="loContactText">sarah@i3lending.com</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

const Component = () => {
  return (
    <div id="rateQuote_pop" className="pop_disable_bg">
      <div className="rateQuote_pop">
        <div className="rateQuote_ContentArea">
          <div className="rateQuote_Head">Get a Rate Quote</div>
          <ul className="rateQuoteIIcol">
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">First Name</div>
              <input type="text" />
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Last Name</div>
              <input type="text" />
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Email</div>
              <input type="text" />
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Phone</div>
              <input type="text" />
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Loan Amount</div>
              <select name="filterType" defaultValue={"DEFAULT"} required>
                <option value="DEFAULT" disabled>
                  Select
                </option>
                <option value="#">Less than $70,000</option>
                <option value="#">$70,001 - $100,000</option>
                <option value="#">$100,001 - $150,000</option>
                <option value="#">$150,001 - $200,000</option>
                <option value="#">$200,001 + </option>
              </select>
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Property Value</div>
              <select name="filterType" defaultValue={"DEFAULT"} required>
                <option value="DEFAULT" disabled>
                  Select
                </option>
                <option value="#">Less than $70,000</option>
                <option value="#">$70,001 - $100,000</option>
                <option value="#">$100,001 - $150,000</option>
                <option value="#">$150,001 - $200,000</option>
                <option value="#">$200,001 + </option>
              </select>
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Loan Type</div>
              <select name="filterType" defaultValue={"DEFAULT"} required>
                <option value="DEFAULT" disabled>
                  Select
                </option>
                <option value="#">Purchase Loan</option>
                <option value="#">Refinance Loan</option>
                <option value="#">Debt Consoldation</option>
                <option value="#">Home Equity</option>
              </select>
            </li>
            <li className="rateQuoteIIcol">
              <div className="rq_formHead">Credit History</div>
              <select name="filterType" defaultValue={"DEFAULT"} required>
                <option value="DEFAULT" disabled>
                  Select
                </option>
                <option value="#">Excellent</option>
                <option value="#">Good</option>
                <option value="#">Fair</option>
                <option value="#">Poor</option>
              </select>
            </li>
          </ul>
          <div className="rq_button_label">
            <input type="button" className="rateQuoteBut" value="Submit" />
            <input
              type="button"
              className="rateQuoteBut"
              value="Close"
              onClick={() => window.hide("rateQuote_pop")}
            />
          </div>
          <div className="errormessage_center" style={{ display: "none" }}>
            All fields are mandatory
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;

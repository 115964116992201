import { Link } from "react-router-dom";

const Component = () => {
    return (
        <li className="preApprove_IIcol_285by100pc">
            <div className="breadcrumbHead">Home Purchase</div>
            <ul className="breadcrumb">
                <li className="breadcrumb">1</li>
                <li className="breadcrumb"><Link to="/preapprovalstepI">Get Pre-approved</Link>
                    <div className="expCol">&#8722;</div>
                    <ul className="sub_breadcrumb">
                        <li className="sub_breadcrumb">I'm just researching</li>
                        <li className="sub_breadcrumb">3 - 6 months</li>
                    </ul>
                </li>

                <li className="breadcrumb">2</li>
                <li className="breadcrumb"><Link to="/preapprovalstepII">Property Details</Link>
                    <div className="expCol">&#43;</div>
                    <ul className="sub_breadcrumb" style={{ display: "none" }}>
                        <li className="sub_breadcrumb">Green Bay, WI 54302, USA</li>
                        <li className="sub_breadcrumb">Townhouse</li>
                        <li className="sub_breadcrumb">Vacation Home</li>
                    </ul>
                </li>

                <li className="breadcrumb">3</li>
                <li className="breadcrumb"><Link to="/preapprovalstepIII">About Self</Link>
                    <div className="expCol">&#43;</div>
                    <ul className="sub_breadcrumb" style={{ display: "none" }}>
                        <li className="sub_breadcrumb">F Name: Monangku</li>
                        <li className="sub_breadcrumb">L Name: Hazarika</li>
                        <li className="sub_breadcrumb">DOB: XX/XX/XXXX</li>
                        <li className="sub_breadcrumb">Ph: XXX-XXX-XXXX</li>
                        <li className="sub_breadcrumb">Military Service: No</li>
                    </ul>
                </li>

                <li className="breadcrumb">4</li>
                <li className="breadcrumb"><Link to="/preapprovalstepIV">Income Details</Link>
                    <div className="expCol">&#43;</div>
                    <ul className="sub_breadcrumb" style={{ display: "none" }}>
                        <li className="sub_breadcrumb">Employed: $1000</li>
                        <li className="sub_breadcrumb">Gross Income: $1000</li>
                        <li className="sub_breadcrumb">Debt: $1001 - $2000</li>
                    </ul>
                </li>

                <li className="breadcrumb">5</li>
                <li className="breadcrumb"><Link to="/preapprovalstepV">Co-Borrower</Link>
                    <div className="expCol">&#43;</div>
                    <ul className="sub_breadcrumb" style={{ display: "none" }}>
                        <li className="sub_breadcrumb">F Name: Monangku</li>
                        <li className="sub_breadcrumb">L Name: Hazarika</li>
                        <li className="sub_breadcrumb">DOB: XX/XX/XXXX</li>
                        <li className="sub_breadcrumb">Ph: XXX-XXX-XXXX</li>
                        <li className="sub_breadcrumb">Employed: $1000</li>
                        <li className="sub_breadcrumb">Gross Income: $1000</li>
                        <li className="sub_breadcrumb">Military Service: No</li>
                    </ul>
                </li>
            </ul>
        </li>
    )
}

export default Component;
import { Link } from "react-router-dom";
import { LoanTabsListNav } from "../../components";

const Component = () => {
    const rateQuote = async (e) => {
        e.preventDefault();
        document.getElementById("rateQuote_pop").style.display = "block";
    };

    return (
        <div className="LoanOptionBlk">
            <div className="widthfix_10px">
                <div className="lo_blkHead">Review Your Loan Options</div>
                <div className="lo_blkTxtDesc">No two loans are alike. Let us explain each options and you can decide what program is best for you. </div>
                <ul className="loPointsDescBlk">
                    <li className="loPointsDescBlk">
                        <LoanTabsListNav type="thirty" />

                    </li>
                    <li className="loPointsDescBlk">
                        <ul className="loDescBlk">
                            <li className="loDescBlk">
                                <div className="loContentBox">
                                    <div className="loDescHead">30 Year Fixed</div>
                                    <ul className="loDescPoints">
                                        <li className="loDescPoints">Most popular mortgage plan</li>
                                        <li className="loDescPoints">No rising rates</li>
                                        <li className="loDescPoints">Steady, affordable monthly payments</li>
                                        <li className="loDescPoints">Qualify for higher loan amounts</li>
                                    </ul>
                                    <ul className="loButtonLabel">
                                        <Link to="/thirty"> <li className="loButtonLabel"><input type="button" value="Learn More" className="loButton" /></li></Link>
                                        <li className="loGetQuote"><Link to="#" className="getQuotelink" onClick={(e) => rateQuote(e)}>Get a Quote</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li className="loDescBlk"></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div >
    )
}

export default Component;
import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div className="app_box_shadow_noPad">
            <div className="app_headBlk_pad20">
                <div className="app_headBlk_blkName">
                    Requests
                </div>
                <div className="blockRefresh_dash"></div>
                <div className="selectRight">
                    <select name="filterType" required>
                        <option value="#">Only Latest</option>
                        <option value="#">All</option>
                    </select>
                </div>
            </div>

            <div className="dash_request_blk">
                <div className="dash_request_head">
                    <table>
                        <tbody>
                            <tr>
                                <td>&nbsp;</td>
                                <td>Date</td>
                                <td>Particulars</td>
                                <td>Status</td>
                                <td>&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="dash_request">
                    <div className="noContent" style={{ display: 'none' }}>No request details available</div>
                    <table>
                        <tbody>
                            <tr>
                                <td><div className="dash_unread"></div></td>
                                <td>Mar/23/2022 &nbsp;14:36</td>
                                <td>Upload income related documents for both borrower and co-borrower</td>
                                <td>Yet to upload</td>
                                <td><Link to=""><div className="dash_uploadIcon"></div></Link></td>
                            </tr>
                            <tr>
                                <td><div className="dash_read"></div></td>
                                <td>Mar/20/2022 &nbsp;11:15</td>
                                <td>Upload professional documents for co-borrower</td>
                                <td>Uploaded</td>
                                <td><div className="dash_uploadIcon_dis"></div></td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="appBox_bottom"><Link to="#">View All Requests</Link></div>
        </div>
    )
}

export default Component;
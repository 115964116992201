import { React } from "../../common";
import Container from "../container";
import { ContactInfo } from "../components";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_contact">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Get In Touch</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt">Help &amp; Support</div>
                                    </li>
                                    <li className="bc_col">
                                        <div className="bcRightBlk">Contact Information</div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtBold_L">Hello, how can we help you?</div>
                                    <div className="pgTxtNormal">Please write your query and one of our executive will get in touch with you shortly</div>
                                    <div className="contactFormNoShadow">
                                        <ul className="contactForm_IIcol">
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead_Error">Name</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormUserIcon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Phone Number</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormPhIcon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Email</div>
                                                <div className="contactFormMailIcon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Zip Code</div>
                                                <div className="contactFormHomeIcon"></div>
                                                <input type="text" />
                                            </li>
                                            {/*<li className="contactForm_IIcol">
                                                <div className="contactFormHead">City</div>
                                                <div className="contactFormLocationIcon"></div>
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">Alabama</option>
                                                    <option value="#">California</option>
                                                    <option value="#">Florida</option>
                                                </select>
                                            </li>
                                             <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Loan Type</div>
                                                <div className="contactFormTypeIcon"></div>
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">Purchase</option>
                                                    <option value="#">Refinance</option>
                                                    <option value="#">Others</option>
                                                </select>
                                            </li> */}
                                        </ul>
                                        <div className="contactForm_Icol">
                                            <div className="contactFormHead">Message</div>
                                            <div className="contactFormNoteIcon"></div>
                                            <textarea></textarea>
                                        </div>
                                        <div className="contactForm_btn_label">
                                            <input type="button" value="Reach Us" className="contactFormBtn" />
                                        </div>
                                        <div className="errormessage" style={{ display: "none" }}>You have some errors. Please fix and proceed.</div>

                                        <div className="successmessage" style={{ display: "none" }}>Thank you for contacting us. We will get back to you ASAP.</div>
                                    </div>
                                </li>
                                <li className="IIcol_100by400">
                                    <ContactInfo />
                                </li>
                            </ul >
                        </div >

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

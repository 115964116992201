import { Link } from "react-router-dom";

const tabItems = [
    { name: "thirty", link: "/thirty", icon: "lo_icon1", label: "30 Year Fixed" },
    { name: "fha", link: "/fha", icon: "lo_icon4", label: "FHA Loans" },
    { name: "va", link: "/va", icon: "lo_icon3", label: "VA Loans" },
    { name: "reverse", link: "/reverse", icon: "lo_icon5", label: "Reverse Mo" },
    { name: "usda", link: "/usda", icon: "lo_icon2", label: "USDA Loans" },
    { name: "jumbo", link: "/jumbo", icon: "lo_icon6", label: "Jumbo Loans" },
    { name: "renovation", link: "/renovation", icon: "lo_icon7", label: "Renovation" },
    { name: "nonqm", link: "/nonqm", icon: "lo_icon8", label: "Non-QM" },
    { name: "conventional", link: "/conventional", icon: "lo_icon9", label: "Conventional" }
]

const Component = ({ type }) => {
    return (
        <div>
            <ul className="loIconNavi">
                {
                    tabItems.map((x) => {
                        if (x.name === type) {
                            return (
                                <li className="loIconNavi" key={x.name}>
                                    <Link to={x.link} className="current"><div className={x.icon}></div>{x.label}</Link>
                                </li>
                            )
                        } else {
                            return (
                                <li className="loIconNavi" key={x.name}>
                                    <Link to={x.link}><div className={x.icon}></div>{x.label}</Link>
                                </li>
                            )
                        }
                    })
                }
            </ul>
        </div >
    )
}

export default Component;
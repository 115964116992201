import { React, Link } from "../../../common";
import Container from "../../container";
import Steps from "../form1003steps";
import Stages from "../form1003stage";
import { InfoLoanType } from "../popup";

const Component = () => {
    const OnPopupInfoClicked = (e, name) => {
        e.preventDefault();
        document.getElementById(name).style.display = "block";
    }

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">

                            <div className="form1003_pgBlk">
                                <ul className="formHeadStageBlk">
                                    <li className="formHeadStageBlk">1003 Form - Complete Application</li>
                                    <li className="formHeadStageBlk">
                                        <Steps />
                                    </li>
                                </ul>

                                <ul className="appFormNavi">
                                    <Stages />
                                </ul>

                                <div className="pageLabelbelow1023">Assets &amp; Liabilities</div>

                                <ul className="selectBorrowerBlk">
                                    <li className="selectBorrowerBlk">
                                        <div className="selectPadLeft10">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT">George &amp; Sophia</option>
                                                <option value="#">Isabella &amp; James</option>
                                            </select>
                                        </div>
                                    </li>
                                    <li className="selectBorrowerBlk">Fill the information for the Borrower: George and Co-Borrower: Sophia</li>
                                </ul>

                                <ul className="form1003IIcol">
                                    <li className="form1003IIcol">
                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">ASSETS -</div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <div className="form1003BlkInsideHead_topMinus40">
                                                <div className="formHeadContent_70pc">Bank Accounts, Retirement and Other Accounts You Have</div>
                                                <div className="form1003Blk_right"><input type="button" value="&#65291; Another" className="headRight_button" /></div>
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Account Type</div>
                                                    <div className="usericon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Bond</option>
                                                        <option value="#">Bridge Loan Not Deposited</option>
                                                        <option value="#">Certificate Of Deposit Time Deposit</option>
                                                        <option value="#">Checking Account</option>
                                                        <option value="#">Individual Development Account</option>
                                                        <option value="#">Life Insurance</option>
                                                        <option value="#">Money Market Fund</option>
                                                        <option value="#">Mutual Fund</option>
                                                        <option value="#">Retirement Fund</option>
                                                        <option value="#">Savings Account</option>
                                                        <option value="#">Stock</option>
                                                        <option value="#">Stock Options</option>
                                                        <option value="#">Trust Account</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Financial Institution</div>
                                                    <div className="officeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Account Number</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cash or Market Value</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="formPartition"><div className="closePartition"><Link to="#">&#x2715; &nbsp;Delete</Link></div></div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Account Type</div>
                                                    <div className="usericon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Bond</option>
                                                        <option value="#">Bridge Loan Not Deposited</option>
                                                        <option value="#">Certificate Of Deposit Time Deposit</option>
                                                        <option value="#">Checking Account</option>
                                                        <option value="#">Individual Development Account</option>
                                                        <option value="#">Life Insurance</option>
                                                        <option value="#">Money Market Fund</option>
                                                        <option value="#">Mutual Fund</option>
                                                        <option value="#">Retirement Fund</option>
                                                        <option value="#">Savings Account</option>
                                                        <option value="#">Stock</option>
                                                        <option value="#">Stock Options</option>
                                                        <option value="#">Trust Account</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Financial Institution</div>
                                                    <div className="officeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Account Number</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cash or Market Value</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Other Assets You Have</div>
                                            <div className="form1003Blk_right"><input type="button" value="&#65291; Another" className="headRight_button" /></div>
                                        </div>

                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Asset Type</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Cash On Hand</option>
                                                        <option value="#">Proceeds From Real Estate Property To Be Sold On Or Before Closing</option>
                                                        <option value="#">Proceeds From Sale of Non-Real Estate Asset</option>
                                                        <option value="#">Secured Borrowed Funds</option>
                                                        <option value="#">Unsecured Borrowed Funds</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Value</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="formPartition"><div className="closePartition"><Link to="#">&#x2715; &nbsp;Delete</Link></div></div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Asset Type</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Cash On Hand</option>
                                                        <option value="#">Proceeds From Real Estate Property To Be Sold On Or Before Closing</option>
                                                        <option value="#">Proceeds From Sale of Non-Real Estate Asset</option>
                                                        <option value="#">Secured Borrowed Funds</option>
                                                        <option value="#">Unsecured Borrowed Funds</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Value</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Other Credits You Have</div>
                                            <div className="form1003Blk_right"><input type="button" value="&#65291; Another" className="headRight_button" /></div>
                                        </div>

                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Credit Type</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Earnest Money</option>
                                                        <option value="#">Employer Assistance</option>
                                                        <option value="#">Lease Purchase Fund</option>
                                                        <option value="#">Lot Equity</option>
                                                        <option value="#">Relocation Funds</option>
                                                        <option value="#">Sweat Equity</option>
                                                        <option value="#">Trade Equity From Property Swap</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cash or Market Value</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="formPartition"><div className="closePartition"><Link to="#">&#x2715; &nbsp;Delete</Link></div></div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Credit Type</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Earnest Money</option>
                                                        <option value="#">Employer Assistance</option>
                                                        <option value="#">Lease Purchase Fund</option>
                                                        <option value="#">Lot Equity</option>
                                                        <option value="#">Relocation Funds</option>
                                                        <option value="#">Sweat Equity</option>
                                                        <option value="#">Trade Equity From Property Swap</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cash or Market Value</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="form1003IIcol">
                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">LIABILITIES -</div>
                                        </div>

                                        <div className="form1003ContentBlk">
                                            <div className="form1003BlkInsideHead_topMinus40">
                                                <div className="formHeadContent_70pc">Credits Cards, Other Debts and Lease that You Owe</div>
                                                <div className="form1003Blk_right"><input type="button" value="&#65291; Another" className="headRight_button" /></div>
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Account Type</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Revolving (eg. Credit Cards)</option>
                                                        <option value="#">Installment (eg. Car, Student, Personal Loans)</option>
                                                        <option value="#">Open 30-Day (Balance Payed Monthly)</option>
                                                        <option value="#">Lease (Not Real Estate)</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Company Name</div>
                                                    <div className="officeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Payment</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unpaid Balance</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="formPartition"><div className="closePartition"><Link to="#">&#x2715; &nbsp;Delete</Link></div></div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Account Type</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Revolving (eg. Credit Cards)</option>
                                                        <option value="#">Installment (eg. Car, Student, Personal Loans)</option>
                                                        <option value="#">Open 30-Day (Balance Payed Monthly)</option>
                                                        <option value="#">Lease (Not Real Estate)</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Company Name</div>
                                                    <div className="officeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Payment</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unpaid Balance</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Other Liabilities and Expenses</div>
                                            <div className="form1003Blk_right"><input type="button" value="&#65291; Another" className="headRight_button" /></div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Liability or Expense Type</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Alimony</option>
                                                        <option value="#">Child Support</option>
                                                        <option value="#">Job Related Expenses</option>
                                                        <option value="#">Separate Maintenance Expense</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Payment</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="formPartition"><div className="closePartition"><Link to="#">&#x2715; &nbsp;Delete</Link></div></div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Liability or Expense Type</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Alimony</option>
                                                        <option value="#">Child Support</option>
                                                        <option value="#">Job Related Expenses</option>
                                                        <option value="#">Separate Maintenance Expense</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Payment</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Real Estate</div>
                                            <div className="form1003Blk_right">&nbsp;</div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Do you currently own real estate?</div>
                                                    <ul className="form_A">
                                                        <li><Link to="#" className="current">Yes</Link></li>
                                                        <li><Link to="#" >No</Link></li>
                                                    </ul>
                                                    {/* Show below if Yes is selected */}
                                                </li>
                                            </ul>
                                            <div className="form1003BlkInsideHead">
                                                <div className="formHeadContent">Real Estate Owned</div>
                                                <div className="form1003Blk_right"><input type="button" value="&#65291; Another" className="headRight_button" /></div>
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Property Street</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unit No</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Property City</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">State</div>
                                                    <div className="locationicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Alabama</option>
                                                        <option value="#">Alaska</option>
                                                        <option value="#">Arizona</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Zip Code</div>
                                                    <div className="form_homeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Present Market Value</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Property Status</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Sold</option>
                                                        <option value="#">Pending Sale</option>
                                                        <option value="#">Retain</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Intended Occupancy</div>
                                                    <div className="form_homeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Investment</option>
                                                        <option value="#">Primary Residence</option>
                                                        <option value="#">Second Home</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Insurance, Maintenance, Misc.</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Gross Rental Income</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Mortgage Loan on this Property?</div>
                                                <ul className="form_A">
                                                    <li><Link to="#" className="current">Yes</Link></li>
                                                    <li><Link to="#" >No</Link></li>
                                                </ul>
                                                {/* Show below if Yes is selected */}
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Creditor Name</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Account Number</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Mortgage Payment</div><div className="formMandatory">*</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unpaid Balance</div><div className="formMandatory">*</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Loan Type</div><div className="formMandatory">*</div><Link to="#" title="Info" onClick={(e) => OnPopupInfoClicked(e, 'info_loanType')}><div className="formTip"></div></Link>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Conventional</option>
                                                        <option value="#">VA</option>
                                                        <option value="#">FHA</option>
                                                        <option value="#">USDA-RD</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">To Be Paid Off</div>
                                                <div className="formCheckboxLabel">
                                                    <input type="checkbox" id="cb_paid" /><label htmlFor="cb_paid">To be Paid off before closing</label>
                                                </div>
                                            </div>

                                            <div className="formPartition"><div className="closePartition"><Link to="#">&#x2715; &nbsp;Delete</Link></div></div>

                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Property Street</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unit No</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Property City</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">State</div>
                                                    <div className="locationicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Alabama</option>
                                                        <option value="#">Alaska</option>
                                                        <option value="#">Arizona</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Zip Code</div>
                                                    <div className="form_homeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Present Market Value</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Property Status</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Sold</option>
                                                        <option value="#">Pending Sale</option>
                                                        <option value="#">Retain</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Intended Occupancy</div>
                                                    <div className="form_homeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Investment</option>
                                                        <option value="#">Primary Residence</option>
                                                        <option value="#">Second Home</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Insurance, Maintenance, Misc.</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Gross Rental Income</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Mortgage Loan on this Property?</div>
                                                <ul className="form_A">
                                                    <li><Link to="#" className="current">Yes</Link></li>
                                                    <li><Link to="#" >No</Link></li>
                                                </ul>
                                                {/* Show below if Yes is selected */}
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Creditor Name</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Account Number</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Mortgage Payment</div><div className="formMandatory">*</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unpaid Balance</div><div className="formMandatory">*</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Loan Type</div><div className="formMandatory">*</div><Link to="#" title="Info" onClick={(e) => OnPopupInfoClicked(e, 'info_loanType')}><div className="formTip"></div></Link>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Conventional</option>
                                                        <option value="#">VA</option>
                                                        <option value="#">FHA</option>
                                                        <option value="#">USDA-RD</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">To Be Paid Off</div>
                                                <div className="formCheckboxLabel">
                                                    <input type="checkbox" id="cb_paid" /><label htmlFor="cb_paid">To be Paid off before closing</label>
                                                </div>
                                            </div>
                                        </div>

                                    </li>
                                </ul>
                                <ul className="form_btn_successError_blk">
                                    <li className="form_btn_steps_label">
                                        <input type="button" value="Save & Finish Later" className="form_btn_steps" disabled />
                                        <input type="button" value="Continue" className="form_btn_steps" onClick={() => window.open('./form1003stepVI', '_self')} />
                                    </li>
                                    {/* If anything is filled in the form, enable Save button */}
                                    <li className="form_successError_steps" style={{ display: "none" }}>
                                        <div className="errormessage">You have some errors. Please fix and proceed.</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <InfoLoanType />
        </>
    )
}

export default Component;
import { React, Link } from "../../../common";
import Container from "../../container";
import BreadCrumb from "../../breadcrumb"
import LoanSearch from "../loansearch"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            
                            <BreadCrumb />

                            <div className="document_pgBlk">
                                <div className="appPgStructure_Icol">
                                    <LoanSearch />
                                </div>

                                <div className="appPgStructure_Icol">
                                    <div className="app_box_shadow_noPad">
                                        <div className="app_headBlk_pad20">
                                            <div className="app_headBlk_blkName">
                                                Upload Documents for Loan No: XXX
                                            </div>
                                        </div>
                                        <div className="appContentarea_pad20">
                                            <ul className="appformColumn">

                                                <div className="uploadDoc">
                                                    <div className="uploadDocHeadBlk">
                                                        <div className="uploadHeading">Document Type</div>
                                                        <div className="selectDocType">
                                                            <select name="filterType" defaultValue={'DEFAULT'} required>
                                                                <option value="DEFAULT" disabled>Select</option>
                                                                <option value="#">Disclosure</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="appContentarea_pad20_bgTexure">
                                                        <ul className="uploadIIIcol">
                                                            <li className="uploadIIIcol">
                                                                <div className="appformContentHead">Document Name</div>
                                                                <div className="documentFormicon"></div>
                                                                <input type="text" />
                                                            </li>
                                                            <li className="uploadIIIcol">
                                                                <div className="appformContentHead">Document Information</div>
                                                                <textarea></textarea>
                                                            </li>
                                                            <li className="uploadIIIcol">
                                                                <div className="appformContentHead">Document Upload</div>
                                                                <div className="uploadSuccess">Upload Successful</div>
                                                                <div className="fileUpload">
                                                                    <div className="uploadBox"><input id="#" type="file"
                                                                        name="joining" /></div>
                                                                    <div className="buttonUploadLayout"><input type="submit"
                                                                        className="buttonUpload" value="Upload" /></div>
                                                                    <div className="submitError" >File size
                                                                        allowed: 300 Kb</div>
                                                                </div>
                                                            </li>
                                                        </ul>

                                                        <div className="uploadSubHead">
                                                            Uploaded Documents
                                                        </div>
                                                        <div className="appcontentarea_table_border">
                                                            <div className="noContent" style={{ display: "none" }}>No documents available</div>
                                                            <div className="viewDocTable_head">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><Link to="#">Document Name</Link></td>
                                                                            <td>Document Information</td>
                                                                            <td>Status</td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="viewDocTable">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Sams Documents</td>
                                                                            <td>Uploading for review</td>
                                                                            <td>Upload Successful</td>
                                                                            <td>
                                                                                <input type="button" value="Delete" className="viewDoc_btn" />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>&nbsp;</td>
                                                                            <td>&nbsp;</td>
                                                                            <td>&nbsp;</td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>&nbsp;</td>
                                                                            <td>&nbsp;</td>
                                                                            <td>&nbsp;</td>
                                                                            <td>&nbsp;</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="appform_btn_label_notop">
                                                    <input type="button" value="Submit" className="appform_btn" />
                                                    <input type="button" value="Reset" className="appform_btn" />
                                                    <div className="buttonLabel_chkbox">
                                                        <input type="checkbox" id="rp1" /><label htmlFor="rp1">Request for Approval</label>
                                                    </div>
                                                </div>
                                                <div className="errormessage" style={{ display: "none" }}>All fields are mandatory</div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

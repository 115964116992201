import { React } from "../../common";
import Container from "../container";
import BreadCrumb from "../breadcrumb"
import AppStatus from "./component/status"
import Request from "./component/request"
import Notification from "./component/notification"
import PreApproval from "./component/preapproval"
import Form1003 from "./component/form1003"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">

                            <BreadCrumb />

                            <div className="dashboard_pgBlk">
                                {/*Application Status*/}
                                <AppStatus />

                                <ul className="appPgStructure_60by40">
                                    <li className="appPgStructure_60by40">
                                        {/*Request*/}
                                        <Request />
                                    </li>

                                    <li className="appPgStructure_60by40">
                                        {/*Notifications*/}
                                        <Notification />
                                    </li>
                                </ul>

                                <ul className="appPgStructure_IIcol">
                                    <li className="appPgStructure_IIcol">
                                        {/*PreApproval*/}
                                        <PreApproval />
                                    </li>
                                    <li className="appPgStructure_IIcol">
                                        {/*Form1003*/}
                                        <Form1003 />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

// import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div>
            <div className="inPgRightHeadResponsive">Contact Information?</div>
            <div className="contactInfoBlk">
                <div className="contactInfo">
                    <div className="contInfoBlkDesc">Our customer service team is available weekdays 8 AM to 5:30 PM (EST)</div>
                    <ul className="iconDesc">
                        <li className="iconDesc">
                            <div className="contInfoLocIcon"></div>
                        </li>
                        <li className="iconDesc">
                            <div className="contInfoTxtBold">1900 Boothe Circle, Suite #100, Longwood, FL 32750</div>
                        </li>
                    </ul>
                    <ul className="iconDesc">
                        <li className="iconDesc">
                            <div className="contInfoPhIcon"></div>
                        </li>
                        <li className="iconDesc">
                            <div className="contInfoTxt">Call &nbsp;Us &nbsp;Today</div>
                            <div className="contInfoTxtBold">(888) 975-1374</div>
                        </li>
                    </ul>
                    <ul className="iconDesc">
                        <li className="iconDesc">
                            <div className="contInfoMailIcon"></div>
                        </li>
                        <li className="iconDesc">
                            <div className="contInfoTxt">Email Us</div>
                            <div className="contInfoTxtBold">info@i3lending.com</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Component;
import { React, Link } from "../../../common";
import Container from "../../container";
import Steps from "../form1003steps"
import Stages from "../form1003stage"
import { InfoCoApplicant } from "../popup";
import { InfoCoBorrower } from "../popup";
import { InfoCoApplicantCoBorrower } from "../popup";

const Component = () => {
    const OnPopupInfoClicked = (e, name) => {
        e.preventDefault();
        document.getElementById(name).style.display = "block";
    }
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">

                            <div className="form1003_pgBlk">
                                <ul className="formHeadStageBlk">
                                    <li className="formHeadStageBlk">1003 Form - Complete Application</li>
                                    <li className="formHeadStageBlk">
                                        <Steps />
                                    </li>
                                </ul>

                                <ul className="appFormNavi">
                                    <Stages />
                                </ul>

                                <div className="pageLabelbelow1023">Personal Details</div>

                                <div className="form1003Icol">
                                    <div className="form1003BlkHead">
                                        <div className="formHeadContent">Personal Details</div>
                                        <div className="form1003Blk_right">&nbsp;</div>
                                    </div>
                                    <div className="form1003ContentBlk">
                                        <ul className="formContentIVcol">
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead_Error">First Name</div><div className="formMandatory">*</div>
                                                <div className="usericon"></div>
                                                <div className="textError"><input type="text" /></div>
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Middle Name</div>
                                                <div className="usericon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Last Name</div><div className="formMandatory">*</div>
                                                <div className="usericon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Cell Phone</div><div className="formMandatory">*</div>
                                                <div className="phicon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Work Phone</div>
                                                <div className="phicon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Email Address</div><div className="formMandatory">*</div>
                                                <div className="mailicon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Is there a Co-Borrower?</div><Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_coborrower')}><div className="formTip"></div></Link>
                                                <ul className="form_A">
                                                    <li><Link to="#" className="current">Yes</Link></li>
                                                    <li><Link to="#">No</Link></li>
                                                </ul>
                                            </li>
                                            <div className="form1003BlkInsideHead">
                                                <div className="formHeadContent_85pc">Co-Borrower Details</div>
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">First Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Middle Name</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Last Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cell Phone</div><div className="formMandatory">*</div>
                                                    <div className="phicon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Email Address</div><div className="formMandatory">*</div>
                                                <div className="mailicon"></div>
                                                <input type="text" />
                                            </div>
                                        </ul>
                                    </div>
                                </div>

                                <div className="form1003Icol">
                                    <div className="form1003BlkHead_Error">
                                        <div className="formHeadContent">Other Details</div>
                                        <div className="form1003Blk_right">&nbsp;</div>
                                    </div>
                                    <div className="form1003ContentBlk">
                                        <ul className="formContentIVcol">
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Type of Credit</div><div className="formMandatory">*</div>
                                                <ul className="form_A">
                                                    <li><Link to="#">Individual</Link></li>
                                                    <li><Link to="#" className="current">Joint</Link></li>
                                                    {/* If Individual is selected, don't show the next 2 question */}
                                                </ul>
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Is there a Co-Applicant?</div><Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_coapplicant')}><div className="formTip"></div></Link>
                                                <ul className="form_A">
                                                    <li><Link to="#" className="current">Yes</Link></li>
                                                    <li><Link to="#">No</Link></li>
                                                    {/* If No is selected, don't show the next question */}
                                                </ul>
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Total Number of Co-Applicants</div><div className="formMandatory">*</div>
                                                <div className="hastagicon"></div>
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">1</option>
                                                    <option value="#">2</option>
                                                    <option value="#">3</option>
                                                    <option value="#">4</option>
                                                    <option value="#">5</option>
                                                </select>
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Co-Applicant 1 Name</div><div className="formMandatory">*</div>
                                                <div className="usericon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Co-Applicant 2 Name</div><div className="formMandatory">*</div>
                                                <div className="usericon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Co-Applicant 3 Name</div><div className="formMandatory">*</div>
                                                <div className="usericon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Are you working with a Loan Officer?</div>
                                                <ul className="form_A">
                                                    <li><Link to="#" className="current">Yes</Link></li>
                                                    <li><Link to="#" >No</Link></li>
                                                </ul>
                                                {/* If Yes is selected, show LO name */}
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">Select State</div><div className="formMandatory">*</div>
                                                <div className="usericon"></div>
                                                <select name="filterType" defaultValue={'DEFAULT'} required>
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">State 1</option>
                                                    <option value="#">State 2</option>
                                                    <option value="#">State 3</option>
                                                </select>
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead_Error">Loan Officer Name</div><div className="formMandatory">*</div>
                                                <div className="usericon"></div>
                                                <div className="selectError">
                                                    <select name="filterType" defaultValue={'DEFAULT'} required>
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Anthony Addison</option>
                                                        <option value="#">Brian Allen</option>
                                                        <option value="#">Robin Brown</option>
                                                    </select>
                                                </div>
                                            </li>
                                            <li className="formContentIVcol">
                                                <div className="form1003ContentHead">How did you hear about us?</div>
                                                <div className="noteicon"></div>
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">Facebook</option>
                                                    <option value="#">LinkedIn</option>
                                                    <option value="#">Instagram</option>
                                                    <option value="#">News Channel</option>
                                                    <option value="#">Other</option>
                                                </select>
                                                {/* If Other is selected, show next text field */}
                                            </li>
                                            <li className="formContentIVcol">
                                                {/* <div className="form1003ContentHead">&nbsp;</div>
                                                <div className="textPad10"><input type="text" /></div> */}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="form1003Icol" style={{ display: "none" }}>
                                    <div className="form1003BlkHead">
                                        <div className="formHeadContent">Co-Borrower Details</div>
                                        <div className="form1003Blk_right">&nbsp;</div>
                                    </div>
                                    <div className="form1003ContentBlk">
                                        <ul className="formContentIIcol">
                                            <li className="formContentIIcol">
                                                <div className="form1003ContentHead">First Name</div><div className="formMandatory">*</div>
                                                <div className="usericon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="formContentIIcol">
                                                <div className="form1003ContentHead">Middle Name</div>
                                                <div className="usericon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="formContentIIcol">
                                                <div className="form1003ContentHead">Last Name</div><div className="formMandatory">*</div>
                                                <div className="usericon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="formContentIIcol">
                                                <div className="form1003ContentHead">Cell Phone</div><div className="formMandatory">*</div>
                                                <div className="phicon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="formContentIIcol">
                                                <div className="form1003ContentHead">Email Address</div><div className="formMandatory">*</div>
                                                <div className="mailicon"></div>
                                                <input type="text" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <ul className="form1003IIcol">
                                    <li className="form1003IIcol">
                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Borrower: Thomas Details</div>
                                            <div className="form1003Blk_right">&nbsp;</div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">First Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Middle Name</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Last Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cell Phone</div><div className="formMandatory">*</div>
                                                    <div className="phicon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Email Address</div><div className="formMandatory">*</div>
                                                <div className="mailicon"></div>
                                                <input type="text" />
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Is there a Co-Borrower?</div><Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_coappCobor')}><div className="formTip"></div></Link>
                                                    <ul className="form_A">
                                                        <li><Link to="#" className="current">Yes</Link></li>
                                                        <li><Link to="#" >No</Link></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <div className="form1003BlkInsideHead">
                                                <div className="formHeadContent_85pc">Co-Borrower Details</div>
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">First Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Middle Name</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Last Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cell Phone</div><div className="formMandatory">*</div>
                                                    <div className="phicon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Email Address</div><div className="formMandatory">*</div>
                                                <div className="mailicon"></div>
                                                <input type="text" />
                                            </div>
                                        </div>
                                    </li>

                                    <li className="form1003IIcol">
                                        <div className="form1003BlkHead" >
                                            <div className="formHeadContent">Borrower: Sophia Details</div>
                                            <div className="form1003Blk_right">&nbsp;</div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">First Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Middle Name</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Last Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cell Phone</div><div className="formMandatory">*</div>
                                                    <div className="phicon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Email Address</div><div className="formMandatory">*</div>
                                                <div className="mailicon"></div>
                                                <input type="text" />
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Is there a Co-Borrower?</div><Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_coappCobor')}><div className="formTip"></div></Link>
                                                    <ul className="form_A">
                                                        <li><Link to="#" className="current">Yes</Link></li>
                                                        <li><Link to="#" >No</Link></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <div className="form1003BlkInsideHead">
                                                <div className="formHeadContent_85pc">Co-Borrower Details</div>
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">First Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Middle Name</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Last Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cell Phone</div><div className="formMandatory">*</div>
                                                    <div className="phicon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Email Address</div><div className="formMandatory">*</div>
                                                <div className="mailicon"></div>
                                                <input type="text" />
                                            </div>
                                        </div>
                                    </li>

                                    <li className="form1003IIcol">
                                        <div className="form1003BlkHead" >
                                            <div className="formHeadContent">Borrower: James Details</div>
                                            <div className="form1003Blk_right">&nbsp;</div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">First Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Middle Name</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Last Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cell Phone</div><div className="formMandatory">*</div>
                                                    <div className="phicon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Email Address</div><div className="formMandatory">*</div>
                                                <div className="mailicon"></div>
                                                <input type="text" />
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Is there a Co-Borrower?</div><Link to="#" onClick={(e) => OnPopupInfoClicked(e, 'info_coappCobor')}><div className="formTip"></div></Link>
                                                    <ul className="form_A">
                                                        <li><Link to="#" className="current">Yes</Link></li>
                                                        <li><Link to="#" >No</Link></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <div className="form1003BlkInsideHead">
                                                <div className="formHeadContent_85pc">Co-Borrower Details</div>
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">First Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Middle Name</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Last Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cell Phone</div><div className="formMandatory">*</div>
                                                    <div className="phicon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Email Address</div><div className="formMandatory">*</div>
                                                <div className="mailicon"></div>
                                                <input type="text" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <ul className="form_btn_successError_blk">
                                    <li className="form_btn_steps_label">
                                        <input type="button" value="Save & Finish Later" className="form_btn_steps" disabled />
                                        <input type="button" value="Continue" className="form_btn_steps" onClick={() => window.open('/form1003stepII', "_self")} />
                                    </li>
                                    {/* If anything is filled in the form, enable Save button */}
                                    <li className="form_successError_steps" style={{ display: "none" }}>
                                        <div className="errormessage">You have some errors. Please fix and proceed.</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <InfoCoApplicant />
            <InfoCoBorrower />
            <InfoCoApplicantCoBorrower />
        </>
    )
}

export default Component;
import { React } from "../../../common";
import Container from "../../container";
import LeftBreadCrumb from "../leftTree"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="preapprove_pgBlk">
                                <div className="preApprove_breadcrumb">Pre-Approval: Get Pre-Approved</div>
                                <ul className="preApprove_IIcol_285by100pc">
                                    <LeftBreadCrumb />
                                    <li className="preApprove_IIcol_285by100pc">
                                        <ul className="step_count">
                                            <li className="stepHead_100pc">One last step. Tell us about your assets, even if you don't plan on spending them</li>
                                        </ul>

                                        <div className="purchase">
                                            <ul className="III_col">
                                                <div className="spacer_25margin_bottom">
                                                    <li className="III_col">
                                                        <div className="pur_Q">Savings Account</div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="III_col">
                                                        <div className="pur_Q">Retirement Accounts</div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="III_col">
                                                        <div className="pur_Q">Stock and Bonds</div>
                                                        <input type="text" />
                                                    </li>
                                                </div>
                                                <div className="spacer_25margin_bottom">
                                                    <li className="III_col">
                                                        <div className="pur_Q">Gifts from Relatives</div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="III_col">
                                                        <div className="pur_Q">Sales of Other Real Estate</div>
                                                        <input type="text" />
                                                    </li>
                                                    <li className="III_col">
                                                        <div className="pur_Q">Other (Business Account)</div>
                                                        <input type="text" />
                                                    </li>
                                                </div>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="pur_Q">Total: $ XXXX</div>
                                            </div>

                                            <div className="button_preApprove_blk">
                                                <input type="button" value="Previous" className="button_steps" onClick={() => window.open('/preapprovalstepVIII', "_self")} />
                                                <input type="button" value="Next" className="button_steps" onClick={() => window.open('/preapprovalstepX', "_self")} />
                                            </div>
                                            <div className="errormessage" style={{ display: "none" }}>All fields are mandatory</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

import { React, Link, useNavigate, useLocation } from "../../common";
import Authentication from "../auth";
import ZipPopup from "../popups/zippop";
import PopupDialog from "../popups/popdlg";
import ConfirmDialog from "../popups/confirmdlg";
import RateQuote from "../popups/quotepop";
import ChangePassword from "../popups/changePassword";
import ProfileComplete from "../popups/profileComplete";

// Buy a Home
const mnu1 = [
  "/firsthome",
  "/nexthome",
  "/newhome",
  "/residential",
  "/foreclosed",
];
// Refinance
const mnu2 = [
  "/lowerpayment",
  "/reducerate",
  "/payoff",
  "/convert",
  "/consolidate",
  "/prepare",
  "/steps",
];
// Mortgage Tools
const mnu3 = [
  "/loanprocess",
  "/mortgagerates",
  "/mortgagecalculator",
  "/refinancecalculator",
  "/principalcalculator",
  "/extrapayment",
  "/incometoqualify",
  "/affordability",
  "/taxbenefit",
  "/apr",
  "/faq",
];
// Loan Options
const mnu4 = [
  "/thirty",
  "/fha",
  "/va",
  "/reverse",
  "/usda",
  "/jumbo",
  "/renovation",
  "/nonqm",
  "/conventional",
];
// More
const mnu5 = ["/stats", "/about", "/career", "/blogs", "/reviews", "/contact"];

const LoggedOutHeader = (props) => {
  const { loc } = props;

  const IsActive = (mnu) => {
    return mnu.findIndex((x) => x === loc) > -1 ? "dropbtn active" : "dropbtn";
  };

  return (
    <>
      <header className="header">
        <div className="widthfix">
          <div className="logo">
            <Link to="/">
              <div className="i3LendingLogo logoPositionHome"></div>
            </Link>
          </div>
          <div className="topRight_fixed_blk">
            <div className="widthfix">
              <div className="headerTopRight">
                <ul className="header_Account_blk">
                  <li className="header_profile_img"></li>
                  <li className="header_nameAcc_blk">
                    <div className="headerProfileName">Hello, Sign In</div>
                    <div className="headerAccount">
                      <div className="accountnav" id="myAccountnav">
                        <div className="dropdownAcc">
                          <button className="dropbtnAcc">
                            Account <div className="arrow_down"></div>
                          </button>
                          <div className="dropdown-content">
                            <Link
                              to="#"
                              className="icon"
                              onClick={() => window.fnSign(1)}
                            >
                              <div className="loginIcon"></div>Login / Signup
                            </Link>
                            <Link to="/support">
                              <div className="supportIcon"></div>Help &amp;
                              Support
                            </Link>
                            <Link to="/WIP">
                              <div className="fgtpasswordIcon"></div>Forgot
                              Password
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="header_Ph_blk">
                  <div className="callus">Call us at</div>
                  <div className="phNo">(888) 975-1374</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navBlock">
          <div className="widthfix">
            <div className="headerRightBlock">
              <ul className="supportNavi">
                <li className="supportNavi">
                  <Link to="/becomebranch" title="Become a Branch">
                    <div className="becomeBranchIcon"></div>
                    <div className="supportNaviText">Become a Branch</div>
                  </Link>
                </li>
                <li className="supportNavi">
                  <Link to="/findlo" title="Find an Agent">
                    <div className="findOfficerIcon"></div>
                    <div className="supportNaviText">Find a Loan Officer</div>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="topnav">
              <div className="dropdown">
                <button className={IsActive(mnu1)}>
                  Buy a Home <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/firsthome">Buy your First Home</Link>
                  <Link to="/nexthome">Buy your Next Home</Link>
                  <Link to="/newhome">Build New Home</Link>
                  <Link to="/residential">
                    Investment on Residential Property
                  </Link>
                  <Link to="/foreclosed">Buy a Foreclosed Home</Link>
                </div>
              </div>
              <div className="dropdown">
                <button className={IsActive(mnu2)}>
                  Refinance <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/lowerpayment">Lower Your Monthly Payment</Link>
                  <Link to="/reducerate">Reduce Your Current Rate</Link>
                  <Link to="/payoff">Pay Off Your Mortgage</Link>
                  <Link to="/convert">Convert To Fixed Rate</Link>
                  <Link to="/consolidate">Consolidate Your Debt</Link>
                  <Link to="/prepare">Prepare For Refinancing</Link>
                  <Link to="/steps">Steps to Refinance</Link>
                </div>
              </div>

              <div className="dropdown">
                <button className={IsActive(mnu3)}>
                  Mortgage Tools <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/loanprocess">Mortgage Process</Link>
                  <Link to="/mortgagerates">Mortgage Rates</Link>
                  <Link to="/mortgagecalculator">Mortgage Calculators</Link>
                  {/* <Link to="/refinancecalculator">Refinance Calculator</Link>
                  <Link to="/affordability">Home Affordability</Link> */}
                  <Link to="/faq">FAQ's</Link>
                </div>
              </div>

              <div className="dropdown">
                <button className={IsActive(mnu4)}>
                  Loan Options <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/thirty">30 Year Fixed Rate</Link>
                  <Link to="/fha">FHA Loans</Link>
                  <Link to="/va">VA Loans</Link>
                  <Link to="/reverse">Reverse Mortgages</Link>
                  <Link to="/usda">USDA Loans</Link>
                  <Link to="/conventional">Conventional Financing</Link>
                  <Link to="/jumbo">Jumbo Loans</Link>
                  <Link to="/renovation">Renovation Loans</Link>
                  <Link to="/nonqm">NON QM</Link>
                </div>
              </div>
              <div className="dropdown">
                <button className={IsActive(mnu5)}>
                  More <div className="line_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/stats">Lending Statistics</Link>
                  <Link to="/about">About Us</Link>
                  <Link to="/career">Careers</Link>
                  <Link to="/blogs">Blogs</Link>
                  <Link to="/reviews">Reviews</Link>
                  <Link to="/contact">Contact Us</Link>
                  <Link to="/lolanding">Loan Officer</Link>
                </div>
              </div>
              {/* <Link to="#" className="icon" onClick={() => window.myFunction()}></Link> */}
            </div>
          </div>
        </div>
      </header>

      <header className="headerResponsive">
        <div className="headerTopRightResponsive">
          <ul className="header_Account_blk_responsive">
            <li className="header_profile_img_responsive"></li>
            <li className="header_nameAcc_blk_responsive">
              <div className="headerProfileName_mini">Hello, Sign In</div>
              <div className="headerAccount_mini">
                <div className="accountnav_mini" id="myAccountnav">
                  <div className="dropdownAcc_mini">
                    <button className="dropbtnAcc_mini">
                      Account <div className="arrow_down_mini"></div>
                    </button>
                    <div className="dropdown-content_mini">
                      <Link
                        to="#"
                        className="icon"
                        onClick={() => window.fnSign(1)}
                      >
                        <div className="loginIcon"></div>Login / Signup
                      </Link>
                      <Link to="support">
                        <div className="supportIcon"></div>Help &amp; Support
                      </Link>
                      <Link to="#">
                        <div className="fgtpasswordIcon"></div>Forgot Password
                      </Link>
                    </div>
                  </div>
                  <Link to="#" className="icon"></Link>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="navBlockResponsive">
          <div className="topnav" id="myTopnav">
            <Link to="/">
              <div className="logoPosResponsive i3LendingLogo"></div>
            </Link>
            <div className="dropdown">
              <button className={IsActive(mnu1)}>
                Buy a Home <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/firsthome">Buy your First Home</Link>
                <Link to="/nexthome">Buy your Next Home</Link>
                <Link to="/newhome">Build New Home</Link>
                <Link to="/residential">
                  Investment on Residential Property
                </Link>
                <Link to="/foreclosed">Buy a Foreclosed Home</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className={IsActive(mnu2)}>
                Refinance <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/lowerpayment">Lower Your Monthly Payment</Link>
                <Link to="/reducerate">Reduce Your Current Rate</Link>
                <Link to="/payoff">Pay Off Your Mortgage</Link>
                <Link to="/convert">Convert To Fixed Rate</Link>
                <Link to="/consolidate">Consolidate Your Debt</Link>
                <Link to="/prepare">Prepare For Refinancing</Link>
                <Link to="/steps">Steps to Refinance</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className={IsActive(mnu3)}>
                Mortgage Tools <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/loanprocess">Mortgage Process</Link>
                <Link to="/mortgagerates">Mortgage Rates</Link>
                <Link to="/mortgagecalculator">Mortgage Calculators</Link>
                {/* <Link to="/refinancecalculator">Refinance Calculator</Link>
                  <Link to="/affordability">Home Affordability</Link> */}
                <Link to="/faq">FAQ's</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className={IsActive(mnu4)}>
                Loan Options <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/thirty">30 Year Fixed Rate</Link>
                <Link to="/fha">FHA Loans</Link>
                <Link to="/va">VA Loans</Link>
                <Link to="/reverse">Reverse Mortgages</Link>
                <Link to="/usda">USDA Loans</Link>
                <Link to="/conventional">Conventional Financing</Link>
                <Link to="/jumbo">Jumbo Loans</Link>
                <Link to="/renovation">Renovation Loans</Link>
                <Link to="/nonqm">NON QM</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className={IsActive(mnu5)}>
                More <div className="line_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/stats">Lending Statistics</Link>
                <Link to="/about">About Us</Link>
                <Link to="/career">Careers</Link>
                <Link to="/blogs">Blogs</Link>
                <Link to="/reviews">Reviews</Link>
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>
            <Link
              to="#"
              className="icon"
              onClick={() => window.myFunction()}
            ></Link>
          </div>
        </div>
      </header>
    </>
  );
};

const LoggedInHeader = (props) => {
  const [fullName, setFullName] = React.useState("Hello, Sign In");
  const NavigateTo = useNavigate();

  const changePassword = async (e) => {
    e.preventDefault();
    document.getElementById("changepswd_pop").style.display = "block";
  };

  React.useEffect(() => {
    const fn = () => {
      let user = sessionStorage.getItem("user");
      setFullName(user);
    };
    fn();
  }, []);

  const OnSignOutClicked = async (e) => {
    e.preventDefault();
    sessionStorage.removeItem("user");
    NavigateTo("/");
  };

  return (
    <>
      <header className="headerInpage">
        <div className="widthfix">
          <div className="topRight_fixed_blk">
            <div className="widthfix">
              <div className="headerTopRightResponsive">
                <ul className="header_Account_blk_responsive">
                  <li className="header_profile_img_responsive"></li>
                  <li className="header_nameAcc_blk_responsive">
                    <div className="headerProfileName_mini" title={fullName}>
                      {fullName}
                    </div>
                    <div className="headerAccount_mini">
                      <div className="accountnav_mini" id="myAccountnav">
                        <div className="dropdownAcc_mini">
                          <button className="dropbtnAcc_mini">
                            Account <div className="arrow_down_mini"></div>
                          </button>
                          <div className="dropdown-content_mini">
                            <Link to="/profile">
                              <div className="profileInfoIcon"></div>Profile
                            </Link>
                            <Link to="/support">
                              <div className="supportIcon"></div>Help &amp;
                              Support
                            </Link>
                            <Link to="/consentlo">
                              <div className="consentIcon"></div>Consents
                            </Link>
                            <Link to="#" onClick={(e) => changePassword(e)}>
                              <div className="fgtpasswordIcon"></div>Change Password
                            </Link>
                            <Link to="#" onClick={(e) => OnSignOutClicked(e)}>
                              <div className="logoutNewIcon"></div>Logout
                            </Link>
                          </div>
                        </div>
                        <Link
                          to="#"
                          className="icon"
                          onClick={() => window.accFunction()}
                        ></Link>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="header_Ph_blk_responsive">
                  <div className="callus">Call us at</div>
                  <div className="phNo">(888) 975-1374</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="navBlockInpage">
          <div className="widthfix">
            <div className="topnav">
              <div className="logoPosInline i3LendingLogo"></div>

              <div className="dropdown">
                <Link to="/dashboard">
                  <button className="dropbtn active">
                    <div className="dashboardIcon active"></div>Dashboard
                  </button>
                </Link>
              </div>

              <div className="dropdown">
                <button className="dropbtn">
                  <div className="documentIcon"></div>Documents{" "}
                  <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/documentview">View Documents</Link>
                  <Link to="/documentupload">Upload Documents</Link>
                  <Link to="/documentapproval">Approvals</Link>
                </div>
              </div>

              <div className="dropdown">
                <button className="dropbtn">
                  <div className="preapproveIcon"></div>Pre-Approval{" "}
                  <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/preapprovalstepI">Get Pre-Approved</Link>
                  <Link to="/preapprovalsaved">Resume Saved Form</Link>
                  <Link to="/preapprovalletterlist">Pre-Approval Letter</Link>
                </div>
              </div>

              <div className="dropdown">
                <button className="dropbtn">
                  <div className="formIcon"></div>1003 Form
                  <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/form1003stepI">Complete Application</Link>
                  <Link to="/form1003short">Short Application</Link>
                  <Link to="/form1003saved">
                    Saved &amp; Completed Applications
                  </Link>
                </div>
              </div>

              <div className="dropdown" style={{ display: "none" }}>
                <button className="dropbtn">
                  Buy a Home <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/firsthome">Buy your First Home</Link>
                  <Link to="/nexthome">Buy your Next Home</Link>
                  <Link to="#">Build New Home</Link>
                  <Link to="#">Investment on Residential Property</Link>
                  <Link to="#">Buy a Foreclosed Home</Link>
                </div>
              </div>

              <div className="dropdown" style={{ display: "none" }}>
                <button className="dropbtn">
                  Refinance <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/approvalstepI">Get Pre-Approved</Link>
                  <Link to="/WIP">Lower Monthly Payment</Link>
                  <Link to="/WIP">Reduce Current Rate</Link>
                  <Link to="/WIP">Steps to Refinance</Link>
                </div>
              </div>

              <div className="dropdown" style={{ display: "none" }}>
                <button className="dropbtn">
                  Mortgage Tools <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/WIP">Mortgage Process</Link>
                  <Link to="/WIP">Mortgage Rates</Link>
                  <Link to="/WIP">Mortgage Calculator</Link>
                  <Link to="/WIP">Refinance Calculator</Link>
                  <Link to="/WIP">Home Affordability</Link>
                  <Link to="/WIP">FAQ's</Link>
                </div>
              </div>

              <div className="dropdown" style={{ display: "none" }}>
                <button className="dropbtn">
                  Loan Options <div className="arrow_down_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/NonQM">Non-QM</Link>
                </div>
              </div>

              <div className="dropdown" style={{ display: "none" }}>
                <button className="dropbtn">
                  More <div className="line_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/WIP">About Us</Link>
                  <Link to="/WIP">Careers</Link>
                  <Link to="/WIP">Blogs</Link>
                  <Link to="/WIP">Reviews</Link>
                  <Link to="/WIP">Contact Us</Link>
                </div>
              </div>
              <Link
                to="#"
                className="icon"
                onClick={() => window.myFunction()}
              ></Link>
            </div>
          </div>
        </div>
      </header>

      <header className="headerResponsive">
        <div className="headerTopRightResponsive">
          <ul className="header_Account_blk_responsive">
            <li className="header_profile_img_responsive"></li>
            <li className="header_nameAcc_blk_responsive">
              <div className="headerProfileName_mini">Hello, Sign In</div>
              <div className="headerAccount_mini">
                <div className="accountnav_mini" id="myAccountnav">
                  <div className="dropdownAcc_mini">
                    <button className="dropbtnAcc_mini">
                      Account <div className="arrow_down_mini"></div>
                    </button>
                    <div className="dropdown-content_mini">
                      <Link to="/profile">
                        <div className="profileInfoIcon"></div>Profile
                      </Link>
                      <Link to="/support">
                        <div className="supportIcon"></div>Help &amp; Support
                      </Link>

                      <Link to="#" onClick={(e) => changePassword(e)}>
                        <div className="#"></div>Consent
                      </Link>

                      <Link to="#" onClick={(e) => changePassword(e)}>
                        <div className="fgtpasswordIcon"></div>Change Password
                      </Link>

                      <Link to="/">
                        <div className="logoutNewIcon"></div>Logout
                      </Link>
                    </div>
                  </div>
                  <Link to="#" className="icon"></Link>
                  {/* onClick={() => window.accFunction()} */}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="navBlockResponsive">
          <div className="topnav" id="myTopnav">
            <Link to="/">
              <div className="logoPosResponsive i3LendingLogo"></div>
            </Link>

            <div className="dropdown">
              <button className="dropbtn active">
                <div className="dashboardIcon active"></div>Dashboard{" "}
                <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/dashboard">Dashboard</Link>
              </div>
            </div>

            <div className="dropdown">
              <button className="dropbtn">
                <div className="documentIcon"></div>Documents{" "}
                <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/documentview">View Documents</Link>
                <Link to="/documentupload">Upload Documents</Link>
                <Link to="/documentapproval">Approvals</Link>
              </div>
            </div>

            <div className="dropdown">
              <button className="dropbtn">
                <div className="preapproveIcon"></div>Pre-Approval{" "}
                <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/approvalstepI">Get Pre-Approved</Link>
                <Link to="/preapprovalsaved">Resume Saved Form</Link>
                <Link to="/preapprovalletterlist">Pre-Approval Letter</Link>
              </div>
            </div>

            <div className="dropdown">
              <button className="dropbtn">
                <div className="formIcon"></div>1003 Form
                <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/form1003stepI">Complete Application</Link>
                <Link to="/form1003short">Short Application</Link>
                <Link to="/form1003saved">
                  Saved &amp; Completed Applications
                </Link>
              </div>
            </div>

            <div className="dropdown" style={{ display: "none" }}>
              <button className="dropbtn">
                Buy a Home <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/firsthome">Buy your First Home</Link>
                <Link to="/nexthome">Buy your Next Home</Link>
                <Link to="#">Build New Home</Link>
                <Link to="#">Investment on Residential Property</Link>
                <Link to="#">Buy a Foreclosed Home</Link>
              </div>
            </div>
            <div className="dropdown" style={{ display: "none" }}>
              <button className="dropbtn">
                Refinance <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/WIP">Get Pre-Approved</Link>
                <Link to="/WIP">Lower Monthly Payment</Link>
                <Link to="/WIP">Reduce Current Rate</Link>
                <Link to="/WIP">Steps to Refinance</Link>
              </div>
            </div>

            <div className="dropdown" style={{ display: "none" }}>
              <button className="dropbtn">
                Mortgage Tools <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/WIP">Mortgage Process</Link>
                <Link to="/WIP">Mortgage Rates</Link>
                <Link to="/WIP">Mortgage Calculator</Link>
                <Link to="/WIP">Refinance Calculator</Link>
                <Link to="/WIP">Home Affordability</Link>
                <Link to="/WIP">FAQ's</Link>
              </div>
            </div>

            <div className="dropdown" style={{ display: "none" }}>
              <button className="dropbtn">
                Loan Options <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/NonQM">Non-QM</Link>
              </div>
            </div>

            <div className="dropdown" style={{ display: "none" }}>
              <button className="dropbtn">
                More <div className="line_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/WIP">About Us</Link>
                <Link to="/WIP">Careers</Link>
                <Link to="/WIP">Blogs</Link>
                <Link to="/WIP">Reviews</Link>
                <Link to="/WIP">Contact Us</Link>
              </div>
            </div>
            <Link
              to="#"
              className="icon"
              onClick={() => window.myFunction()}
            ></Link>
          </div>
        </div>
      </header>
    </>
  );
};

const Component = (props) => {

  const [loggedIn, setLoggedIn] = React.useState(null);

  React.useEffect(() => {
    const fn = () => {
      let user = sessionStorage.getItem("user");
      if (user) setLoggedIn(user);
    };
    fn();
  }, []);

  const loc = useLocation().pathname;

  return (
    <>
      {loggedIn ? (
        <LoggedInHeader loc={loc} {...props} />
      ) : (
        <LoggedOutHeader loc={loc} {...props} />
      )}
      <Authentication />
      <ZipPopup />
      <PopupDialog />
      <RateQuote />
      <ChangePassword />
      <ProfileComplete />
      <ConfirmDialog title={"Do you want to continue with your Pre-approval?"} message={"You'll be able to pick from where you left"} display={false} />
    </>
  );
};

export default Component;

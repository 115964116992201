const Component = () => {
    return (
        <div>
            <div className="app_box_shadow">
                <ul className="summaryVblk">
                    <li className="summaryVblk">
                        <div className="summaryHead">Loan Number:</div>

                        <div className="loanSearchTxtBox">
                            <input type="text" />
                        </div>
                        <div className="iconsearch"></div>

                        <div className="loanSearchSelect" style={{ display: "none" }}>
                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                <option value="DEFAULT" disabled>Select</option>
                                <option value="#">123456</option>
                            </select>
                        </div>
                    </li>
                    {/* <li className="noLoan">
                        Please select a loan to view the loan details
                    </li> */}
                    <li className="summaryVblk">
                        <div className="summaryHead">Borrower Name:</div>
                        <div className="summaryText">Mark Wilson</div>
                    </li>
                    <li className="summaryVblk">
                        <div className="summaryHead">Loan Amount:</div>
                        <div className="summaryText">$ 489,000.00</div>
                    </li>
                    <li className="summaryVblk">
                        <div className="summaryHead">Issuing Authority:</div>
                        <div className="summaryText">i3 Lending</div>
                    </li>
                    <li className="summaryVblk">
                        <div className="summaryHead">Est Close Date:</div>
                        <div className="summaryText">Oct/11/2025</div>
                    </li>
                    <li className="summaryVblk">
                        <div className="summaryHead">Property Address:</div>
                        <div className="summaryText">9205, Topeka Street, Bethesda, TN 20817  </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Component;
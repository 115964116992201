import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div className="app_box_shadow_noPad">
            <div className="app_headBlk_pad20">
                <div className="app_headBlk_blkName">
                    Notifications
                </div>
                <div className="blockRefresh_dash"></div>
            </div>
            <div className="appContentarea">
                <div className="dash_notify_blk">
                    <div className="dash_notify_head">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Particulars</td>
                                    <td>Date</td>
                                    <td>Action</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="dash_notify">
                        <div className="noContent" >No new notifictions</div>
                        <table style={{ display: 'none' }}>
                            <tbody>
                                <tr>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="appBox_bottom"><Link to="#">View All Notifications</Link></div>
        </div>
    )
}

export default Component;
const Component = () => {
    return (
        <div id="info_coapplicant" className="pop_disable_bg">
            <div className="coApplicant_pop">
                <div className="pop_head">Co-Applicant</div>
                <div className="pop_contentArea">
                    <div className="pop_contentHead">Select this option if you like to add third party other than your spouse as your co-applicant for the loan</div>
                </div>
                <div className="pop_buttonLabel">
                    <input type="button" value="Close" className="pop_button" onClick={() => window.hide('info_coapplicant')} />
                </div>
            </div>
        </div>
    );
};

export default Component;
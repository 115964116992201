import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div className="widthfix_10px">
            <ul className="aboutContainer">
                <li className="aboutContainer">
                    <div className="aboutImgBlk">
                        <div className="expBlk">
                            <div className="expBlkContent">
                                <div className="txtExpNumber">10<span className="symbolFont">&#43;</span></div>
                                <div className="txtExp">Years Experience</div>
                            </div>
                        </div>
                        <div className="aboutImage"></div>
                    </div>
                </li>
                <li className="aboutContainer">
                    <div className="aboutContentBlk">
                        <div className="abt_headTxt">About Us</div>
                        <div className="abt_heroTxt">Innovation, Inspiration and Integrity</div>
                        <div className="abt_pointHeadTxt">First is Innovation.</div>
                        <div className="abt_pointTxt">This company is innovative in bringing the latest technology, the best rates
                            and service possible.</div>
                        <div className="abt_pointHeadTxt">Second is Inspiration.</div>
                        <div className="abt_pointTxt">Our block chain innovation inspires all the possibilities in helping others to get
                            their dream home.</div>
                        <div className="abt_pointHeadTxt">Third is Integrity.</div>
                        <div className="abt_pointTxt">With those inspired we are guided by Integrity ensuring we are helping our
                            clients for a lifetime.</div>
                        <ul className="abt_uspPoint">
                            <li className="abt_uspPoint">Solid Team</li>
                            <li className="abt_uspPoint">Faster Process</li>
                            <li className="abt_uspPoint">Easy Documentation</li>
                            <li className="abt_uspPoint">24/7 Online Support</li>
                        </ul>
                        <Link to="/about"><input type="button" className="abt_button" value="learn more &#43;" /></Link>
                    </div>

                </li>
            </ul>
        </div>
    )
}

export default Component;
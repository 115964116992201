import { React, Link } from "../../../common";
import Container from "../../container";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="preapprove_pgBlk">
                                <div className="preApprove_breadcrumb">Pre-Approval: Approval Letters</div>
                                <div className="savedApprovalLetterBlk">
                                    <div className="noContent" style={{ display: "none" }}>No pre-approval letter available</div>

                                    <div className="approvalList_head">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Property Address</td>
                                                    <td>Date &amp; Time</td>
                                                    <td>Approved Amount</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="approvalList">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><Link to="">1900 Boothe Circle, Suite #100, Longwood, FL 32750</Link></td>
                                                    <td>12/25/2022 &nbsp; 13:16</td>
                                                    <td>$1,75,000</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container >
        </>
    )
}

export default Component;

import { React, Link } from "../../../common";
import Container from "../../container";
import Steps from "../form1003steps"
import Stages from "../form1003stage"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">

                            <div className="form1003_pgBlk">
                                <ul className="formHeadStageBlk">
                                    <li className="formHeadStageBlk">1003 Form - Complete Application</li>
                                    <li className="formHeadStageBlk">
                                        <Steps />
                                    </li>
                                </ul>

                                <ul className="appFormNavi">
                                    <Stages />
                                </ul>

                                <div className="pageLabelbelow1023">Borrower and Co-Borrower Information</div>

                                <ul className="selectBorrowerBlk">
                                    <li className="selectBorrowerBlk">
                                        <div className="selectPadLeft10">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                {/* <option value="DEFAULT" disabled>Select</option> */}
                                                <option value="#">Borrower: George</option>
                                                <option value="DEFAULT">Co-Borrower: Sophia</option>
                                                <option value="#">Borrower: Isabella</option>
                                                <option value="#">Co-Borrower: James</option>
                                            </select>
                                        </div>
                                    </li>
                                    <li className="selectBorrowerBlk">Fill the information for the Co-Borrower Sophia <Link to="../../form1003stepIII">Borrower Link</Link></li>
                                </ul>

                                <ul className="form1003IIcol">
                                    <li className="form1003IIcol">
                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Co-Borrower Information</div>
                                            <div className="form1003Blk_right">&nbsp;</div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">First Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Middle Name</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Last Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cell Phone</div><div className="formMandatory">*</div>
                                                    <div className="phicon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Email Address</div><div className="formMandatory">*</div>
                                                <div className="mailicon"></div>
                                                <input type="text" />
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Social Security Number</div>
                                                    <div className="lockicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Date of Birth</div>
                                                    <div className="form_dateicon"></div>
                                                    <input type="text" placeholder="MM/DD/YYYY" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Marital Status</div><div className="formMandatory">*</div>
                                                    <div className="matitalicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Married</option>
                                                        <option value="#">Unmarried</option>
                                                        <option value="#">Separated</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Do you have dependents?</div>
                                                    <ul className="form_A">
                                                        <li><Link to="#" className="current">Yes</Link></li>
                                                        <li><Link to="#" >No</Link></li>
                                                    </ul>
                                                    {/* If Yes is selected, show dependent age */}
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Dependent Age</div>
                                                    <div className="form1003ContentHead_right">
                                                        <input type="button" value="Add Dependent" className="contentHeadRight_button" />
                                                        <input type="button" value="&#8722;" className="contentHeadRight_button" />
                                                    </div>
                                                    <div className="timeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Citizenship</div>
                                                    <div className="usericon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">U.S. Citizen</option>
                                                        <option value="#">Permanent Resident Alien</option>
                                                        <option value="#">Non-Permanent Resident Alien</option>
                                                    </select>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Current Address</div>
                                            <div className="form1003Blk_right">
                                                <div className="headRightLabel_chkbox">
                                                    <input type="checkbox" id="cb_address" /><label htmlFor="cb_address">Same as Borrower Address</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Street</div><div className="formMandatory">*</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unit No</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Zip Code</div><div className="formMandatory">*</div>
                                                    <div className="form_homeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">City</div><div className="formMandatory">*</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">State</div><div className="formMandatory">*</div>
                                                    <div className="locationicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Alabama</option>
                                                        <option value="#">Alaska</option>
                                                        <option value="#">Arizona</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Country</div><div className="formMandatory">*</div>
                                                    <div className="countryicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Housing</div><div className="formMandatory">*</div>
                                                    <div className="form_homeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">No Primary Housing Expense</option>
                                                        <option value="#">Rent</option>
                                                        <option value="#">Own</option>
                                                    </select>
                                                </li>
                                                {/* Show Monthly Rent Amount, if rent is selected in housing */}
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Rent Amount</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">How Long at Address</div><div className="formMandatory">*</div>
                                                    <ul className="formContentIIcol_noBot">
                                                        <li className="formContentIIcol_noBot">
                                                            <div className="form_dateicon"></div>
                                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                                <option value="DEFAULT" disabled>Year</option>
                                                                <option value="#">1</option>
                                                                <option value="#">2</option>
                                                                <option value="#">3</option>
                                                                <option value="#">4</option>
                                                                <option value="#">5</option>
                                                                <option value="#">6</option>
                                                                <option value="#">7</option>
                                                                <option value="#">8</option>
                                                                <option value="#">9</option>
                                                                <option value="#">10</option>
                                                                <option value="#">11</option>
                                                                <option value="#">12</option>
                                                                <option value="#">13</option>
                                                                <option value="#">14</option>
                                                                <option value="#">15</option>
                                                            </select>
                                                        </li>
                                                        <li className="formContentIIcol_noBot">
                                                            <div className="form_dateicon"></div>
                                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                                <option value="DEFAULT" disabled>Mth</option>
                                                                <option value="#">1</option>
                                                                <option value="#">2</option>
                                                                <option value="#">3</option>
                                                                <option value="#">4</option>
                                                                <option value="#">5</option>
                                                                <option value="#">6</option>
                                                                <option value="#">7</option>
                                                                <option value="#">8</option>
                                                                <option value="#">9</option>
                                                                <option value="#">10</option>
                                                                <option value="#">11</option>
                                                                <option value="#">12</option>
                                                            </select>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">&nbsp;</div>
                                                    <div className="form1003NoteText">
                                                        Please fill previous address, if stay in current address is less than 2 years
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>

                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Mailing Address</div>
                                            <div className="form1003Blk_right">
                                                <div className="headRightLabel_chkbox">
                                                    <input type="checkbox" id="cb_address" /><label htmlFor="cb_address">Same as Co-Borrower Current Address</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Street</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unit No</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">City</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">State</div>
                                                    <div className="locationicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Alabama</option>
                                                        <option value="#">Alaska</option>
                                                        <option value="#">Arizona</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Zip Code</div>
                                                    <div className="form_homeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Country</div>
                                                    <div className="countryicon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Current Employment/Self-Employment</div>
                                            <div className="form1003Blk_right">&nbsp;</div>
                                        </div>

                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Employer or Business Name</div>
                                                    <div className="officeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Phone</div>
                                                    <div className="phicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Street</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unit No</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">City</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">State</div>
                                                    <div className="locationicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Alabama</option>
                                                        <option value="#">Alaska</option>
                                                        <option value="#">Arizona</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Zip Code</div>
                                                    <div className="form_homeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Country</div>
                                                    <div className="countryicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Position or Title</div>
                                                    <div className="noteicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Start date</div>
                                                    <div className="form_dateicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">How long in this line of work?</div>
                                                    <ul className="formContentIIcol_noBot">
                                                        <li className="formContentIIcol_noBot">
                                                            <div className="form_dateicon"></div>
                                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                                <option value="DEFAULT" disabled>Year</option>
                                                                <option value="#">1</option>
                                                                <option value="#">2</option>
                                                                <option value="#">3</option>
                                                                <option value="#">4</option>
                                                                <option value="#">5</option>
                                                                <option value="#">6</option>
                                                                <option value="#">7</option>
                                                                <option value="#">8</option>
                                                                <option value="#">9</option>
                                                                <option value="#">10</option>
                                                                <option value="#">11</option>
                                                                <option value="#">12</option>
                                                                <option value="#">13</option>
                                                                <option value="#">14</option>
                                                                <option value="#">15</option>
                                                            </select>
                                                        </li>
                                                        <li className="formContentIIcol_noBot">
                                                            <div className="form_dateicon"></div>
                                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                                <option value="DEFAULT" disabled>Mth</option>
                                                                <option value="#">1</option>
                                                                <option value="#">2</option>
                                                                <option value="#">3</option>
                                                                <option value="#">4</option>
                                                                <option value="#">5</option>
                                                                <option value="#">6</option>
                                                                <option value="#">7</option>
                                                                <option value="#">8</option>
                                                                <option value="#">9</option>
                                                                <option value="#">10</option>
                                                                <option value="#">11</option>
                                                                <option value="#">12</option>
                                                            </select>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">&nbsp;</div>
                                                    <div className="form1003NoteText">
                                                        Please fill previous employment, if the current employment is less than 2 years
                                                    </div>
                                                </li>
                                                <div className="spacer_20margin_bottom">
                                                    <div className="form1003ContentHead">Check if this statement applies</div>
                                                    <div className="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_statement" /><label htmlFor="cb_statement">I am employed by a family member, property seller, real estate agent, or other party to the transaction</label>
                                                    </div>
                                                </div>
                                                <div className="spacer_20margin_bottom">
                                                    <div className="form1003ContentHead">Business Owner or Self-Employed</div>
                                                    <div className="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_bose" /><label htmlFor="cb_bose">Check if you are the Business Owner or Self-Employed</label>
                                                    </div>
                                                </div>
                                                {/* Show Ownership Share and Monthly Income, if business owner or self employed is selected. Don't show Gross Monthly Income */}
                                                <div className="spacer_20margin_bottom">
                                                    <div className="form1003ContentHead">Ownership Share</div>
                                                    <ul className="form_A">
                                                        <li><Link to="#" className="current">Have ownership share &#60; 25%</Link></li>
                                                        <li><Link to="#" >Have ownership share &#62; 25%</Link></li>
                                                    </ul>
                                                </div>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Income (or Loss)</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="form1003BlkInsideHead">
                                                <div className="formHeadContent">Gross Monthly Income</div>
                                                <div className="form1003Blk_right">&nbsp;</div>
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Base</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Overtime</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Bonus</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Commission</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Military Entitlements</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Other</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>

                                    </li>
                                    <li className="form1003IIcol">
                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Complete Information for Employment and Income</div>
                                            <div className="form1003Blk_right">&nbsp;</div>
                                        </div>

                                        <div className="form1003ContentBlk">
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Complete Information for Additional Employment/Self-Employment and Income</div>
                                                <ul className="form_A">
                                                    <li><Link to="#" className="current">Yes</Link></li>
                                                    <li><Link to="#" >No</Link></li>
                                                </ul>
                                                {/* Show below if Yes is selected */}
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Employer or Business Name</div>
                                                    <div className="officeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Phone</div>
                                                    <div className="phicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Street</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unit No</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">City</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">State</div>
                                                    <div className="locationicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Alabama</option>
                                                        <option value="#">Alaska</option>
                                                        <option value="#">Arizona</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Zip Code</div>
                                                    <div className="form_homeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Country</div>
                                                    <div className="countryicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Position or Title</div>
                                                    <div className="noteicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Start date</div>
                                                    <div className="form_dateicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">How long in this line of work?</div>
                                                    <ul className="formContentIIcol_noBot">
                                                        <li className="formContentIIcol_noBot">
                                                            <div className="form_dateicon"></div>
                                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                                <option value="DEFAULT" disabled>Year</option>
                                                                <option value="#">1</option>
                                                                <option value="#">2</option>
                                                                <option value="#">3</option>
                                                                <option value="#">4</option>
                                                                <option value="#">5</option>
                                                                <option value="#">6</option>
                                                                <option value="#">7</option>
                                                                <option value="#">8</option>
                                                                <option value="#">9</option>
                                                                <option value="#">10</option>
                                                                <option value="#">11</option>
                                                                <option value="#">12</option>
                                                                <option value="#">13</option>
                                                                <option value="#">14</option>
                                                                <option value="#">15</option>
                                                            </select>
                                                        </li>
                                                        <li className="formContentIIcol_noBot">
                                                            <div className="form_dateicon"></div>
                                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                                <option value="DEFAULT" disabled>Mth</option>
                                                                <option value="#">1</option>
                                                                <option value="#">2</option>
                                                                <option value="#">3</option>
                                                                <option value="#">4</option>
                                                                <option value="#">5</option>
                                                                <option value="#">6</option>
                                                                <option value="#">7</option>
                                                                <option value="#">8</option>
                                                                <option value="#">9</option>
                                                                <option value="#">10</option>
                                                                <option value="#">11</option>
                                                                <option value="#">12</option>
                                                            </select>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <div className="spacer_20margin_bottom">
                                                    <div className="form1003ContentHead">Check if this statement applies</div>
                                                    <div className="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_statement1" /><label htmlFor="cb_statement1">I am employed by a family member, property seller, real estate agent, or other party to the transaction</label>
                                                    </div>
                                                </div>
                                                <div className="spacer_20margin_bottom">
                                                    <div className="form1003ContentHead">Business Owner or Self-Employed</div>
                                                    <div className="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_bose1" /><label htmlFor="cb_bose1">Check if you are the Business Owner or Self-Employed</label>
                                                    </div>
                                                </div>
                                                {/* Show Ownership Share and Monthly Income, if business owner or self employed is selected. Don't show Gross Monthly Income */}
                                                <div className="spacer_20margin_bottom">
                                                    <div className="form1003ContentHead">Ownership Share</div>
                                                    <ul className="form_A">
                                                        <li><Link to="#" className="current">Have ownership share &#60; 25%</Link></li>
                                                        <li><Link to="#" >Have ownership share &#62; 25%</Link></li>
                                                    </ul>
                                                </div>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Income (or Loss)</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="form1003BlkInsideHead">
                                                <div className="formHeadContent">Gross Monthly Income</div>
                                                <div className="form1003Blk_right">&nbsp;</div>
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Base</div>
                                                    <div className="form_dollaricon icon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Overtime</div>
                                                    <div className="form_dollaricon icon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Bonus</div>
                                                    <div className="form_dollaricon icon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Commission</div>
                                                    <div className="form_dollaricon icon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Military Entitlements</div>
                                                    <div className="form_dollaricon icon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Other</div>
                                                    <div className="form_dollaricon icon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Complete Information for Previous Employment and Income</div>
                                            <div className="form1003Blk_right">&nbsp;</div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Do you want to add Complete Information for Previous Employment/Self-Employment and Income?</div>
                                                <ul className="form_A">
                                                    <li><Link to="#" className="current">Yes</Link></li>
                                                    <li><Link to="#" >No</Link></li>
                                                </ul>
                                                {/* Show below if Yes is selected */}
                                            </div>
                                            <div className="form1003BlkInsideHead">
                                                <div className="formHeadContent_85pc">Provide at least 2 years of current and previous Emp</div>
                                                <div className="form1003Blk_right"><input type="button" value="&#65291; New" className="headRight_button" /></div>
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Employer or Business Name</div>
                                                    <div className="officeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Street</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unit No</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">City</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">State</div>
                                                    <div className="locationicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Alabama</option>
                                                        <option value="#">Alaska</option>
                                                        <option value="#">Arizona</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Zip Code</div>
                                                    <div className="form_homeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Country</div>
                                                    <div className="countryicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Position or Title</div>
                                                    <div className="noteicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">From date</div>
                                                    <div className="form_dateicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">To date</div>
                                                    <div className="form_dateicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <div className="spacer_20margin_bottom">
                                                    <div className="form1003ContentHead">Business Owner or Self-Employed</div>
                                                    <div className="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_bo" /><label htmlFor="cb_bo">Check if you were the Business Owner or Self-Employed</label>
                                                    </div>
                                                </div>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Previous Monthly Income</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="formPartition"><div className="closePartition"><Link to="#">&#x2715; &nbsp;Delete</Link></div></div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Employer or Business Name</div>
                                                    <div className="officeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Street</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unit No</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">City</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">State</div>
                                                    <div className="locationicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Alabama</option>
                                                        <option value="#">Alaska</option>
                                                        <option value="#">Arizona</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Zip Code</div>
                                                    <div className="form_homeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Country</div>
                                                    <div className="countryicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Position or Title</div>
                                                    <div className="noteicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">From date</div>
                                                    <div className="form_dateicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">To date</div>
                                                    <div className="form_dateicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <div className="spacer_20margin_bottom">
                                                    <div className="form1003ContentHead">Business Owner or Self-Employed</div>
                                                    <div className="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_bo" /><label htmlFor="cb_bo">Check if you were the Business Owner or Self-Employed</label>
                                                    </div>
                                                </div>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Previous Monthly Income</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Income from Other Sources</div>
                                            <div className="form1003Blk_right"><input type="button" value="&#65291; Add Another" className="headRight_button" /></div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Income Source</div>
                                                    <div className="form_dollaricon icon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Accessory Unit Income</option>
                                                        <option value="#">Alimony</option>
                                                        <option value="#">Automobile Allowance</option>
                                                        <option value="#">Boarder Income</option>
                                                        <option value="#">Capital Gains</option>
                                                        <option value="#">Child Support</option>
                                                        <option value="#">Defined Contribution Plan</option>
                                                        <option value="#">Disability</option>
                                                        <option value="#">Dividends Interest</option>
                                                        <option value="#">Employment Related Account</option>
                                                        <option value="#">Foster Care</option>
                                                        <option value="#">Housing Allowance</option>
                                                        <option value="#">Housing Choice Voucher Program</option>
                                                        <option value="#">Mortgage Credit Certificate</option>
                                                        <option value="#">Mortgage Differential</option>
                                                        <option value="#">Non Borrower Household Income</option>
                                                        <option value="#">Notes Receivable Installment</option>
                                                        <option value="#">Pension</option>
                                                        <option value="#">Public Assistance</option>
                                                        <option value="#">Royalties</option>
                                                        <option value="#">Separate Maintenance</option>
                                                        <option value="#">Social Security</option>
                                                        <option value="#">Temporary Leave</option>
                                                        <option value="#">Tip Income</option>
                                                        <option value="#">Trust</option>
                                                        <option value="#">Unemployment</option>
                                                        <option value="#">VA Benefits(Non Educational)</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Income</div>
                                                    <div className="form_dollaricon icon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="formPartition"><div className="closePartition"><Link to="#">&#x2715; &nbsp;Delete</Link></div></div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Income Source</div>
                                                    <div className="form_dollaricon icon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Accessory Unit Income</option>
                                                        <option value="#">Alimony</option>
                                                        <option value="#">Automobile Allowance</option>
                                                        <option value="#">Boarder Income</option>
                                                        <option value="#">Capital Gains</option>
                                                        <option value="#">Child Support</option>
                                                        <option value="#">Defined Contribution Plan</option>
                                                        <option value="#">Disability</option>
                                                        <option value="#">Dividends Interest</option>
                                                        <option value="#">Employment Related Account</option>
                                                        <option value="#">Foster Care</option>
                                                        <option value="#">Housing Allowance</option>
                                                        <option value="#">Housing Choice Voucher Program</option>
                                                        <option value="#">Mortgage Credit Certificate</option>
                                                        <option value="#">Mortgage Differential</option>
                                                        <option value="#">Non Borrower Household Income</option>
                                                        <option value="#">Notes Receivable Installment</option>
                                                        <option value="#">Pension</option>
                                                        <option value="#">Public Assistance</option>
                                                        <option value="#">Royalties</option>
                                                        <option value="#">Separate Maintenance</option>
                                                        <option value="#">Social Security</option>
                                                        <option value="#">Temporary Leave</option>
                                                        <option value="#">Tip Income</option>
                                                        <option value="#">Trust</option>
                                                        <option value="#">Unemployment</option>
                                                        <option value="#">VA Benefits(Non Educational)</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Income</div>
                                                    <div className="form_dollaricon icon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>

                                    </li>
                                </ul>
                                <ul className="form_btn_successError_blk">
                                    <li className="form_btn_steps_label">
                                        <input type="button" value="Save & Finish Later" className="form_btn_steps" disabled />
                                        <input type="button" value="Continue" className="form_btn_steps" onClick={() => window.open('./form1003stepV', '_self')} />
                                    </li>
                                    {/* If anything is filled in the form, enable Save button */}
                                    <li className="form_successError_steps" style={{ display: "none" }}>
                                        <div className="errormessage">You have some errors. Please fix and proceed.</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
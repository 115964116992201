import { React, Link } from "../../../common";
import Container from "../../container";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="form1003_pgBlk">
                                <ul className="formHeadStageBlk">
                                    <li className="formHeadStageBlk">1003 Form - Short Application</li>
                                    <li className="formHeadStageBlk">&nbsp;</li>
                                </ul>

                                <ul className="form1003IIcol">
                                    <li className="form1003IIcol">
                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Personal Details</div>
                                            <div className="form1003Blk_right">&nbsp;</div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead_Error">First Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Last Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Email Address</div><div className="formMandatory">*</div>
                                                <div className="mailicon"></div>
                                                <input type="text" />
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Loan Officer Name</div>
                                                    <div className="usericon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Anthony Addison</option>
                                                        <option value="#">Brian Allen</option>
                                                        <option value="#">Robin Brown</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Is there a Co-Borrower?</div><div className="formMandatory">*</div>
                                                    <ul className="form_A">
                                                        <li><Link to="#" className="current">Yes</Link></li>
                                                        <li><Link to="#" >No</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Evening Phone</div><div className="formMandatory">*</div>
                                                    <div className="phicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Work Phone</div>
                                                    <div className="phicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">How would you like to be contacted</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Evening Phone</option>
                                                        <option value="#">Work Phone</option>
                                                        <option value="#">Email</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">My / Our Credit is</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Very Good</option>
                                                        <option value="#">Good</option>
                                                        <option value="#">Some Problem</option>
                                                        <option value="#">Major Problem</option>
                                                        <option value="#">I am not sure</option>
                                                    </select>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="form1003IIcol">
                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Loan and Property Info</div>
                                            <div className="form1003Blk_right">&nbsp;</div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <div className="spacer_20margin_bottom">
                                                <div className="form1003ContentHead">Property Address</div>
                                                <div className="form_homeicon"></div>
                                                <input type="text" />
                                            </div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">City</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">State</div>
                                                    <div className="locationicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Alabama</option>
                                                        <option value="#">Alaska</option>
                                                        <option value="#">Arizona</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Zip Code</div>
                                                    <div className="form_homeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Purpose of Loan</div><div className="formMandatory">*</div>
                                                    <div className="locationicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Construction</option>
                                                        <option value="#">Refinance</option>
                                                        <option value="#">Construction - Permanant</option>
                                                        <option value="#">Other</option>
                                                        <option value="#">Purchase</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Property Will Be</div><div className="formMandatory">*</div>
                                                    <div className="locationicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Primary Residence</option>
                                                        <option value="#">Secondary Residence</option>
                                                        <option value="#">Investment Property</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Purchase Price (Estimated Value)</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="form_btn_successError_blk">
                                    <li className="form_btn_steps_srt_label">
                                        <input type="button" value="Submit" className="form_btn_steps" />
                                    </li>
                                    <li className="form_successError_srt_steps">
                                        <div className="errormessage">You have some errors. Please fix and submit.</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
import { React, Link } from "../../../../common";
import Container from "../../../container";
import { LoanTabsListNav } from "../../../components";

const Component = () => {

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_lo_30">
                            <ul className="bc_bg">
                                <div className="widthfix_10px">
                                    <li className="bc_col">
                                        <div className="bcBlkDesc">
                                            <div className="blkDescTxt">Loan Options</div>
                                            <div className="rightCurve"></div>
                                        </div>
                                        <div className="bcHeadTxt_topTxt_noRight">Non QM</div>
                                    </li>
                                    <li className="bc_col">&nbsp;</li>
                                </div>
                            </ul>
                        </div>

                        <div className="widthfix_10px">
                            <ul className="loPgStructure">
                                <li className="loPgStructure">
                                    <LoanTabsListNav type="nonqm" />
                                </li>
                                <li className="loPgStructure">
                                    <ul className="loPointsBlk">
                                        <li className="loPointsBlk">
                                            <ul className="loDescPoints">
                                                <li className="loDescPoints">Receive money from the loan</li>
                                                <li className="loDescPoints">Include all costs in one loan</li>
                                                <li className="loDescPoints">Steady, affordable monthly payments</li>
                                                <li className="loDescPoints">Qualify for higher loan amounts</li>

                                                <Link to="#"><input type="button" value="Apply Now" className="loButton" /></Link>
                                            </ul>
                                        </li>
                                        <li className="loPointsBlk">
                                            <div className="pgTxtBoldNoTopSpace">Non QM Product Highlights:</div>

                                            <ul className="bulletPoints_un">
                                                <li className="bp_squareOutline">620 Min FICO</li>
                                                <li className="bp_squareOutline">Loan Amounts up to $2,000,000 on all products</li>
                                                <li className="bp_squareOutline">Up to 90% LTV with NO MI</li>
                                                <li className="bp_squareOutline"><b>Bank Statement Loans (Self Employed)</b></li>
                                                <div className="bp_indent_30">
                                                    <li className="bp_circleOutline">620 Min FICO</li>
                                                    <li className="bp_circleOutline">Up to 90% LTV , NO MI</li>
                                                    <li className="bp_circleOutline">Loan Amounts up to $2,000,000</li>
                                                    <li className="bp_circleOutline">SFR, Condo, 2-4 units ok</li>
                                                    <li className="bp_circleOutline">12 and 24 months Personal Bank Statements</li>
                                                    <li className="bp_circleOutline">12 and 24 months Business Bank Statements</li>
                                                    <li className="bp_circleOutline">NO P&L statement required on Business Bank Statements</li>
                                                    <li className="bp_circleOutline">DTI up to 50% back end in some cases</li>
                                                </div>
                                                <li className="bp_squareOutline"><b>Jumbo Agency Alternative Loans</b></li>
                                                <div className="bp_indent_30">
                                                    <li className="bp_circleOutline">620 Min FICO</li>
                                                    <li className="bp_circleOutline">Loan Amounts up to $2,000,000</li>
                                                    <li className="bp_circleOutline">Owner occupied and Investment Properties</li>
                                                    <li className="bp_circleOutline">SFR, Condo, 2-4 Units Ok</li>
                                                    <li className="bp_circleOutline">620 min – 80% LTV</li>
                                                    <li className="bp_circleOutline">680 min – 90% LTV, No MI</li>
                                                    <li className="bp_circleOutline">3 or 6 months reserves only required on many programs</li>
                                                    <li className="bp_circleOutline">DTI as high as 50% back end on some programs</li>
                                                    <li className="bp_circleOutline">Interest Only Options</li>
                                                    <li className="bp_circleOutline">Cash Out up to $1,000,000 cash in hand</li>
                                                </div>
                                                <li className="bp_squareOutline"><b>Recent Credit Events</b></li>
                                                <div className="bp_indent_30">
                                                    <li className="bp_circleOutline">Borrowers that can’t qualify for Conventional or FHA</li>
                                                    <li className="bp_circleOutline">620 Min FICO</li>
                                                    <li className="bp_circleOutline">Loan Amounts up to $2,000,000</li>
                                                    <li className="bp_circleOutline">LTVs up to 85%</li>
                                                </div>
                                                <div className="bp_indent_60">
                                                    <li className="bp_circle">Mortgage Lates in the last 12 months (30s, 60s, 90s, 120s)</li>
                                                    <li className="bp_circle">1 day out of foreclosure</li>
                                                    <li className="bp_circle">1 day out of short sale</li>
                                                    <li className="bp_circle">1 day out of BK</li>
                                                </div>
                                                <li className="bp_squareOutline"><b>Investment Property Loans</b></li>
                                                <div className="bp_indent_30">
                                                    <li className="bp_circleOutline">620 Min FICO</li>
                                                    <li className="bp_circleOutline">SFR, Condo, 2-4 Units Ok</li>
                                                    <li className="bp_circleOutline">Loan Amounts up to $2,000,000</li>
                                                    <li className="bp_circleOutline">Unlimited owned Properties</li>
                                                    <li className="bp_circleOutline">Unlimited Financed Properties</li>
                                                    <li className="bp_circleOutline">Close in LLC or Corporation or Trust</li>
                                                    <li className="bp_circleOutline">Full Doc or  Bank Statements , or No Income / No Assets</li>
                                                    <li className="bp_circleOutline">NO Income / No Asset</li>
                                                </div>
                                                <div className="bp_indent_60">
                                                    <li className="bp_circle">Credit and Collateral Loan</li>
                                                    <li className="bp_circle">DSCR or No DSCR option</li>
                                                    <li className="bp_circle">No income docs, No Job Listed on 1003, No Assets Required</li>
                                                </div>
                                                <div className="bp_indent_90">
                                                    <li className="bp_circleDouble">640 Min FICO</li>
                                                    <li className="bp_circleDouble">LTVS up to 80% (with DSCR)</li>
                                                    <li className="bp_circleDouble">LTVS up to 75% (with No DSCR)</li>
                                                </div>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
const Component = () => {
    return (
        <div>
            <div className="inPgRightHeadResponsive">Leave Your Phone Number</div>
            <div className="requestCallBackBlk">
                <div className="requestCallBack">
                    <input type="text" placeholder="Name" />
                    <input type="text" placeholder="Email Id" />
                    <input type="text" placeholder="Phone Number" />
                </div>
                <input type="button" className="callBack_button" value="Request Call Back" />
            </div>
        </div>
    )
}

export default Component;
import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div>
            <li className="appFormNavi_5step">
                <Link to="/form1003stepI" className="passed"><div className="navinumber passed">1</div><div className="naviTxt">Personal Details</div></Link>
            </li>
            <li className="appFormNavi_5step">
                <Link to="/form1003stepII" className="current"><div className="navinumber active">2</div><div className="naviTxt">Loan Details</div></Link>
            </li>
            <li className="appFormNavi_5step">
                <Link to="/form1003stepIII"><div className="navinumber">3</div><div className="naviTxt">Borrower &amp; Co-Borrower Information</div></Link>
            </li>
            {/* <li className="appFormNavi_5step">
                <Link to="/form1003stepIV"><div className="navinumber">4</div><div className="naviTxt">Co-Borrower</div></Link>
            </li>
            <li className="appFormNavi_5step">
                <Link to="/form1003stepVI"><div className="navinumber">5</div><div className="naviTxt">Co-Applicant</div></Link>
            </li> */}
            <li className="appFormNavi_5step">
                <Link to="/form1003stepV"><div className="navinumber">4</div><div className="naviTxt">Assets &amp; Liabilities</div></Link>
            </li>
            <li className="appFormNavi_5step">
                <Link to="/form1003stepVI"><div className="navinumber">5</div><div className="naviTxt">Declarations</div></Link>
            </li>
        </div>
    )
}

export default Component;
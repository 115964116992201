const Component = () => {
  return (
    <div id="ziplook_pop" className="pop_disable_bg">
      <div className="ziplook_pop">
        <div className="ziplook_ContentArea">
          <div className="ziplook_Head">Where are you looking?</div>
          <input type="text" placeholder="City or Zip Code" required />
          <div className="ziplook_button_label">
            <input type="button" className="ziplookBut" value="Submit" />
            <input type="button" className="ziplookBut" value="Close" onClick={() => window.hide('ziplook_pop')} />
          </div>
          <div className="errormessage_center" style={{ display: "none" }}>City or Zip is mandatory</div>
        </div>
      </div>
    </div>
  );
};

export default Component;

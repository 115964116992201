import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../screens/home";
import Dashboard from "../screens/dashboard";
import Profile from "../screens/profile";
import Support from "../screens/support";
import ConsentLO from "../screens/consentlo";
import Blog1 from "../screens/headertabs/generic/blog/blog1";
import Blog2 from "../screens/headertabs/generic/blog/blog2";
import Blog3 from "../screens/headertabs/generic/blog/blog3";
import LoLanding from "../screens/lolanding";

// Generic Page Links
import {
  Statistics, About, Career, Blog, Review, Contact,
  BecomeBranch, FindLoanOfficer, Terms,
  Privacy, Disclosure, Branch, Quote, Consent
} from "../screens/headertabs/generic";

//Document Links
import {
  DocumentView, DocumentUpload, DocumentApproval
} from "../screens/document";

//PreApproval Links
import {
  PreApprovalStepI, PreApprovalStepII, PreApprovalStepIII, PreApprovalStepIV, PreApprovalStepV, PreApprovalStepVI, PreApprovalStepVII, PreApprovalStepVIII, PreApprovalStepIX, PreApprovalStepX, PreApprovalSaved, PreApprovalLetterList
} from "../screens/preapproval";

//Form1003 Links
import {
  Form1003Short, Form1003StepI, Form1003StepII, Form1003StepIII, Form1003StepIV, Form1003StepV, Form1003StepVI, Form1003Saved
} from "../screens/form1003";

//Buy Home Links
import {
  BuyFirstHome, BuyNextHome, BuildNewHome, ResidentialProperty, ForeclosedHome
} from "../screens/headertabs/buy";

//Refinance Links
import {
  LowerPayment, ReduceRate, PayOff,
  Convert, Consolidate, PrepareRefinance, RefinanceSteps
} from "../screens/headertabs/refinance";

//Mortgage Tools
import {
  LoanProcess, MortgageRates, FAQ
} from "../screens/headertabs/mortgagetools";

//Calculators
import {
  MortgageCalculator, RefinanceCalculator, PrincipalCalculator, ExtraPayment, IncomeToQualify, Affordability, TaxBenefits, APR
} from "../screens/headertabs/mortgagetools/calculators";

//Loan Options Links
import {
  ThirtyYr, FHA, VA, Reverse, USDA, Jumbo,
  Renovation, NonQM, Conventional
} from "../screens/headertabs/loanoptions";

const RouteLinks = [
  { id: 1, path: "", Component: Home },
  { id: 2, path: "firsthome", Component: BuyFirstHome },
  { id: 3, path: "nexthome", Component: BuyNextHome },
  { id: 4, path: "newhome", Component: BuildNewHome },
  { id: 5, path: "residential", Component: ResidentialProperty },
  { id: 6, path: "foreclosed", Component: ForeclosedHome },
  { id: 7, path: "lowerpayment", Component: LowerPayment },
  { id: 8, path: "reducerate", Component: ReduceRate },
  { id: 9, path: "payoff", Component: PayOff },
  { id: 10, path: "convert", Component: Convert },
  { id: 11, path: "consolidate", Component: Consolidate },
  { id: 12, path: "prepare", Component: PrepareRefinance },
  { id: 13, path: "steps", Component: RefinanceSteps },
  { id: 14, path: "loanprocess", Component: LoanProcess },
  { id: 15, path: "mortgagerates", Component: MortgageRates },
  { id: 16, path: "mortgagecalculator", Component: MortgageCalculator },
  { id: 17, path: "refinancecalculator", Component: RefinanceCalculator },
  { id: 18, path: "principalcalculator", Component: PrincipalCalculator },
  { id: 19, path: "faq", Component: FAQ },
  { id: 20, path: "thirty", Component: ThirtyYr },
  { id: 21, path: "fha", Component: FHA },
  { id: 22, path: "va", Component: VA },
  { id: 23, path: "reverse", Component: Reverse },
  { id: 24, path: "usda", Component: USDA },
  { id: 25, path: "jumbo", Component: Jumbo },
  { id: 26, path: "renovation", Component: Renovation },
  { id: 27, path: "nonqm", Component: NonQM },
  { id: 28, path: "conventional", Component: Conventional },
  { id: 29, path: "stats", Component: Statistics },
  { id: 30, path: "about", Component: About },
  { id: 31, path: "career", Component: Career },
  { id: 32, path: "blogs", Component: Blog },
  { id: 33, path: "reviews", Component: Review },
  { id: 34, path: "contact", Component: Contact },
  { id: 35, path: "becomebranch", Component: BecomeBranch },
  { id: 36, path: "findlo", Component: FindLoanOfficer },
  { id: 37, path: "terms", Component: Terms, },
  { id: 38, path: "privacy", Component: Privacy },
  { id: 39, path: "disclosure", Component: Disclosure },
  { id: 40, path: "branch", Component: Branch },
  { id: 41, path: "quote", Component: Quote },
  { id: 42, path: "dashboard", Component: Dashboard },
  { id: 43, path: "documentview", Component: DocumentView },
  { id: 44, path: "documentupload", Component: DocumentUpload },
  { id: 45, path: "documentapproval", Component: DocumentApproval },
  { id: 46, path: "profile", Component: Profile },
  { id: 47, path: "preapprovalstepI", Component: PreApprovalStepI },
  { id: 48, path: "preapprovalstepII", Component: PreApprovalStepII },
  { id: 49, path: "preapprovalstepIII", Component: PreApprovalStepIII },
  { id: 50, path: "preapprovalstepIV", Component: PreApprovalStepIV },
  { id: 51, path: "preapprovalstepV", Component: PreApprovalStepV },
  { id: 52, path: "preapprovalstepVI", Component: PreApprovalStepVI },
  { id: 53, path: "preapprovalstepVII", Component: PreApprovalStepVII },
  { id: 54, path: "preapprovalstepVIII", Component: PreApprovalStepVIII },
  { id: 55, path: "preapprovalstepIX", Component: PreApprovalStepIX },
  { id: 56, path: "preapprovalstepX", Component: PreApprovalStepX },
  { id: 57, path: "form1003short", Component: Form1003Short },
  { id: 58, path: "form1003stepI", Component: Form1003StepI },
  { id: 59, path: "form1003stepII", Component: Form1003StepII },
  { id: 60, path: "form1003stepIII", Component: Form1003StepIII },
  { id: 61, path: "form1003stepIV", Component: Form1003StepIV },
  { id: 62, path: "form1003stepV", Component: Form1003StepV },
  { id: 63, path: "form1003stepVI", Component: Form1003StepVI },
  { id: 64, path: "form1003saved", Component: Form1003Saved },
  { id: 65, path: "preapprovalsaved", Component: PreApprovalSaved },
  { id: 66, path: "preapprovalletterlist", Component: PreApprovalLetterList },
  { id: 67, path: "support", Component: Support },
  { id: 68, path: "extrapayment", Component: ExtraPayment },
  { id: 69, path: "incometoqualify", Component: IncomeToQualify },
  { id: 70, path: "affordability", Component: Affordability },
  { id: 71, path: "taxbenefit", Component: TaxBenefits },
  { id: 72, path: "apr", Component: APR },
  { id: 73, path: "blog1", Component: Blog1 },
  { id: 74, path: "blog2", Component: Blog2 },
  { id: 75, path: "blog3", Component: Blog3 },
  { id: 76, path: "consent", Component: Consent },
  { id: 77, path: "consentlo", Component: ConsentLO },
  { id: 77, path: "lolanding", Component: LoLanding },
];

function PrivateRoute({ children }) {
  let loggedin = sessionStorage.getItem("user");
  return loggedin ? children : <Navigate to="/" />;
}

const RendorRoutes = () => {
  return (
    <Routes>
      {RouteLinks.map((x) => {
        if (x.Session) {
          return <Route key={x.id} path={"/" + x.path} exact
            element={
              <PrivateRoute>
                <x.Component />
              </PrivateRoute>
            }
          />
        } else {
          return <Route key={x.id} path={"/" + x.path} exact
            element={<x.Component />} />
        }
      })}
    </Routes>
  );
};

export default RendorRoutes;

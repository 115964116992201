import { Link } from "react-router-dom";

const FooterFull = () => {
    const OnFBClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.facebook.com/i3loans";
        window.open(_link, "_blank");
    };

    const OnTwitterClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://twitter.com/i3lending";
        window.open(_link, "_blank");
    };

    const OnLinkedinClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.linkedin.com/company/i3-lending-inc";
        window.open(_link, "_blank");
    };

    const OnInstaClicked = (e) => {
        e.preventDefault();
        const _link =
            "https://www.instagram.com/i3lending";
        window.open(_link, "_blank");
    };

    return (
        <div id="footer_home">
            <div className="widthfix">
                <ul className="footerIIIcol">
                    <li className="footerIIIcol">
                        <ul className="footerLogoSocial">
                            <li className="footerLogoSocial">
                                <div className="i3LendingWhiteLogo footerLogoSetting"></div>
                                <div className="footerNMLStxt">NMLS Id:</div>
                                <div className="footerNMLStxt">1020884</div>
                            </li>
                            <li className="footerLogoSocial">
                                <div className="footerHeadText">Social</div>
                                <ul className="socialmedia">
                                    <li className="socialmedia">
                                        <Link to="#" onClick={OnFBClicked}><div className="icon_fb"></div></Link>
                                    </li>
                                    <li className="socialmedia">
                                        <Link to="#" onClick={OnTwitterClicked}><div className="icon_twitter"></div></Link>
                                    </li>
                                    <li className="socialmedia">
                                        <Link to="#" onClick={OnLinkedinClicked}><div className="icon_linkedin"></div></Link>
                                    </li>
                                    <li className="socialmedia">
                                        <Link to="#" onClick={OnInstaClicked}><div className="icon_insta"></div></Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div className="footerCopyright">i3 Lending Inc &copy; {new Date().getFullYear()} | All rights reserved.</div>
                        <div className="footerTerms"><Link to="/terms" className="termsLink">Terms of Use</Link>&nbsp; | &nbsp;<Link to="/privacy" className="termsLink">Privacy Policy</Link></div>
                    </li>
                    <li className="footerIIIcol">
                        <div className="footerHeadText">Quick Links</div>
                        <ul className="footerQuickLink">
                            <li className="footerQuickLink"><Link to="/reviews" className="quickLink">Reviews</Link></li>
                            <li className="footerQuickLink"><Link to="/branch" className="quickLink">Branch Locations</Link></li>
                            <li className="footerQuickLink"><Link to="/disclosure" className="quickLink">Disclosures and Licenses</Link></li>
                            <li className="footerQuickLink"><Link to="#" className="quickLink">NMLS Consumer Access Lookup</Link></li>
                            <li className="footerQuickLink"><Link to="#" className="quickLink">Texas Recovery Fund Notice</Link></li>
                        </ul>
                    </li>
                    <li className="footerIIIcol">
                        <div className="footerHeadText">Get Updates</div>
                        <div className="footerGetUpdate">We'll keep you posted on rates, mortgage news, home tips and other  great offers</div>
                        <input type="email" placeholder="Your E-mail" />
                        <input type="button" value="Subscribe" className="footer_button" />
                    </li>
                </ul>
            </div>

        </div>
    );
}

const FooterSmall = () => {
    return (
        <div id="footer">
            <div className="footer_blk">
                <div className="widthfix">
                    <ul className="footerLinks">
                        <li className="footerLinks"><Link to="/privacy">Privacy Policy</Link></li>
                        {/* <li className="footerLinks"><Link to="#">Cookies</Link></li> */}
                        <li className="footerLinks"><Link to="/terms">Terms Of Use</Link></li>
                        {/* <li className="footerLinks"><Link to="#">Accessibility</Link></li> */}
                        <li className="footerLinks"><Link to="/about">About Us</Link></li>
                        <li className="footerLinks"><Link to="/contact">Contact Us</Link></li>
                    </ul>
                    <div className="footer_copyright">
                        i3 Lending Inc &copy; {new Date().getFullYear()} &nbsp;All Rights Reserved.
                    </div>
                </div>
            </div>
        </div>
    );
}

const Component = ({ type }) => {
    return type ? <FooterFull /> : <FooterSmall />
};

export default Component;
const Component = () => {
    return (
        <div id="home_bottom_blk">
            <div className="widthfix_10px">
                <ul className="home_IV_col">
                    <li className="home_IV_col">
                        <ul className="bottomIconText_blk">
                            <li className="bottomIcon_blk"><div className="bottomIcon1"></div></li>
                            <li className="bottomText_blk">
                                <div className="bottom_blk_desc">Rates as low as</div>
                                <div className="bottom_blk_duration">from</div>
                                <div className="bottom_blk_number">5</div>
                                <div className="bottom_blk_number">.75</div>
                                <div className="bottom_blk_sign">%</div>
                            </li>
                        </ul>
                    </li>
                    <li className="home_IV_col">
                        <ul className="bottomIconText_blk">
                            <li className="bottomIcon_blk"><div className="bottomIcon2"></div></li>
                            <li className="bottomText_blk">
                                <div className="bottom_blk_desc">Amount of credit</div>
                                <div className="bottom_blk_duration">upto</div>
                                <div className="bottom_blk_sign">$</div>
                                <div className="bottom_blk_number">5</div>
                                <div className="bottom_blk_sign">M</div>
                            </li>
                        </ul>
                    </li>
                    <li className="home_IV_col">
                        <ul className="bottomIconText_blk">
                            <li className="bottomIcon_blk"><div className="bottomIcon3"></div></li>
                            <li className="bottomText_blk">
                                <div className="bottom_blk_desc">Fast &amp; Easy Process</div>
                                <div className="bottom_blk_duration">only</div>
                                <div className="bottom_blk_number">7</div>
                                <div className="bottom_blk_sign">Days</div>
                            </li>
                        </ul>
                    </li>
                    <li className="home_IV_col">
                        <ul className="bottomIconText_blk">
                            <li className="bottomIcon_blk"><div className="bottomIcon4"></div></li>
                            <li className="bottomText_blk">
                                <div className="bottom_blk_desc">Long Loan Term</div>
                                <div className="bottom_blk_duration">upto</div>
                                <div className="bottom_blk_number">30</div>
                                <div className="bottom_blk_sign">Years</div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Component;
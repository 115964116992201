import { React } from "../../../common";
import Container from "../../container";
import LeftBreadCrumb from "../leftTree"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">
                            <div className="preapprove_pgBlk">
                                <div className="preApprove_breadcrumb">Pre-Approval: Get Pre-Approved</div>
                                <ul className="preApprove_IIcol_285by100pc">
                                    <LeftBreadCrumb />
                                    <li className="preApprove_IIcol_285by100pc">
                                        <ul className="step_count">
                                            <li className="stepHead_100pc">Just a few more steps until you are pre-approved</li>
                                        </ul>

                                        <div className="purchase">
                                            <div className="fico_head">Your FICO&trade; Score is 710</div>
                                            <div className="spacer_25margin_bottom">
                                                FICO Score Graph Goes Here
                                            </div>

                                            <div className="button_preApprove_blk">
                                                <input type="button" value="Previous" className="button_steps" onClick={() => window.open('/preapprovalstepVII', "_self")} />
                                                <input type="button" value="Next" className="button_steps" onClick={() => window.open('/preapprovalstepIX', "_self")} />
                                            </div>
                                            <div className="errormessage" style={{ display: "none" }}>All fields are mandatory</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;

import { React, Link } from "../../../common";
import Container from "../../container";
import Steps from "../form1003steps"
import Stages from "../form1003stage"
import { InfoMortgageApplied } from "../popup";
import { InfoLoanAmount } from "../popup";
import { InfoInterestRate } from "../popup";
import { InfoLoanTerm } from "../popup";
import { InfoAmortizationType } from "../popup";
import { InfoLoanPurpose } from "../popup";
import { InfoOccupancy } from "../popup";

const Component = () => {
    const OnPopupInfoClicked = (e, name) => {
        e.preventDefault();
        document.getElementById(name).style.display = "block";
    }
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">

                            <div className="form1003_pgBlk">
                                <ul className="formHeadStageBlk">
                                    <li className="formHeadStageBlk">1003 Application</li>
                                    <li className="formHeadStageBlk">
                                        <Steps />
                                    </li>
                                </ul>

                                <ul className="appFormNavi">
                                    <Stages />
                                </ul>

                                <div className="pageLabelbelow1023">Loan Details</div>

                                <ul className="form1003IIcol">
                                    <li className="form1003IIcol">
                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Loan Details</div>
                                            <div className="form1003Blk_right">&nbsp;</div>
                                        </div>
                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Mortgage Applied For</div><Link to="#" title="Info" onClick={(e) => OnPopupInfoClicked(e, 'info_mortgageApplied')}><div className="formTip"></div></Link>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Conventional</option>
                                                        <option value="#">VA</option>
                                                        <option value="#">FHA</option>
                                                        <option value="#">USDA-RD</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Loan Amount</div><div className="formMandatory">*</div><Link to="#" title="Info" onClick={(e) => OnPopupInfoClicked(e, 'info_loanAmount')}><div className="formTip"></div></Link>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Interest Rate</div><Link to="#" title="Info" onClick={(e) => OnPopupInfoClicked(e, 'info_interestRate')}><div className="formTip"></div></Link>
                                                    <div className="form_percenticon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Loan Term in Months</div><Link to="#" title="Info" onClick={(e) => OnPopupInfoClicked(e, 'info_loanTerm')}><div className="formTip"></div></Link>
                                                    <div className="form_dateicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Amortization Type</div><Link to="#" title="Info" onClick={(e) => OnPopupInfoClicked(e, 'info_amortizationType')}><div className="formTip"></div></Link>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Fixed rate</option>
                                                        <option value="#">Adjustable rate</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Loan Purpose</div><Link to="#" title="Info" onClick={(e) => OnPopupInfoClicked(e, 'info_loanPurpose')}><div className="formTip"></div></Link>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Purchase</option>
                                                        <option value="#">Refinance</option>
                                                        <option value="#">Other</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Purchase Price</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Occupancy</div><Link to="#" title="Info" onClick={(e) => OnPopupInfoClicked(e, 'info_occupancy')}><div className="formTip"></div></Link>
                                                    <div className="form_homeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Primary Residence</option>
                                                        <option value="#">Secondary Home</option>
                                                        <option value="#">Investment Property</option>
                                                        <option value="#">Investment FHA</option>
                                                    </select>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Property Details</div>
                                            <div className="form1003Blk_right">&nbsp;</div>
                                        </div>

                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Street</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Unit No</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">City</div>
                                                    <div className="locationicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">State</div>
                                                    <div className="locationicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Alabama</option>
                                                        <option value="#">Alaska</option>
                                                        <option value="#">Arizona</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Zip Code</div>
                                                    <div className="form_homeicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">County</div>
                                                    <div className="countryicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Number of Units</div>
                                                    <div className="hastagicon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Appraised Value</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>

                                    </li>
                                    <li className="form1003IIcol">
                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent">Gifts/Grants You Have Been Receive for this Loan</div>
                                            <div className="form1003Blk_right">
                                                <input type="button" value="&#65291; New" className="headRight_button" />
                                            </div>
                                        </div>


                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Asset Type</div>
                                                    <div className="gifticon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Cash Gift</option>
                                                        <option value="#">Gift or Equity</option>
                                                        <option value="#">Grant</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Deposited/Not Deposited</div>
                                                    <ul className="form_A">
                                                        <li><Link to="#" className="current">Deposited</Link></li>
                                                        <li><Link to="#" >Not Deposited</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Source</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Community Nonprofit</option>
                                                        <option value="#">Employer</option>
                                                        <option value="#">Federal Agency</option>
                                                        <option value="#">Local Agency</option>
                                                        <option value="#">Relative</option>
                                                        <option value="#">Religious Nonprofit</option>
                                                        <option value="#">State Agency</option>
                                                        <option value="#">Unmarried Partner</option>
                                                        <option value="#">Lender</option>
                                                        <option value="#">Parent</option>
                                                        <option value="#">Non Parent Relative</option>
                                                        <option value="#">Unrelated Friend</option>
                                                        <option value="#">Others</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cash or Market Value</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="formPartition"><div className="closePartition"><Link to="#">&#x2715; &nbsp;Delete</Link></div></div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Asset Type</div>
                                                    <div className="gifticon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Cash Gift</option>
                                                        <option value="#">Gift or Equity</option>
                                                        <option value="#">Grant</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Deposited/Not Deposited</div>
                                                    <ul className="form_A">
                                                        <li><Link to="#" className="current">Deposited</Link></li>
                                                        <li><Link to="#" >Not Deposited</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Source</div>
                                                    <div className="typeicon"></div>
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Select</option>
                                                        <option value="#">Community Nonprofit</option>
                                                        <option value="#">Employer</option>
                                                        <option value="#">Federal Agency</option>
                                                        <option value="#">Local Agency</option>
                                                        <option value="#">Relative</option>
                                                        <option value="#">Religious Nonprofit</option>
                                                        <option value="#">State Agency</option>
                                                        <option value="#">Unmarried Partner</option>
                                                        <option value="#">Lender</option>
                                                        <option value="#">Parent</option>
                                                        <option value="#">Non Parent Relative</option>
                                                        <option value="#">Unrelated Friend</option>
                                                        <option value="#">Others</option>
                                                    </select>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Cash or Market Value</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="form1003BlkHead">
                                            <div className="formHeadContent_85pc">Other New Mortgage Loans on the Property</div>
                                            <div className="form1003Blk_right"><input type="button" value="&#65291; New" className="headRight_button" /></div>
                                        </div>

                                        <div className="form1003ContentBlk">
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Creditor Name</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Lien Type</div>
                                                    <ul className="form_A">
                                                        <li><Link to="#" className="current">First</Link></li>
                                                        <li><Link to="#" >Subordinate</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Payment</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Loan Amount / Amount to be Drawn</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Credit Limit (if applicable)</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                            <div className="formPartition"><div className="closePartition"><Link to="#">&#x2715; &nbsp;Delete</Link></div></div>
                                            <ul className="formContentIIcol">
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Creditor Name</div>
                                                    <div className="usericon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Lien Type</div>
                                                    <ul className="form_A">
                                                        <li><Link to="#" className="current">First</Link></li>
                                                        <li><Link to="#" >Subordinate</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Monthly Payment</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Loan Amount / Amount to be Drawn</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                                <li className="formContentIIcol">
                                                    <div className="form1003ContentHead">Credit Limit (if applicable)</div>
                                                    <div className="form_dollaricon"></div>
                                                    <input type="text" />
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="form_btn_successError_blk">
                                    <li className="form_btn_steps_label">
                                        <input type="button" value="Save & Finish Later" className="form_btn_steps" disabled />
                                        <input type="button" value="Continue" className="form_btn_steps" onClick={() => window.open('./form1003stepIII', '_self')} />
                                    </li>
                                    {/* If anything is filled in the form, enable Save button */}
                                    <li className="form_successError_steps" style={{ display: "none" }}>
                                        <div className="errormessage">You have some errors. Please fix and proceed.</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <InfoMortgageApplied />
            <InfoLoanAmount />
            <InfoInterestRate />
            <InfoLoanTerm />
            <InfoAmortizationType />
            <InfoLoanPurpose />
            <InfoOccupancy />
        </>
    )
}

export default Component;
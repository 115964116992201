import { React } from "../../../common";
import Container from "../../container";
import Steps from "../form1003steps"
import Stages from "../form1003stage"

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content">
                        <div className="widthfix_10px">

                            <div className="form1003_pgBlk">
                                <ul className="formHeadStageBlk">
                                    <li className="formHeadStageBlk">1003 Form - Complete Application</li>
                                    <li className="formHeadStageBlk">
                                        <Steps />
                                    </li>
                                </ul>

                                <ul className="appFormNavi">
                                    <Stages />
                                </ul>

                                <div className="pageLabelbelow1023">Declarations</div>

                                <ul className="selectBorrowerBlk">
                                    <li className="selectBorrowerBlk">
                                        <div className="selectPadLeft10">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT">George &amp; Sophia</option>
                                                <option value="#">Isabella &amp; James</option>
                                            </select>
                                        </div>
                                    </li>
                                    <li className="selectBorrowerBlk">Fill the declarations for the Borrower: George and Co-Borrower: Sophia</li>
                                </ul>

                                <div className="form1003_declarations">
                                    <ul className="form1003_declarations_bor_head">
                                        <li className="form1003_declarations_bor_head">Description</li>
                                        <li className="form1003_declarations_bor_head">Borrower</li>
                                    </ul>
                                    <ul className="form1003_declarations_bor">
                                        <li className="form1003_declarations_bor">Will you occupy the property as your primary residence? </li>
                                        <li className="form1003_declarations_bor">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Select</option>
                                                <option value="#">Yes</option>
                                                <option value="#">No</option>
                                            </select>
                                        </li>
                                    </ul>
                                    <ul className="form1003_declarations_bor">
                                        <li className="form1003_declarations_bor">Will you occupy the property as your primary residence?</li>
                                        <li className="form1003_declarations_bor">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Select</option>
                                                <option value="#">Yes</option>
                                                <option value="#">No</option>
                                            </select>
                                        </li>
                                    </ul>
                                </div>

                                <div className="form1003_declarations">
                                    <ul className="form1003_declarations_borCobor_head">
                                        <li className="form1003_declarations_borCobor_head">Description</li>
                                        <li className="form1003_declarations_borCobor_head">Borrower</li>
                                        <li className="form1003_declarations_borCobor_head">Co-Borrower</li>
                                    </ul>
                                    <ul className="form1003_declarations_borCobor">
                                        <li className="form1003_declarations_borCobor">Will you occupy the property as your primary residence?</li>
                                        <li className="form1003_declarations_borCobor">
                                            <div className="borCoborHeadLabel">Borrower -</div>
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Select</option>
                                                <option value="#">Yes</option>
                                                <option value="#">No</option>
                                            </select>
                                        </li>
                                        <li className="form1003_declarations_borCobor">
                                            <div className="borCoborHeadLabel">Co-Borrower -</div>
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Select</option>
                                                <option value="#">Yes</option>
                                                <option value="#">No</option>
                                            </select>
                                        </li>
                                    </ul>
                                    <ul className="form1003_declarations_borCobor">
                                        <li className="form1003_declarations_borCobor">Will you occupy the property as your primary residence? </li>
                                        <li className="form1003_declarations_borCobor">
                                            <div className="borCoborHeadLabel">Borrower -</div>
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Select</option>
                                                <option value="#">Yes</option>
                                                <option value="#">No</option>
                                            </select>
                                        </li>
                                        <li className="form1003_declarations_borCobor">
                                            <div className="borCoborHeadLabel">Co-Borrower -</div>
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Select</option>
                                                <option value="#">Yes</option>
                                                <option value="#">No</option>
                                            </select>
                                        </li>
                                    </ul>
                                </div>

                                <div className="form1003_declarations">
                                    <ul className="form1003_declarations_bor_30by70_head">
                                        <li className="form1003_declarations_bor_30by70_head">Military Service</li>
                                        <li className="form1003_declarations_bor_30by70_head">Borrower</li>
                                    </ul>
                                    <ul className="form1003_declarations_bor_30by70">
                                        <li className="form1003_declarations_bor_30by70">
                                            <div class="form1003ContentHead">
                                                Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States Armed Forces?
                                            </div>
                                        </li>
                                        <li className="form1003_declarations_bor_30by70">
                                            <div class="spacer_20margin_bottom">
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">Yes</option>
                                                    <option value="#">No</option>
                                                </select>
                                            </div>

                                            <div class="spacer_20margin_bottom">
                                                <div class="form1003ContentHead">Check all that apply</div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms_cb" /><label for="cb_ms_cb">Currently serving on active duty with projected expiration date of service/tour</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms1_cb" /><label for="cb_ms1_cb">Currently retired, discharged, or separated from service</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms2_cb" /><label for="cb_ms2_cb">Only period of service was as a non-activated member of the Reserve or National Guard</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms3_cb" /><label for="cb_ms3_cb">Surviving spouse</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="form1003_declarations">
                                    <ul className="form1003_declarations_borCobor_30by70_head">
                                        <li className="form1003_declarations_borCobor_30by70_head">Military Service</li>
                                        <li className="form1003_declarations_borCobor_30by70_head">Borrower</li>
                                        <li className="form1003_declarations_borCobor_30by70_head">Co-Borrower</li>
                                    </ul>
                                    <ul className="form1003_declarations_borCobor_30by70">
                                        <li className="form1003_declarations_borCobor_30by70">
                                            <div class="form1003ContentHead">
                                                Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States Armed Forces?
                                            </div>
                                        </li>
                                        <li className="form1003_declarations_borCobor_30by70">
                                            <div class="spacer_20margin_bottom">
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">Yes</option>
                                                    <option value="#">No</option>
                                                </select>
                                            </div>

                                            <div class="spacer_20margin_bottom">
                                                <div class="form1003ContentHead">Check all that apply</div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms_cb" /><label for="cb_ms_cb">Currently serving on active duty with projected expiration date of service/tour</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms1_cb" /><label for="cb_ms1_cb">Currently retired, discharged, or separated from service</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms2_cb" /><label for="cb_ms2_cb">Only period of service was as a non-activated member of the Reserve or National Guard</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms3_cb" /><label for="cb_ms3_cb">Surviving spouse</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="form1003_declarations_borCobor_30by70">
                                            <div class="spacer_20margin_bottom">
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">Yes</option>
                                                    <option value="#">No</option>
                                                </select>
                                            </div>

                                            <div class="spacer_20margin_bottom">
                                                <div class="form1003ContentHead">Check all that apply</div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms_cb" /><label for="cb_ms_cb">Currently serving on active duty with projected expiration date of service/tour</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms1_cb" /><label for="cb_ms1_cb">Currently retired, discharged, or separated from service</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms2_cb" /><label for="cb_ms2_cb">Only period of service was as a non-activated member of the Reserve or National Guard</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms3_cb" /><label for="cb_ms3_cb">Surviving spouse</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="form1003_declarations">
                                    <ul className="form1003_declarations_borCobor_30by70_head">
                                        <li className="form1003_declarations_borCobor_30by70_head">Demographic Information</li>
                                        <li className="form1003_declarations_borCobor_30by70_head">Borrower</li>
                                        <li className="form1003_declarations_borCobor_30by70_head">Co-Borrower</li>
                                    </ul>
                                    <ul className="form1003_declarations_borCobor_30by70">
                                        <li className="form1003_declarations_borCobor_30by70">
                                            <div class="form1003ContentHead">
                                                Ethnicity
                                            </div><div className="formMandatory">*</div>
                                        </li>
                                        <li className="form1003_declarations_borCobor_30by70">
                                            <div class="spacer_20margin_bottom">
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">Yes</option>
                                                    <option value="#">No</option>
                                                </select>
                                            </div>

                                            <div class="spacer_20margin_bottom">
                                                <div class="form1003ContentHead">Check all that apply</div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms_cb" /><label for="cb_ms_cb">Currently serving on active duty with projected expiration date of service/tour</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms1_cb" /><label for="cb_ms1_cb">Currently retired, discharged, or separated from service</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms2_cb" /><label for="cb_ms2_cb">Only period of service was as a non-activated member of the Reserve or National Guard</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms3_cb" /><label for="cb_ms3_cb">Surviving spouse</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="form1003_declarations_borCobor_30by70">
                                            <div class="spacer_20margin_bottom">
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">Yes</option>
                                                    <option value="#">No</option>
                                                </select>
                                            </div>

                                            <div class="spacer_20margin_bottom">
                                                <div class="form1003ContentHead">Check all that apply</div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms_cb" /><label for="cb_ms_cb">Currently serving on active duty with projected expiration date of service/tour</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms1_cb" /><label for="cb_ms1_cb">Currently retired, discharged, or separated from service</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms2_cb" /><label for="cb_ms2_cb">Only period of service was as a non-activated member of the Reserve or National Guard</label>
                                                    </div>
                                                </div>
                                                <div class="spacer_10margin_bottom">
                                                    <div class="formCheckboxLabel">
                                                        <input type="checkbox" id="cb_ms3_cb" /><label for="cb_ms3_cb">Surviving spouse</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>


                                <ul className="form_btn_successError_blk">
                                    <li className="form_btn_steps_label">
                                        <input type="button" value="Save & Finish Later" className="form_btn_steps" disabled />
                                        <input type="button" value="Submit" className="form_btn_steps" />
                                    </li>
                                    {/* If anything is filled in the form, enable Save button */}
                                    <li className="form_successError_steps" style={{ display: "none" }}>
                                        <div className="errormessage">You have some errors. Please fix and proceed.</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;